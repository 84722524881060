import React, { useState, useEffect } from "react";
import ListItem from "../../atoms/ListItem";
import "./style.scss";

import styled from "styled-components";

const AnnotationListBody = ({
  taskType,
  rows,
  onClickRow,
  onClickDelete,
  disableRemove,
  resetSelection,
  reset,
  frameRate,
  isActiveBleeding,
  selected,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const getRowsWithBlank = (rows) => {
    let prev = { start: -1, end: -1, code: 0 };
    const rowsWithBlank = rows.reduce((acc, curr) => {
      if (curr.start === 0) {
        acc.push(curr);
        prev = curr;
      } else {
        prev.end + 1 === curr.start ? acc.push(curr) : acc.push({ start: prev.end + 1, end: curr.start - 1, code: 0 }, curr);
        prev = curr;
      }

      return acc;
    }, []);

    return rowsWithBlank;
  };

  const renderRows = (rows, taskType) => {
    if (rows) {
      let annotationList = [];
      annotationList = taskType === "phase" ? getRowsWithBlank(rows) : rows;

      console.log(taskType)

      return annotationList.map((row, i) => {
        if (row === null && (taskType === "INTERVENTION" || taskType === "AB FRAME")) {
          return <StyledInterventionBlank />;
        } else if (row === null) {
          return <StyledBlank />;
        } else if (taskType === 'presence'){    //presence index debug
          return (
            <>
              <ListItem
                key={row.start ? row.start + row.end : ""}
                row={row}               
                onClickRow={() => {
                  if (i !== selectedIndex) {
                    setSelectedIndex(i);
                    let index;
                    if (typeof row.index === 'string') {
                      index = row.index.split('-')[0]
                    } else {
                      index = row.index
                    }
                    if (onClickRow) {
                      onClickRow({
                        ...row,
                        index: i
                      });
                    }
                  } else {
                    resetSelection();
                  }
                }}
                onClickDelete={onClickDelete}
                disableRemove={taskType === "frame_selection" && row.code === 2 ? true : onClickDelete ? false : true}
                isSelected={i === selectedIndex}
                frameRate={frameRate}
                annotationType={taskType}
                style={{ background: row.background }}
              />
            </>
          );
        } else if (taskType === 'bleeding' || taskType === 'phase'){    //ai empowerd index debug  
          return (
            <>
              <ListItem
                key={row.start ? row.start + row.end : ""}
                row={row}               
                onClickRow={() => {
                  if (i !== selectedIndex) {
                    setSelectedIndex(i);
                    let index;
                    if (typeof row.index === 'string') {
                      index = row.index.split('-')[0]
                    } else {
                      index = row.index
                    }
                    if (onClickRow) {
                      onClickRow({
                        ...row,
                        // index: i
                      });
                    }
                  } else {
                    resetSelection();
                  }
                }}
                onClickDelete={onClickDelete}
                disableRemove={taskType === "frame_selection" && row.code === 2 ? true : onClickDelete ? false : true}
                isSelected={i === selectedIndex}
                frameRate={frameRate}
                annotationType={taskType}
                style={{ background: row.background }}
              />
            </>
          );
        } else {
          return (
            <>
              <ListItem
                key={row.start ? row.start + row.end : ""}
                row={row}
                onClickRow={() => {
                  if (i !== selectedIndex) {
                    setSelectedIndex(i);
                    let index;
                    if (typeof row.index === 'string') {
                      index = row.index.split('-')[0]
                    } else {
                      index = row.index
                    }
                    if (onClickRow) {
                      onClickRow({
                        ...row,
                        index: parseInt(index)
                      });
                    }
                  } else {
                    resetSelection();
                  }
                }}
                onClickDelete={onClickDelete}
                disableRemove={taskType === "frame_selection" && row.code === 2 ? true : onClickDelete ? false : true}
                isSelected={i === selectedIndex}
                frameRate={frameRate}
                annotationType={taskType}
                style={{ background: row.background }}
              />
            </>
          );
        }
      });
    }
  };

  useEffect(() => {
    if (reset) {
      setSelectedIndex(null);
    }
  }, [reset]);

  return (
    <div
      className={
        isActiveBleeding
          ? "active-bleeding-annotation-list-body"
          : taskType === "INTERVENTION" || taskType === "AB FRAME"
          ? "intervention-annotation-list-body"
          : taskType.includes("CHOLEC") || taskType.includes("GAS")
          ? "cholec-list-body"
          : ""
      }
    >
      {renderRows(rows, taskType)}
    </div>
  );
};

const StyledBlank = styled.div`
  border-bottom: 1px solid black;
  width: 100%;
  height: 10px;
`;

const StyledInterventionBlank = styled.div`
  height: 32px;
  min-height: 32px;
  margin-top: 12px;
  margin-bottom: 6px;
`;

export default AnnotationListBody;
