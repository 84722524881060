import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DualTab from "../../molecules/AnnotationListTab/DualTab";
import AnnotationListBody from "../../molecules/AnnotationListBody";
import { setSelected, removeIntervention } from "../../../redux/ducks/intervention.js";
import ModalPortal from "../ModalPortal.jsx";
import Modal from "../Modal.jsx";
import Message from "../Message.jsx";

import { renderViewData } from "../../../utils";

import "./style.scss";

const AbInterventionList = ({ onClickListItem, bleedingState, annotationType, frameRate }) => {
  const dispatch = useDispatch();
  const { selected, activeBleeding, intervention } = bleedingState;
  const [msg, setMsg] = useState();
  const [reset, setReset] = useState({ ab: 0, inter: 0 });
  const [viewData, setViewData] = useState(null);
  const dualList = annotationType === "AB FRAME" ? ["Actice Bleeding", "Frame"] : ["Actice Bleeding", "Intervention"];
  const onClickAbRow = (row) => {
    const render = renderViewData(activeBleeding, intervention, -1);
    setViewData((prevState) => ({ ...prevState, ...render }));
    dispatch(setSelected({ ...row, currentTab: 0, code: null }));
    onClickListItem(row);
  };

  const onClickInterRow = (row) => {
    const render = renderViewData(activeBleeding, intervention, -1);
    setViewData((prevState) => ({ ...prevState, ...render }));
    dispatch(
      setSelected({
        ...row,
        currentTab: 1,
        x: row.x,
        y: row.y,
      })
    );
    onClickListItem(row);
  };
  // console.log(selected);

  const onClickDeleteIntervention = (row) => {
    const deleteWarningMsg = {
      type: "warning",
      message: "Are you sure you want to delete this annotation?",
      actions: [
        {
          label: "Yes",
          onClick: () => {
            const interventionData = {
              start: row.start,
              end: row.end,
              code: row.code,
              index: parseInt(row.index.split("-")[0]),
            };
            if (row.x !== undefined) {
              interventionData.x = row.x;
            }
            if (row.y !== undefined) {
              interventionData.y = row.y;
            }
            if (row.type !== undefined) {
              interventionData.type = row.type;
            }
            console.log(interventionData);
            dispatch(removeIntervention(interventionData));
            setMsg(null);
          },
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: () => setMsg(null),
          type: "gray",
          cancelOnEsc: true,
        },
      ],
    };
    setMsg(deleteWarningMsg);
  };

  useEffect(() => {
    if (!selected) setReset({ ab: reset.ab + 1, inter: reset.inter + 1 });
    if (selected?.currentTab === 1) {
      setReset({ ...reset, ab: reset.ab + 1 });
    } else if (selected?.currentTab === 0) {
      setReset({ ...reset, inter: reset.inter + 1 });
    }
  }, [selected]);

  useEffect(() => {
    setReset({ ...reset, inter: reset.inter + 1 });
    let selectedIndex = -1;
    if (selected?.index) {
      dispatch(setSelected({ currentTab: 0 }));
      selectedIndex = selected.index;

    }
    
    const render = renderViewData(activeBleeding, intervention, selectedIndex);
    setViewData((prevState) => ({ ...prevState, ...render }));
  }, [activeBleeding, intervention]);

  return (
    <>
      <div className="ab-intervention-list-container">
        <div className="container-header">
          <DualTab tabs={dualList} nRows={[activeBleeding.length, intervention.length]} />
        </div>
        <div className="container-body">
          <AnnotationListBody
            taskType={annotationType}
            rows={viewData ? viewData.ab : []}
            onClickRow={onClickAbRow}
            resetSelection={() => dispatch(setSelected(null))}
            reset={reset.ab}
            frameRate={frameRate}
          />
          <AnnotationListBody
            taskType={annotationType}
            rows={viewData ? viewData.inter : []}
            onClickRow={onClickInterRow}
            onClickDelete={onClickDeleteIntervention}
            resetSelection={() => dispatch(setSelected(null))}
            reset={reset.inter}
            frameRate={frameRate}
            isAnnotator={true}
          />
        </div>
      </div>
      {msg && (
        <ModalPortal>
          <Modal>
            <Message {...msg} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

export default AbInterventionList;
