import React from "react";
import { Switch, Route } from "react-router-dom";

import NavBar from "../components/organisms/NavBar.jsx";
import Agreement from "../pages/Agreement.jsx";

import AdminRoute from "./AdminRoute.jsx";
import AnnotatorRoute from "./AnnotatorRoute.jsx";
import AgreementRoute from "./AgreementRoute.jsx";

const HomeRoute = (props) => {
  const user = props.authenticatedAs;
  const currPath = props.location.pathname;

  const { role, isAgreed } = user;

  return (
    <>
      <NavBar currPath={currPath} userInfo={user} />
      {role?.admin === true ? (
        <AdminRoute />
      ) : (
        <Switch>
          <Route
            exact
            path="/agreement"
            render={(props) => <Agreement isAgreed={isAgreed} {...props} />}
          />
          <AgreementRoute
            path="/"
            isAgreed={isAgreed}
            component={AnnotatorRoute}
            role={role}
            currPath={currPath}
            userInfo={user}
          />
        </Switch>
      )}
    </>
  );
};

export default HomeRoute;
