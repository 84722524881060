import { request } from "../libs";

const updateStatus = (taskId, status) => {
  const url = `/video-tasks/${taskId}/status`;
  return request.put(url, { status });
};

const updateTasks = (taskIds, update) => {
  const url = `/video-tasks/update-many`;
  return request.post(url, { tasks: taskIds, ...update });
};

const deleteTasks = (taskIds) => {
  const url = `/video-tasks/delete-many`;
  return request.post(url, { tasks: taskIds });
};

export { updateStatus, updateTasks, deleteTasks };
