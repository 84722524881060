const changeFormat = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  const hh = date.getHours().toString().padStart(2, "0");
  const mm = date.getMinutes().toString().padStart(2, "0");
  const ss = date.getSeconds().toString().padStart(2, "0");

  const formatDate = `${year}-${month}-${day} ${hh}:${mm}:${ss}`;

  return formatDate;
};

const getDate = (assignDate) => {
  const createdAt = new Date(assignDate);
  const assignDated = changeFormat(createdAt).substr(0, 10);

  return assignDated;
};

export { getDate };
