import React, { useState } from "react";
import styled from "styled-components";

import Input from "../atoms/Input";
import Button from "../atoms/Button";
import { ReactComponent as CloseButton } from "../../assets/icnClose.svg";

// TODO 모두 입력된 상태, 렌더를 반환하는 useInputList 작성 후 리팩토링
const EditUserModal = ({ initialUser, actions: { onCancel, onSubmitEdit }, closeModal }) => {
  const [user, setUser] = useState({
    name: initialUser.name,
    email: initialUser.email,
    // password: "",
  });

  const setUserName = ({ target: { value } }) => {
    setUser({ ...user, name: value });
  };

  const setUserEmail = ({ target: { value } }) => setUser({ ...user, email: value });
  // const setUserPassword = ({ target: { value } }) => setUser({ ...user, password: value });

  return (
    <Wrapper>
      <StyledCloseButton onClick={closeModal} />
      <StyledTitle>Edit Member</StyledTitle>
      <StyledInputContainer>
        <Input label="Name" value={user.name} onChange={setUserName} style={{ width: "400px" }} />
        <Input label="Email" value={user.email} onChange={setUserEmail} style={{ width: "400px" }} />
        {/* {<Input label="Password" value={user.password} onChange={setUserPassword} style={{ width: "400px" }} />} */}
      </StyledInputContainer>
      <StyledButtonGroup>
        <Button label="Cancel" onClick={onCancel} />
        <Button label="Done" onClick={() => onSubmitEdit(initialUser._id, user)} disabled={!user.name || !user.email} />
      </StyledButtonGroup>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  padding: 60px 180px;
  position: relative;
  background-color: white;
  border-radius: 10px;
`;

const StyledTitle = styled.h1`
  font-size: 48px;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 45px;
  right: 45px;
  cursor: pointer;
`;

const StyledInputContainer = styled.div`
  margin-bottom: 60px;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default EditUserModal;
