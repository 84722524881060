import React from "react";
import styled from "styled-components";

/**
 * @param {Array} content - [code, phase명]을 담고 있음
 * @param {number} disabled - 비활성화할 selectorItem index
 */
const SelectorItem = ({ content, disabled, ...rest }) => {
  const [code, caption] = content;

  return (
    <Wrapper>
      {!disabled && <StyledTooltipText>{caption}</StyledTooltipText>}
      <StyledSelectorItem disabled={disabled} {...rest}>
        {code}
      </StyledSelectorItem>
    </Wrapper>
  );
};

const StyledTooltipText = styled.span`
  visibility: hidden;
  width: 140px;
  background-color: #30363b;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -70px;
  transition: opacity 0.3s;
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  &:hover ${StyledTooltipText} {
    visibility: visible;
    opacity: 1;
  }
  ${StyledTooltipText}::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border: none;
    border-width: 5px;
    border-style: solid;
    border-color: #30363b transparent transparent transparent;
  }
`;

const StyledSelectorItem = styled.button`
  min-width: 25px;
  min-height: 25px;
  margin-left:15px;
  background-color: ${({ active }) => (active ? "#00A999" : "#30363b")};
  color: ${({ disabled }) => (disabled ? "#889198" : "#ffffff")};
  font-size: 10px;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  outline: none;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    background-color: #889198;
  }
`;

export default SelectorItem;
