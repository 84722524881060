import React, { useState, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import Input from "../../atoms/NewInput";
import Selector from "../../molecules/Selector.jsx";
import ModalPortal from "../ModalPortal.jsx";
import Modal from "../Modal.jsx";
import Message from "../Message.jsx";

import { CODES } from "../constants.js";
import { validate } from "../../../utils/validate.js";
import { toFrame, toHHMMSSFF, completeHHMMSSFF } from "../../../utils/annotation.js";
import {
  checkPhase,
  insertPhaseThunk,
  insertSkillAssessmentThunk,
  clearWarning,
  importPhase,
  importSkillAssessment,
  setSelected,
} from "../../../redux/ducks/skillAssessment";
import { updateTasks } from "../../../api";

const CholeSAInputContainer = ({ state, videoMeta, annotationType }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [frameInputValues, setFrameInputValues] = useState({
    start: "00:00:00:00",
    end: "00:00:00:00",
  });
  const [startFrameInput, setStartFrameInput] = useState(-1);
  const [reset, setReset] = useState(0);
  const [code, setCode] = useState(null);
  const [skill, setSkill] = useState({
    "Depth Perception": "",
    "Bimanual Dexterity": "",
    Efficiency: "",
    "Tissue Handling": "",
    Difficulty: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState(null);

  const { totalFrame, frameRate } = videoMeta;
  const { skillAssessment, selected, selectedSkill, warning } = state;

  const dispatch = useDispatch();

  const closeWarning = () => dispatch(clearWarning());

  const updateInput = useCallback(
    ({ target: { name, value } }) => {
      if (validate(value, "hhmmssff")) {
        const newValues = {
          ...frameInputValues,
          [name]: value,
        };
        setFrameInputValues(newValues);
      }
    },
    [frameInputValues]
  );

  const updateValidatedInputs = () => {
    const startHHMMSSFF = completeHHMMSSFF(frameInputValues.start);
    const endHHMMSSFF = completeHHMMSSFF(frameInputValues.end);
    const startFrame = toFrame(startHHMMSSFF, frameRate);
    const endFrame = toFrame(endHHMMSSFF, frameRate);

    dispatch(setSelected({ start: startFrame, end: endFrame }));
    setFrameInputValues({
      ...frameInputValues,
      start: toHHMMSSFF(startFrame < totalFrame ? startFrame : totalFrame, frameRate),
      end: toHHMMSSFF(endFrame < totalFrame ? endFrame : totalFrame, frameRate),
    });
  };

  const updateCode = (code) => {
    setCode(code);
    dispatch(setSelected({ code: code }));
  };

  const loadJSONFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      let json;
      const phase = [];
      const skillAssessment = [];
      reader.onload = (e) => {
        json = JSON.parse(e.target.result);
        console.log(json);
        json.annotations.forEach((el) => {
          json.annotationType === "ONLY_ARMES" ? phase.push(el) : skillAssessment.push(el);
        });

        skillAssessment.length > 0 ? dispatch(importSkillAssessment(skillAssessment)) : dispatch(importPhase(phase));
      };
      reader.readAsText(e.target.files[0]);
    } else {
      alert("Failed import JSON file");
    }
  };

  const dispatchPhase = () => {
    if (!code) return;

    const data = {
      ...frameInputValues,
      start: toFrame(frameInputValues.start, frameRate),
      end: toFrame(frameInputValues.end, frameRate),
      code: code,
    };

    dispatch(checkPhase(data));
    setCode(null);
  };

  const dispatchSkillAssessment = () => {
    dispatch(insertSkillAssessmentThunk(skill));
    setSkill({
      "Depth Perception": "",
      "Bimanual Dexterity": "",
      Efficiency: "",
      "Tissue Handling": "",
      Difficulty: "",
    });
  };

  const insertPhase = () => dispatch(insertPhaseThunk(warning.data));

  const inputOnKeyDown = (e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      updateValidatedInputs();
    }
  };

  const inputProps = [
    {
      placeholder: "Start",
      name: "start",
      value: frameInputValues?.start !== undefined ? frameInputValues?.start : "",
      onChange: updateInput,
      onKeyDown: inputOnKeyDown,
      onBlur: updateValidatedInputs,
      onFocus: (e) => e.target.setSelectionRange(0, e.target.value.length),
      autoComplete: "off",
    },
    {
      placeholder: "End",
      name: "end",
      value: frameInputValues?.end !== undefined ? frameInputValues?.end : "",
      onChange: updateInput,
      onKeyDown: inputOnKeyDown,
      onBlur: updateValidatedInputs,
      onFocus: (e) => e.target.setSelectionRange(0, e.target.value.length),
      autoComplete: "off",
    },
  ];

  const renderInputs = () => inputProps.map((item, i) => <StyledInput key={"" + reset + i} {...item} />);

  const updateSkill = (e) => {
    e.persist();
    setSkill((prevState) => ({
      ...prevState,
      [e.target.name]: parseInt(e.target.value),
    }));
  };

  const makeString = (obj) => {
    const { code, start, end } = obj;
    const startHHMMSSFF = toHHMMSSFF(start, frameRate);
    const endHHMMSSFF = toHHMMSSFF(end, frameRate);

    return `(${code}) ${startHHMMSSFF} - ${endHHMMSSFF}`;
  };

  const getMessage = () => {
    const msg = warning.list.map((curr) => makeString(curr)).join(", ");

    return `The section you filled in, ${makeString(warning.data)} is overlapped with ${msg}. Do you want to change it?`;
  };

  const warningModal = useMemo(() => {
    if (!warning.data) return;

    return {
      type: "warning",
      message: getMessage(),
      actions: [
        {
          label: "Yes",
          theme: "secondary",
          onClick: insertPhase,
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: closeWarning,
          cancelOnEsc: true,
        },
      ],
    };
  }, [warning]);

  const tabs = [
    {
      title: "Phase",
      component: (
        <>
          <StyledInputWrapper>
            {renderInputs()}
            <StyledAddBtn onClick={dispatchPhase}>Add</StyledAddBtn>
          </StyledInputWrapper>
          <StyledSelectorWrapper>
            {videoMeta && videoMeta.annotationType && (
              <Selector contents={CODES["ONLY_ARMES"]} onClick={updateCode} active={code} clickable={true} />
            )}
          </StyledSelectorWrapper>
        </>
      ),
    },
    {
      title: "Skill",
      component: (
        <StyledSkillWrapper>
          <StyledSkillHeader>
            <div>{selectedPhase}</div>
            <StyledSaveBtn onClick={dispatchSkillAssessment} disabled={disabled}>
              Save
            </StyledSaveBtn>
          </StyledSkillHeader>
          <StyledSkillSelectWrapper>
            <StyledSkillLabel>Depth perception</StyledSkillLabel>
            <StyledSkillSelect
              name="Depth Perception"
              value={skill["Depth Perception"]}
              onChange={(e) => updateSkill(e, e.currentTarget.name)}
              disabled={disabled}
            >
              <option value="">선택</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </StyledSkillSelect>
            <StyledSkillLabel>Bimanual Dexterity</StyledSkillLabel>
            <StyledSkillSelect
              name="Bimanual Dexterity"
              value={skill["Bimanual Dexterity"]}
              onChange={(e) => updateSkill(e, e.currentTarget.name)}
              disabled={disabled}
            >
              <option value="">선택</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </StyledSkillSelect>
            <StyledSkillLabel>Efficiency</StyledSkillLabel>
            <StyledSkillSelect
              name="Efficiency"
              value={skill["Efficiency"]}
              onChange={(e) => updateSkill(e, e.currentTarget.name)}
              disabled={disabled}
            >
              <option value="">선택</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </StyledSkillSelect>
            {annotationType.includes("GOALS") && (
              <>
                <StyledSkillLabel>Tissue Handling</StyledSkillLabel>
                <StyledSkillSelect
                  name="Tissue Handling"
                  value={skill["Tissue Handling"]}
                  onChange={(e) => updateSkill(e, e.currentTarget.name)}
                  disabled={disabled}
                >
                  <option value="">선택</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </StyledSkillSelect>
              </>
            )}
            {annotationType.includes("GEARS") && (
              <>
                <StyledSkillLabel>Force Sensitivity</StyledSkillLabel>
                <StyledSkillSelect
                  name="Force Sensitivity"
                  value={skill["Force Sensitivity"]}
                  onChange={(e) => updateSkill(e, e.currentTarget.name)}
                  disabled={disabled}
                >
                  <option value="">선택</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </StyledSkillSelect>
              </>
            )}
            {annotationType.includes("GEARS") && (
              <>
                <StyledSkillLabel>Robotic Control</StyledSkillLabel>
                <StyledSkillSelect
                  name="Robotic Control"
                  value={skill["Robotic Control"]}
                  onChange={(e) => updateSkill(e, e.currentTarget.name)}
                  disabled={disabled}
                >
                  <option value="">선택</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </StyledSkillSelect>
              </>
            )}
            <StyledSkillLabel>Difficulty</StyledSkillLabel>
            <StyledSkillSelect
              name="Difficulty"
              value={skill["Difficulty"]}
              onChange={(e) => updateSkill(e, e.currentTarget.name)}
              disabled={disabled}
            >
              <option value="">선택</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </StyledSkillSelect>
          </StyledSkillSelectWrapper>
        </StyledSkillWrapper>
      ),
    },
  ];

  const handleChangeTab = (index) => {
    setActiveTab(index);
  };

  const renderTabs = () =>
    tabs.map((tab, i) => (
      <StyledTabButton key={i} active={activeTab === i} onClick={() => handleChangeTab(i)} name={tab.title}>
        <div active={activeTab === i}>{tab.title}</div>
      </StyledTabButton>
    ));

  useEffect(() => {
    if (selected) {
      selected.start = !selected.start ? 0 : selected.start < totalFrame ? selected.start : totalFrame;
      selected.end = !selected.end ? 0 : selected.end < totalFrame ? selected.end : totalFrame;
      selected.start >= selected.end ? setStartFrameInput(0) : setStartFrameInput(selected.end);
      setFrameInputValues({
        ...selected,
        start: toHHMMSSFF(selected.start, frameRate),
        end: toHHMMSSFF(selected.end, frameRate),
      });
      if (selected.code) {
        setSelectedPhase(`Phase${selected.code}`);
        setSkill(skillAssessment[0][`Phase${selected.code}`]);
      }
    } else {
      setFrameInputValues({
        start: "00:00:00:00",
        end: "00:00:00:00",
      });
      setSelectedPhase(null);
      setSkill({
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
      });
    }
    setCode(selected?.code);
    setReset(reset + 1);
  }, [selected]);

  useEffect(() => {
    if (!selected && !selectedSkill) {
      setDisabled(true);
      setSkill({
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
      });
      setActiveTab(0);
    } else {
      console.log(selectedSkill);
      setDisabled(false);
      if (selectedSkill) {
        setSelectedPhase(selectedSkill[1]);
        setSkill(selectedSkill[0]);
        setActiveTab(1);
      }
    }
  }, [selected, selectedSkill]);

  useEffect(() => {
    const { data, list } = warning;
    if (data && !list?.length) dispatch(insertPhaseThunk(warning.data));

    setFrameInputValues({
      start: toHHMMSSFF(startFrameInput + 1, frameRate),
      end: "00:00:00:00",
    });
    setReset(reset + 1);
  }, [warning]);

  return (
    <>
      <Wrapper>
        <StyledTabContainer>
          {renderTabs()}
          <StyledLabel htmlFor="import_json">Import</StyledLabel>
          <StyledImportInput type="file" id="import_json" name="import_json" accept=".json" onChange={loadJSONFile} />
        </StyledTabContainer>
        {tabs[activeTab].component}
      </Wrapper>
      {warning?.list?.length > 0 && (
        <ModalPortal>
          <Modal>
            <Message {...warningModal} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

const StyledSkillSelect = styled.select`
  width: 50px;
  height: 30px;
  border: 1px solid #000000;
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
`;

const StyledSkillLabel = styled.div`
  width: 130px;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  color: #000000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 20px;
  background-color: #ffffff;
`;

const StyledLabel = styled.label`
  width: 100px;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: 2px;
  border: 1px solid #00a999;
  color: #00a999;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
`;

const StyledImportInput = styled.input`
  display: none;
`;

const StyledInput = styled(Input)`
  font-size: 10px;

  :not(:first-of-type) {
    margin-left: 32px;
  }
`;

const StyledSelectorWrapper = styled.div`
  margin-top: 20px;
  margin-left: 20px;
`;

const StyledSkillSelectWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 650px;
  height: 80px;
`;

const StyledSkillHeader = styled.div`
  margin-top: 5px;
  margin-left: 20px;
  display: flex;
`;

const StyledSkillWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

const StyledInputWrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  display: flex;
`;

const StyledSaveBtn = styled.button`
  width: 100px;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  border: 1px solid #00a999;
  color: #00a999;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-left: auto;
`;

const StyledAddBtn = styled.button`
  width: 100px;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  border: 1px solid #00a999;
  color: #00a999;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin-left: auto;
  margin-right: 100px;
`;

const StyledTabButton = styled.div`
  width: 100px;
  height: 35px;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  border-top: ${({ active }) => (active ? "2px solid #00a999" : "none")};
  border-bottom: ${({ active }) => (active ? "none" : "1px solid #d1d2d3")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.6s;
  background: ${({ active }) => (active ? "#ffffff" : "#F2F2F2")};
  color: ${({ active }) => (active ? "#000000" : "#666666")};
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 2px;
  }
`;

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #000000;
`;

const Wrapper = styled.div`
  background-color: #ffffff;
  height: 150px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #d1d2d3;
`;

export default CholeSAInputContainer;
