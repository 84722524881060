import { updateGrading } from "../../utils";
import { saveAnnotation } from "../../api";

// Action
const SET_ID = "grading/SET_ID";
const CHANGE_GRADING = "grading/CHANGE_GRADING";
const SET_GRADING = "grading/SET_GRADING";
const RESET_GRADING = "grading/RESET_GRADING";
const SET_INDEX = "grading/SET_INDEX";
const initialState = {
  id: null,
  currentGrading: {},
  currentIndex: -1,
  grading: [],
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ID:
      return { ...state, id: action.payload };

    case CHANGE_GRADING:
      const currentGrading = action.payload ? { ...state.currentGrading, ...action.payload } : null;
      return { ...state, currentGrading: currentGrading };

    case SET_INDEX:
      return { ...state, currentIndex: action.payload };

    case SET_GRADING:
      return {
        ...state,
        currentGrading: initialState.currentGrading,
        grading: action.payload,
        currentIndex: -1,
      };
    case RESET_GRADING:
      return initialState;
    default:
      return state;
  }
}

// Action Creator
export function setId(payload) {
  return { type: SET_ID, payload };
}

export function changeGrading(payload) {
  return { type: CHANGE_GRADING, payload };
}

export function setGrading(payload) {
  return { type: SET_GRADING, payload };
}
export function setCurrentIndex(payload) {
  return { type: SET_INDEX, payload };
}

export const importGrading = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().grading;
    const { status } = await saveAnnotation(state.id, payload);
    if (status === 200) {
      dispatch(setGrading(payload));
    }
  } catch (err) {
    console.log(err);
  }
};

export function resetGrading() {
  return { type: RESET_GRADING };
}

export const updateThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().grading;
    const activeBleedingWithGrading = updateGrading(state.grading, payload, state.currentIndex);
    const { status } = await saveAnnotation(state.id, activeBleedingWithGrading);
    if (status === 200) {
      dispatch(setGrading(activeBleedingWithGrading));
    }
  } catch (err) {
    console.error(err);
  }
};
