import React, {
  useState,
  useEffect,
  useMemo,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import AnnotationListTab from "../../molecules/AnnotationListTab";
import AnnotationListBody from "../../molecules/AnnotationListBody";
import {
  setSelected,
  switchPhase,
  removeArmesThunk,
} from "../../../redux/ducks/phase.js";
import ModalPortal from "../ModalPortal.jsx";
import Modal from "../Modal.jsx";
import Message from "../Message.jsx";
import { getCompletionRate, checkOnlyArmes } from "../../../utils";

const PhaseListContainer = (
  { totalFrame, onClickListItem, phaseState, frameRate },
  ref
) => {
  const dispatch = useDispatch();
  const { selected, armes, onlyArmes, currPhase } = phaseState;

  const [rows, setRows] = useState(armes);
  const [disableRemove, setDisableRemove] = useState(false);
  const [msg, setMsg] = useState();
  const [reset, setReset] = useState(0);

  const tabs = ["ARMES", "ONLY_ARMES"];

  const completionRate = useMemo(
    () => (totalFrame ? getCompletionRate(totalFrame, armes) : null),
    [armes]
  );

  const warningMsg = {
    type: "warning",
    message:
      "If you make changes on 'Armes', 'Only Armes' data will be lost. Do you want to switch to 'Armes'?",
    actions: [
      {
        label: "Yes",
        onClick: () => {
          dispatch(switchPhase());
          setMsg(null);
        },
        submitOnEnter: true,
      },
      {
        label: "No",
        onClick: () => {
          setMsg(null);
        },
        type: "gray",
        cancelOnEsc: true,
      },
    ],
  };

  const errorMsg = {
    type: "error",
    message:
      "‘Only Armes’ not available. Please complete ‘Armes’ first and try again.",
    actions: [
      {
        label: "Ok",
        onClick: () => {
          setMsg(null);
        },
        submitOnEnter: true,
      },
    ],
  };

  const onClickTab = () => {
    if (currPhase === "ONLY_ARMES") return setMsg(warningMsg);
    else if (completionRate >= 100) return dispatch(switchPhase());
    setMsg(errorMsg);
  };

  const onClickRow = (row) => {
    dispatch(setSelected(row));
    onClickListItem(row.start);
  };

  const onClickDelete = (row) => {
    const deleteWarningMsg = {
      type: "warning",
      message: "Are you sure you want to delete this phase?",
      actions: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(removeArmesThunk(row));
            setMsg(null);
          },
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: () => setMsg(null),
          type: "gray",
          cancelOnEsc: true,
        },
      ],
    };

    setMsg(deleteWarningMsg);
  };

  useEffect(() => {
    const isArmes = currPhase === "ARMES";
    isArmes ? setRows(armes) : setRows(onlyArmes);
    setDisableRemove(!isArmes);
  }, [armes, onlyArmes, currPhase]);

  useEffect(() => {
    if (!selected) setReset(reset + 1);
  }, [selected]);

  useImperativeHandle(
    ref,
    () => ({
      rate: getCompletionRate(totalFrame, armes),
      finished: checkOnlyArmes(onlyArmes).length ? false : true,
    }),
    [armes, onlyArmes]
  );

  return (
    <>
      <div className="phase-list-container">
        <div className="container-header">
          <AnnotationListTab
            tabs={tabs}
            nRows={rows.length}
            onClickTab={onClickTab}
            active={currPhase}
          />
        </div>
        <div className="container-body">
          <AnnotationListBody
            taskType="phase"
            rows={rows}
            onClickRow={onClickRow}
            onClickDelete={onClickDelete}
            disableRemove={disableRemove}
            resetSelection={() => dispatch(setSelected(null))}
            reset={reset}
            frameRate={frameRate}
          />
        </div>
        <div className="container-footer">
          {totalFrame &&
            `Completion Rate: ${getCompletionRate(totalFrame, armes)
              .toString()
              .slice(0, 4)}%`}
        </div>
      </div>
      {msg && (
        <ModalPortal>
          <Modal>
            <Message {...msg} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

export default React.forwardRef(PhaseListContainer);
