import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import GradingListItem from "../../atoms/ListItem/GradingListItem";
import "./style.scss";
import { setCurrentGrading } from "../../../redux/ducks/grading";

import styled from "styled-components";

const GradingListBody = ({
  taskType,
  rows,
  onClickRow,
  onClickDelete,
  disableRemove,
  resetSelection,
  reset,
  frameRate,
  isActiveBleeding,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  const renderRows = (rows, taskType) => {
    if (rows) {
      let annotationList = [];
      annotationList = rows;

      return annotationList.map((row, i) => {
        return (
          <>
            <GradingListItem
              index={i}
              key={row.start ? "" + row.start + row.end : ""}
              row={row}
              onClickRow={() => {
                if (i !== selectedIndex) {
                  setSelectedIndex(i);
                  if (onClickRow) onClickRow(row, i);
                } else {
                  resetSelection();
                }
              }}
              onClickDelete={onClickDelete}
              disableRemove={onClickDelete ? false : true}
              isSelected={i === selectedIndex || row.selected}
              frameRate={frameRate}
              annotationType={taskType}
              style={{ background: row.background }}
            />
          </>
        );
      });
    }
  };

  useEffect(() => {
    if (reset) {
      setSelectedIndex(null);
    }
  }, [reset]);

  return (
    <div
      className={
        isActiveBleeding ? "active-bleeding-annotation-list-body" : taskType === "INTERVENTION" ? "intervention-annotation-list-body" : ""
      }
    >
      {renderRows(rows, taskType)}
    </div>
  );
};

export default GradingListBody;
