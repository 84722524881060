import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelected as setPhaseSelected } from "../../redux/ducks/phase.js";
import { setSelected as setInterventionSelected } from "../../redux/ducks/intervention.js";
import { setSelected as setBleedingSelected } from "../../redux/ducks/bleeding.js";
import { setSelected as setFrameSelected } from "../../redux/ducks/frame.js";
import { setSelected as setSkillSelected } from "../../redux/ducks/skillAssessment";

import { setSelected as setActiveBleedingSelected } from "../../redux/ducks/activebleeding.js";
import { setSelected as setPresenceSelected } from "../../redux/ducks/presence.js";

import { toHHMMSSFF, toFrame, completeHHMMSSFF } from "../../utils/annotation";
import { validate } from "../../utils/validate.js";

import Input from "../atoms/NewInput";

import styled from "styled-components";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import ResolutionSettingIcon from "../../assets/resolution-setting.svg";

require("@silvermine/videojs-quality-selector")(videojs);

const PAUSE_KEY = "Space";
const FORWARD_1_FRAME_KEY = "KeyF";
const BACKWARD_1_FRAME_KEY = "KeyA";
const FORWARD_1_SEC_KEY = "KeyX";
const BACKWARD_1_SEC_KEY = "KeyZ";
const FORWARD_10_SEC_KEY = "ArrowRight";
const BACKWARD_10_SEC_KEY = "ArrowLeft";
 
// bugfix, related ticket: ANNO2020-91 -> resolved
const VideoPlayer = ({ videoMeta, src, className, refs, frameRate, onClick, isPointOn, bleedingPoint }) => {
  const dispatch = useDispatch();
  let { totalFrame, path } = videoMeta;
  const fname = path?.split("/")[path?.split("/").length - 1];
  const videoRef = refs.videoRef;

  const [currentTime, setCurrentTime] = useState("00:00:00:00");
  let player;
  const updateTime = (time) => {
    setCurrentTime(toHHMMSSFF(Math.floor(time * frameRate), frameRate));
  };
  const togglePlayPause = () => {
    player && (player.paused() ? player.play() : player.pause());
  };

  const mvFrame = (value) => updateTime(videoRef.current.currentTime += value);

  const keyHandle = (code) => {
    const { currentTime, ended, readyState } = videoRef.current;
    const isVideoStarted = !!(currentTime >= 0 && !ended && readyState > 2);
    if (!isVideoStarted) return;

    const frame = Math.floor(videoRef.current.currentTime * frameRate);
    const setSelected =
      videoMeta?.annotationType === "FRAME_SELECTION"
        ? setFrameSelected
        : videoMeta?.annotationType === "PRESENCE"
          ? setPresenceSelected
          : videoMeta?.annotationType === "INSTRUMENTS_PRESENCE"
            ? setPresenceSelected
            : videoMeta?.annotationType === "ORGAN_PRESENCE"
              ? setPresenceSelected
              : videoMeta?.annotationType === "PHASE"
                ? setPhaseSelected
                : videoMeta?.annotationType === "ACTIVE_BLEEDING"
                  ? setActiveBleedingSelected
                  : videoMeta?.annotationType === "INTERVENTION" || videoMeta?.annotationType === "AB_FRAME"
                    ? setInterventionSelected
                    : videoMeta?.annotationType === "CHOLEC_SKILL_ASSESSMENT" ||
                      videoMeta?.annotationType === "GASTRECTOMY_GOALS" ||
                      videoMeta?.annotationType === "GASTRECTOMY_GEARS"
                      ? setSkillSelected
                      : setBleedingSelected;

    if (code === "KeyQ") dispatch(setSelected({ start: frame }));
    else if (code === "KeyR") dispatch(setSelected({ end: frame }));
    if (videoMeta.annotationType === "INTERVENTION") {
      if (code === "Digit1") {
        dispatch(setSelected({ code: 1 }));
      } else if (code === "Digit2") {
        dispatch(setSelected({ code: 2 }));
      } else if (code === "Digit3") {
        dispatch(setSelected({ code: 3 }));
      } else if (code === "Digit4") {
        dispatch(setSelected({ code: 4 }));
      } else if (code === "Digit5") {
        dispatch(setSelected({ code: 5 }));
      }
    }
  };

  const onChangeTimeInput = (e) => {
    const changedValue = e.target.value;

    if (validate(changedValue, "hhmmssff") || !changedValue) {
      setCurrentTime(changedValue);
    }
  };

  /**
   *
   * Set Player's current time to current frame
   * @param {} frame
   */
  const setPlayerFrame = (frame) => {
    if (frame > totalFrame) {
      videoRef.current.currentTime = parseFloat(totalFrame) / frameRate;
    } else {
      // parseFloat(frame) /30 1프레임이동이 안됨 Ex 2 => 1 1=>0 으로되는 현상을 해결하기위해
      // 0.00001 epsilon 강제주입 ()
      // FIXME
      videoRef.current.currentTime = 0.0001 + parseFloat(frame) / frameRate;
    }
  };

  const controlTimestampKeyEvent = (e) => {
    if (e.keyCode === 13) {
      movePlayerToCurrentTime();
    }
  };

  const movePlayerToCurrentTime = () => {
    if (currentTime) {
      const hhmmssff = completeHHMMSSFF(currentTime);
      const frame = toFrame(hhmmssff, frameRate);
      setPlayerFrame(frame);
    }
  };

  const controlPlayer = (e) => {
    const { code, metaKey, ctrlKey } = e;
    const modKey = window.navigator.platform.match("Mac") ? metaKey : ctrlKey;

    if (code !== PAUSE_KEY && !/(Enter|Escape)/.test(code)) player.pause();

    switch (code) {
      case PAUSE_KEY:
        e.preventDefault();
        togglePlayPause();
        return;
      case FORWARD_1_FRAME_KEY:
        mvFrame(1 / frameRate);
        return;
      case BACKWARD_1_FRAME_KEY:
        mvFrame(-(1 / frameRate));
        return;
      case FORWARD_1_SEC_KEY:
        mvFrame(1);
        return;
      case BACKWARD_1_SEC_KEY:
        mvFrame(-1);
        return;
      case FORWARD_10_SEC_KEY:
        if (modKey) mvFrame(60);
        return;
      case BACKWARD_10_SEC_KEY:
        e.preventDefault();
        if (modKey) mvFrame(-60);
        return;
      default:
        keyHandle(code);
        return;
    } 
  };

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    preload: "metadata",
    playbackRates: [0.5, 1, 2, 3, 4, 5],
    width: 900,
    height: 720,
    totalFrame,
    type: "video/mp4",
    controlBar: {
      children: [
        "PlayToggle",
        "CurrentTimeDisplay",
        "ProgressControl",
        "RemainingTimeDisplay",
        "PlaybackRateMenuButton",
        "qualitySelector",
        "FullscreenToggle",
      ],
    },
  };

  useEffect(() => {
    window.addEventListener("keydown", controlPlayer);

    player = videojs(videoRef.current, videoJsOptions);
    player.on("timeupdate", () => {
      updateTime(player.currentTime());
    });
    player.src([
      {
        src: `${src}?quality=1080p`,
        type: "video/mp4",
        label: "1080p",
        selected: true,
      },
      {
        src: `${src}?quality=720p`,
        type: "video/mp4",
        label: "720p",
      },
      {
        src: `${src}?quality=480p`,
        type: "video/mp4",

        label: "480p",
      },
    ]);

    const fullscreenToggleEl = player.controlBar.FullscreenToggle;
    fullscreenToggleEl.on("click", () => fullscreenToggleEl.blur());

    return () => {
      window.removeEventListener("keydown", controlPlayer);
      player.dispose();
    };
  }, []);

  return (
    <Wrapper className={className}>
      <StyledVjs>
        <div data-vjs-player>
          <video ref={videoRef} className="video-js vjs-big-play-centered" />

          {(videoMeta?.annotationType === "ACTIVE_BLEEDING" ||
            videoMeta?.annotationType === "AB_FRAME" ||
            videoMeta?.annotationType === "INTERVENTION" ||
            videoMeta?.annotationType === "GRADING") && (
              <VideoDiv onClick={onClick}>
                <Square x={bleedingPoint && isPointOn ? bleedingPoint.x : ""} y={bleedingPoint && isPointOn ? bleedingPoint.y : ""} />
              </VideoDiv>
            )}
        </div>
      </StyledVjs>
      <StyledInfoArea>
        <StyledInput
          value={currentTime}
          onKeyDown={controlTimestampKeyEvent}
          onChange={onChangeTimeInput}
          onBlur={movePlayerToCurrentTime}
          onFocus={(e) => e.target.setSelectionRange(0, e.target.value.length)}
        />
        <VerticalLine />
        <StyledText>{toHHMMSSFF(totalFrame, frameRate)} (Total Time)</StyledText>
        <StyledFileName>{currentTime}</StyledFileName>
        <StyledFileName>
          {videoMeta.indexCode}/{fname}
        </StyledFileName>
      </StyledInfoArea>
    </Wrapper>
  );
};
const StyledInput = styled(Input)`
  background: #313541;
  border-radius: 3px;
  width: 110px;
  height: 24px;
  margin-left: 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
`;
const StyledVjs = styled.div`
  .video-js {
    width: 900px;

    margin: auto;
    .vjs-time-control {
      display: block;
    }

    .vjs-quality-selector {
      .vjs-menu-button {
        .vjs-icon-placeholder::before {
          content: "";
          background: url(${ResolutionSettingIcon}) center no-repeat;
        }
      }
    }

    *:focus {
      outline: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  justify-content: center;
`;
const StyledFileName = styled.div`
  height: 30px;
  margin-left: auto;
`;
const StyledInfoArea = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
`;

const StyledText = styled.div`
  font-size: 14px;
  font-weight: 700;
  box-sizing: border-box;
  color: #010101;
`;

const VerticalLine = styled.div`
  display: inline-block;
  border-left: 1px solid lightgrey;
  height: 14px;
  margin: 0 10px;
`;

const VideoDiv = styled.div`
  width: 900px;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0;
`;

const Square = styled.div`
  width: ${(props) => (props.x > 0 || props.y > 0 ? "7px" : 0)};
  height: ${(props) => (props.x > 0 || props.y > 0 ? "7px" : 0)};
  border: ${(props) => (props.x > 0 || props.y > 0 ? "3.5px" : 0)} solid #00ff38;
  position: absolute;
  left: ${(props) => `${props.x - 3.5}px`};
  top: ${(props) => `${props.y - 3.5}px`};
`;

export default React.memo(React.forwardRef(VideoPlayer));
