import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

/** Button group은 button과 같은 요소의 방향, 정렬, 버튼 사이의 크기를 지정합니다. */
const ButtonGroup = ({ children, ...rest }) => {
  return <StyledButtonGroup {...rest}>{children}</StyledButtonGroup>;
};

ButtonGroup.defaultProps = {
  direction: "row",
  align: "left",
  gap: "1rem",
};

ButtonGroup.propTypes = {
  /** `direction`은 button의 나열 방향을 지정합니다. row와 column으로 구분합니다. */
  direction: PropTypes.oneOf(["row", "column"]),
  /** `align`은 button group의 정렬을 지정합니다. center, right, left로 구분합니다. 기본값은 left 입니다.*/
  align: PropTypes.oneOf(["left", "center", "right"]),
  /** `gap`은 button 사이의 공백을 지정합니다. */
  gap: PropTypes.string,
  /** `children`은 button group 사이에 들어갈 요소입니다. */
  children: PropTypes.node.isRequired,
};

const center = css`
  justify-content: center;
`;

const right = css`
  justify-content: flex-end;
`;

const left = css`
  justify-content: flex-start;
`;

const alignStyles = {
  center,
  right,
  left,
};

// 더 좋은 네이밍 추천 부탁드립니다..
const childDirectionStyle = (gap, direction) => {
  if (direction === "row") {
    return css`
      flex-direction: row;

      > :not(:first-child) {
        margin-left: ${gap};
      }
    `;
  }
  if (direction === "column") {
    return css`
      flex-direction: column;

      > :not(:first-child) {
        margin-top: ${gap};
      }
    `;
  }
};

const StyledButtonGroup = styled.div`
  display: flex;
  ${({ gap, direction }) => childDirectionStyle(gap, direction)};
  ${({ align }) => alignStyles[align]};
`;

export default ButtonGroup;
