import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

const Checkbox = ({ name, value, checked, onChange, shape, color, className, isSeperate, updateView }) => {
  const [seperateChecked, setSeperateChecked] = useState(checked);

  const updateSeperateChecked = () => {
    onChange(seperateChecked);
    setSeperateChecked(!seperateChecked);
  };

  useEffect(() => {
    if (isSeperate && checked !== seperateChecked) {
      setSeperateChecked(checked);
    }
  }, [checked, updateView]);

  return (
    <Wrapper className={className}>
      {!isSeperate ? (
        <StyledInput
          type="checkbox"
          id="checkbox"
          name={name}
          checked={checked}
          value={value}
          onChange={onChange}
          shape={shape}
          color={color}
        />
      ) : (
        <StyledInput
          type="checkbox"
          id="checkbox"
          name={name}
          checked={seperateChecked}
          value={value}
          onChange={updateSeperateChecked}
          shape={shape}
          color={color}
        />
      )}
      <StyledLabel labelFor="checkbox" shape={shape} color={color}>
        <svg width="20px" height="20px" viewBox="0 0 20 20">
          <polyline strokeWidth="2" fill="none" points="4 8 7 11 12 6" strokeLinejoin="round" strokeLinecap="round"></polyline>
        </svg>
      </StyledLabel>
    </Wrapper>
  );
};

Checkbox.defaultProps = {
  name: "checkbox",
  color: "green",
};

const hutomColors = {
  green: "#46b297",
  purple: "#7E5FD3",
  red: "#FF5F5F",
  black: "black",
};

const Wrapper = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`;

const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;

  &:checked ~ label {
    background-color: ${({ shape, color }) => (shape === "rect" ? "white" : hutomColors[color])};
    border-color: ${({ shape, color }) => (shape === "rect" ? "initial" : hutomColors[color])};

    polyline {
      stroke: ${({ shape }) => (shape === "rect" ? "black" : "white")};
      opacity: 1;
      transition: opacity 0.5s ease-in-out;

      ${({ shape }) => {
        if (shape !== "rect")
          return css`
            stroke-dasharray: 100;
            stroke-dashoffset: 100;
            animation: draw 0.5s ease-in-out forwards;
          `;
      }}
    }
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
`;

const StyledLabel = styled.label`
  display: block;
  width: 100%;
  height: 100%;
  border: 2px solid ${({ shape, color }) => (shape === "rect" ? "black" : hutomColors[color])};
  box-sizing: border-box;
  border-radius: ${({ shape }) => (shape === "rect" ? "5px" : "50%")};
  background-color: white;

  polyline {
    stroke: ${({ shape, color }) => (shape === "rect" ? "rgba(0,0,0,0)" : hutomColors[color])};
  }
`;
export default Checkbox;
