import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Button from "../components/atoms/NewButton.jsx";
import ButtonGroup from "../components/atoms/ButtonGroup.jsx";
import VideoPlayer from "../components/organisms/VideoPlayer.jsx";
import PresenceListContainer from "../components/organisms/ListContainer/Presence";
import PresenceInputContainer from "../components/organisms/PresenceInputContainer";
import ModalPortal from "../components/organisms/ModalPortal.jsx";
import Modal from "../components/organisms/Modal.jsx";
import Message from "../components/organisms/Message.jsx";

import { setPresence, resetPresence, setId, importPresence } from "../redux/ducks/presence.js";
import { updateStatus } from "../api";
import { getBasename, exportAnnotationData } from "../utils";

const PresenceAnnotation = ({ task }) => {
  const history = useHistory();

  const videoRef = useRef();

  const [modal, setModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();
  const presenceState = useSelector((state) => state.presence);
  const stopTime = useRef(Number.MAX_VALUE);

  const selectList = (data) => {
    videoRef.current.currentTime = data.start / frameRate;
    stopTime.current = data.end / frameRate;
  };

  const videoMeta = useMemo(() => {
    if (task?.data?.annotationType) {
      const {
        annotationType,
        video: { indexCode, path, totalFrame, frameRate },
      } = task.data;
      const basename = getBasename(path);
      const title = `${indexCode}-${basename}-${annotationType}`;
      return { indexCode, totalFrame, title, annotationType, frameRate, path};
    }
  }, [task]);

  const src = useMemo(() => {
    if (task?.data?.video) {
      const videoId = task.data.video._id;

      return `${window.env.API_URL}/videos/${videoId}`;
    }
  }, [task]);
  const totalFrame = useMemo(() => task?.data?.video?.totalFrame, [task]);
  const frameRate = useMemo(() => (task?.data?.video?.frameRate ? task?.data?.video?.frameRate : 30), [task]);
  const annotationType = useMemo(() => task?.data?.annotationType.replaceAll("_", " "), [task]);

  const warningModal = {
    type: "warning",
    message: "Annotations cannot be modified after submission. Would you like to submit?",
    actions: [
      {
        label: "Yes",
        theme: "secondary",
        onClick: () => {
          setSubmit(true);
          setModal(false);
        },
        submitOnEnter: true,
      },
      {
        label: "No",
        onClick: () => setModal(false),
        cancelOnEsc: true,
      },
    ],
  };

  const mvToHome = () => history.push("/");

  const showModal = () => setModal(true);

  const exportData = () => {
    if (task?.data) {
      exportAnnotationData(task.data, videoRef, frameRate);
    }
  };

  const renderCheckUserBtn = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const role = Object.keys(user.role);

    return role[0] === "admin" ? (
      <Button label="Export" theme="secondary" disabled={disabled} onClick={exportData} />
    ) : (
      <Button label="Submit" theme="secondary" onClick={showModal} />
    );
  };

  useEffect(() => {
    const player = videoRef.current;

    player.addEventListener("timeupdate", () => {
      if (player.currentTime >= stopTime.current) {
        player.pause();
        player.currentTime = stopTime.current;
        stopTime.current = Number.MAX_VALUE;
      }
    });
    return () => {
      dispatch(resetPresence());
    };
  }, []);
  const loadJSONFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      let json;
      reader.onload = (e) => {
        json = JSON.parse(e.target.result);
        const result = [];

        json.annotations.forEach((el) => {
          result.push(el);
        });
        dispatch(importPresence(result));
      };

      reader.readAsText(e.target.files[0]);
    } else {
      alert("Failed import JSON file");
    }
  };

  useEffect(() => {
    if (task?.data?.annotations) {
      const [presence] = task.data.annotations;
      dispatch(setId(presence._id));
      dispatch(setPresence(presence.data));
    }
  }, [task]);

  useEffect(() => {
    if (submit) {
      updateStatus(task?.data?._id, "DONE")
        .then((res) => {
          if (res.status === 200) history.replace("/");
        })
        .catch((err) => alert(err.message));
    }
  }, [submit]);

  useEffect(() => {
    if (task?.data?.status === "DONE") {
      setDisabled(false);
    }
  }, [task]);
  return task?.data ? (
    <>
      <Wrapper>
        <Container>
          <StyledLeftStack>
            <StyledVideoPlayer 
              videoMeta={videoMeta} 
              src={src} 
              refs={{ videoRef }} 
              frameRate={frameRate} 
            />
            <StyledPresenceController
              totalFrame={totalFrame}
              presenceState={presenceState}
              annotationType={annotationType}
              frameRate={frameRate}
              onChange={loadJSONFile}
            />
          </StyledLeftStack>
          <StyledRightStack>
            <PresenceListContainer
              totalFrame={totalFrame}
              presenceState={presenceState}
              annotationType={annotationType}
              onClickListItem={selectList}
              frameRate={frameRate}
            />
            <StyledButtonGroup direction="row" align="right">
              <Button label="Exit" onClick={mvToHome} />
              {renderCheckUserBtn()}
            </StyledButtonGroup>
          </StyledRightStack>
        </Container>
      </Wrapper>
      {modal && (
        <ModalPortal>
          <Modal>
            <Message {...warningModal} />
          </Modal>
        </ModalPortal>
      )}
    </>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledLeftStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  min-width: 1000px;
`;
const StyledRightStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 300px;
  margin-left: 32px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: auto;
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  margin-bottom: 15px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
`;

const StyledPresenceController = styled(PresenceInputContainer)`
  width: 100%;
  height: 100px;
  border-radius: 10px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
`;

export default PresenceAnnotation;