import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

// TODO production에서는 logger 제외해야 하나?
let middlewares = [thunk];
if (process.env.NODE_ENV !== "production")
  middlewares = [...middlewares, logger];

export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
