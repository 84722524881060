import React from "react";
import styled from "styled-components";
import Checkbox from "../atoms/Checkbox.jsx";

const CheckboxWithLabel = ({
  name,
  value,
  checked,
  onChange,
  labelText,
  shape,
  labelStyle,
}) => {
  return (
    <Wrapper>
      <Checkbox
        name={name}
        value={value}
        onChange={onChange}
        shape={shape}
        checked={checked}
      />
      <StyledLabelText labelStyle={labelStyle}>{labelText}</StyledLabelText>
    </Wrapper>
  );
};

CheckboxWithLabel.defaultProps = {
  shape: "rect",
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StyledLabelText = styled.div`
  margin-left: 10px;
  font-size: ${({ labelStyle }) =>
    (labelStyle && labelStyle.fontSize) || "initial"};
  font-weight: ${({ labelStyle }) =>
    (labelStyle && labelStyle.fontWeight) || "initial"};
`;

export default CheckboxWithLabel;
