import React from "react";
import { ReactComponent as BtnDelete } from "../../../assets/delete-circle-black.svg";
import { toHHMMSSFF } from "../../../utils/annotation";

import { CODES } from "../../organisms/constants";

import "./style.scss";

const ListItem = ({
  row,
  onClickRow,
  onClickDelete,
  disableRemove,
  isSelected,
  frameRate,
  annotationType,
  style,
}) => {
  const { code, start, end, checked, index, type, label } = row;
  const getClassName = () => {
    if (isSelected) return "list-item selected";
    else if (code === 0) return "list-item highlight";
    else if (checked) return "list-item checked";
    else if (annotationType === "frame_selection" && row.code === 2)
      return "list-item guided";
    return "list-item";
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onClickDelete(row);
  };

  return (
    <div className={getClassName()} onClick={onClickRow} style={style}>
      {annotationType !== "frame_selection" &&
        annotationType !== "ACTIVE BLEEDING" &&
        annotationType !== "INTERVENTION" &&
        code && <div className="item-code">{code}</div>}
      {annotationType === "frame_selection" ||
        (annotationType === "presence" && label && (
          <div className="item-code">{label}</div>
        ))}
      {annotationType === "INTERVENTION" && (
        <div className="index-code">{index}</div>
      )}
      {annotationType === "INTERVENTION" && type && (
        <div className="type">{type}</div>
      )}
      {annotationType === "INTERVENTION" && type && code && (
        <div className="intervention">{CODES["INTERVENTION"][code]}</div>
      )}
      <div className={disableRemove ? "item-frame" : "item-frame enabled"}>
        {toHHMMSSFF(start, frameRate)}{" "}
        {annotationType === "frame_selection"
          ? ""
          : `- ${toHHMMSSFF(end, frameRate)}`}
        {!disableRemove && (
          <div className="delete-item" onClick={handleDelete}>
            <BtnDelete />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListItem;
