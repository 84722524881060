import React from "react";
import styled from "styled-components";
import { ReactComponent as NotiIcon } from "../../assets/noti.svg";

const NotiBadge = (props) => {
  return (
    <Wrapper>
      <NotiIcon {...props} />
      {props.nunreads > 0 && <StyledBadge>{props.nunreads}</StyledBadge>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
`;

const StyledBadge = styled.div`
  position: absolute;
  display: inline-block;
  background-color: crimson;
  color: white;
  font-size: 10px;
  font-weight: 500;
  width: 12px;
  line-height: 12px;
  border-radius: 50%;
  text-align: center;
  text-anchor: middle;
  top: 10px;
  right: 23px;
`;

export default NotiBadge;
