import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import Input from "../atoms/NewInput.jsx";
import Button from "../atoms/NewButton.jsx";
import Selector from "../molecules/Selector.jsx";

import { validate } from "../../utils/validate.js";

import { CODES } from "./constants.js";
import {
  toFrame,
  toHHMMSSFF,
  completeHHMMSSFF,
} from "../../utils/annotation.js";

import {
  setSelected,
  check,
  insertThunk,
  clearWarning,
} from "../../redux/ducks/bleeding.js";
import ModalPortal from "./ModalPortal.jsx";
import Modal from "./Modal.jsx";
import Message from "./Message.jsx";

const AnnotationInputContainer = ({
  className,
  totalFrame,
  annotationState,
  videoMeta,
  frameRate,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { selected, warning } = annotationState;

  const [code, setCode] = useState(null);
  const [frameInputValues, setFrameInputValues] = useState({
    start: "00:00:00:00",
    end: "00:00:00:00",
  });
  const [startFrameInput, setStartFrameInput] = useState(-1);
  const [reset, setReset] = useState(0);

  const updateCode = (code) => {
    setCode(code);
    dispatch(setSelected({ code: code }));
  };
  const updateInput = useCallback(
    ({ target: { name, value } }) => {
      if (validate(value, "hhmmssff")) {
        const newValues = {
          ...frameInputValues,
          [name]: value,
        };
        setFrameInputValues(newValues);
      }
    },
    [frameInputValues]
  );

  const dispatchAnnotation = () => {
    if (!code) return;

    const data = {
      ...frameInputValues,
      start: toFrame(frameInputValues.start, frameRate),
      end: toFrame(frameInputValues.end, frameRate),
      code: code,
    };

    dispatch(check(data));
    setCode(null);
  };

  const resetInput = () => {
    dispatch(setSelected(null));
    setCode(null);
  };

  const inputOnKeyDown = (e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      updateValidatedInputs();
      dispatchAnnotation();
    }
  };

  const updateValidatedInputs = () => {
    const startHHMMSSFF = completeHHMMSSFF(frameInputValues.start);
    const endHHMMSSFF = completeHHMMSSFF(frameInputValues.end);
    const startFrame = toFrame(startHHMMSSFF, frameRate);
    const endFrame = toFrame(endHHMMSSFF, frameRate);
    dispatch(setSelected({ start: startFrame, end: endFrame, code: code }));

    setFrameInputValues({
      ...frameInputValues,
      start: toHHMMSSFF(
        startFrame < totalFrame ? startFrame : totalFrame,
        frameRate
      ),
      end: toHHMMSSFF(endFrame < totalFrame ? endFrame : totalFrame, frameRate),
    });
  };

  const insertAnnotation = () => dispatch(insertThunk(warning.data));
  const closeWarning = () => dispatch(clearWarning());

  const inputProps = [
    {
      placeholder: "Start",
      name: "start",
      value:
        frameInputValues?.start !== undefined
          ? frameInputValues.start
          : "00:00:00:00",
      onChange: updateInput,
      onKeyDown: inputOnKeyDown,
      onBlur: updateValidatedInputs,
      onFocus: (e) => e.target.setSelectionRange(0, e.target.value.length),
      autoComplete: "off",
    },
    {
      placeholder: "End",
      name: "end",
      value:
        frameInputValues?.end !== undefined ? frameInputValues.end : "00:00:00",
      onChange: updateInput,
      onKeyDown: inputOnKeyDown,
      onBlur: updateValidatedInputs,
      onFocus: (e) => e.target.setSelectionRange(0, e.target.value.length),
      autoComplete: "off",
    },
  ];

  const renderInputs = () =>
    inputProps.map((item, i) => <StyledInput key={"" + reset + i} {...item} />);

  const makeString = (obj) => {
    const { code, start, end } = obj;
    const startHHMMSSFF = toHHMMSSFF(start, frameRate);
    const endHHMMSSFF = toHHMMSSFF(end, frameRate);
  
    console.log("obj: ", obj);
    console.log("index: ", obj.index);
   
    return `(${code}) ${startHHMMSSFF} - ${endHHMMSSFF}`;
  };

  const getMessage = () => {
    const msg = warning.list.map((curr) => makeString(curr)).join(", ");

    console.log("warning list: ", warning);
    console.log("getMsg: ",msg);

    return `The section you filled in, ${makeString(
      warning.data
    )} is overlapped with ${msg}. Do you want to change it?`;
  };

  const warningModal = useMemo(() => {
    if (!warning.data) return;

    return {
      type: "warning",
      message: getMessage(),
      actions: [
        {
          label: "Yes",
          theme: "secondary",
          onClick: insertAnnotation,
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: closeWarning,
          cancelOnEsc: true,
        },
      ],
    };
  }, [warning]);

  useEffect(() => {
    if (selected) {
      selected.start = !selected.start
        ? 0
        : selected.start < totalFrame
        ? selected.start
        : totalFrame;
      selected.end = !selected.end
        ? 0
        : selected.end < totalFrame
        ? selected.end
        : totalFrame;
      selected.start >= selected.end
        ? setStartFrameInput(0)
        : setStartFrameInput(selected.end);
      setFrameInputValues({
        ...selected,
        start: toHHMMSSFF(selected.start, frameRate),
        end: toHHMMSSFF(selected.end, frameRate),
      });
    } else {
      setFrameInputValues({
        start: toHHMMSSFF(startFrameInput + 1, frameRate),
        end: "00:00:00:00",
      });
    }
    setCode(selected?.code);
    setReset(reset + 1);
  }, [selected]);

  useEffect(() => {
    const { data, list } = warning;
    if (data && !list?.length) dispatch(insertThunk(warning.data));

    setFrameInputValues({ start: "00:00:00:00", end: "00:00:00:00" });
    setReset(reset + 1);
  }, [warning]);

  return (
    <>
      <Wrapper className={className}>
        <StyledContent>
          <StyledInputWrapper>
            {renderInputs()}
            <StyledLabel htmlFor="import_json">Import JSON</StyledLabel>
            <StyledImportInput
              type="file"
              id="import_json"
              name="import_json"
              accept=".json"
              onChange={onChange}
            />
            <StyledButtonWrapper>
              {selected && (
                <Button label="Cancel" size="small" onClick={resetInput} />
              )}
              <Button
                label={selected?.code ? "Save" : "Add"}
                theme="secondary"
                size="small"
                onClick={dispatchAnnotation}
              />
            </StyledButtonWrapper>
          </StyledInputWrapper>
          {videoMeta &&
            videoMeta.annotationType &&
            CODES[videoMeta.annotationType] && (
              <Selector
                contents={CODES[videoMeta.annotationType]}
                onClick={updateCode}
                active={code}
                clickable={true}
              />
            )}
        </StyledContent>
      </Wrapper>
      {warning?.list?.length > 0 && (
        <ModalPortal>
          <Modal>
            <Message {...warningModal} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  height: 120px;
  max-height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  line-height: 30px;
  font-size: 14px;
  padding: 0 40px;
  box-sizing: border-box;
  font-weight: 500;
`;

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 40px;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  height: 50%;
  align-items: center;
`;

const StyledInput = styled(Input)`
  font-size: 10px;

  :not(:first-of-type) {
    margin-left: 32px;
  }
`;

const StyledButtonWrapper = styled.div`
  margin: auto 0 auto auto;
  button:not(:first-child) {
    margin-left: 15px;
  }
`;

const StyledImportInput = styled.input`
  display: none;

  &:hover {
    cursor: point;
  }
`;

const StyledLabel = styled.label`
  background-color: #00a999;
  padding: 5px 5px;
  border-radius: 70px;
  width: 100px;
  height: auto;
  font-size: 0.8rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;
`;

export default AnnotationInputContainer;
