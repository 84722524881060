import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { useRequest } from "../hooks";

import Button from "../components/atoms/NewButton";
import Table from "../components/organisms/Table";
import TableHead from "../components/molecules/TableHead";
import TableBody from "../components/molecules/TableBody";
import TableRow from "../components/molecules/TableRow";
import TableCell from "../components/atoms/TableCell";

import { getFileName } from "../utils/common";
import { getDate } from "../utils/date";
import { getCompletionRate, normalizeString, capitalizeFirstLetter } from "../utils";

import { updateStatus } from "../api";

const AnnotatorMain = () => {
  const history = useHistory();

  const [result, send] = useRequest();
  const [annotatorTaskData, setAnnotatorTaskData] = useState([]);

  const headerData = ["Date", "Index", "Video", "", "Annotation Type", ""];

  const getAnnotationType = (annotations) =>
    annotations.length > 1 ? "Phase" : capitalizeFirstLetter(normalizeString(annotations[0].type));

  const changeStatus = async (taskId, taskStatus) => {
    try {
      let status = null;
      if (taskStatus === "NOT_STARTED") {
        const res = await updateStatus(taskId, "IN_PROGRESS");
        status = res.status;
      }
      if ([null, 200].includes(status)) return history.push(`video-tasks/${taskId}`);
    } catch (error) {
      alert(error.message);
    }
  };

  const getTasksUidata = (taskData) => {
    const tasks = taskData.map((task) => {
      const { _id, createdAt, status, annotationType } = task;
      let indexCode, path;
      if (task.video) {
        indexCode = task.video.indexCode;
        path = task.video.path;
      } else {
        indexCode = null;
        path = null;
      }

      const onClickButton = () => changeStatus(_id, status);

      const disabled = status === "DONE";

      return [
        getDate(createdAt),
        indexCode,
        getFileName(path),
        capitalizeFirstLetter(normalizeString(status)),
        annotationType,
        <Button onClick={onClickButton} disabled={disabled} label="Annotate" theme="secondary" />,
      ];
    });

    setAnnotatorTaskData([...tasks]);
  };

  // table data render
  const renderTableHeader = () => {
    return headerData.map((data, i) => <TableCell align={i !== headerData.length - 1 ? "left" : "right"}>{data}</TableCell>);
  };

  const renderTableBody = () => {
    return annotatorTaskData.map((row) => (
      <TableRow>
        {row.map((data, i) => (
          <TableCell align={i !== headerData.length - 1 ? "left" : "right"}>{data}</TableCell>
        ))}
      </TableRow>
    ));
  };

  useEffect(() => {
    const request = {
      method: "GET",
      url: `/video-tasks`,
    };
    send(request);
  }, []);

  useEffect(() => {
    if (result && result.data) {
      result.data && getTasksUidata(result.data);
    }
  }, [result]);

  return (
    <StyledWrapper>
      <StyledTable>
        <TableHead>
          <TableRow>{headerData && renderTableHeader()}</TableRow>
        </TableHead>
        <TableBody>{annotatorTaskData && renderTableBody()}</TableBody>
      </StyledTable>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: center;
`;

const StyledTable = styled(Table)`
  width: 95%;
  margin: 30px;
  overflow: scroll;
`;

export default AnnotatorMain;
