import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { updateUserThunk } from "../../redux/actions.js";
import { cropImage } from "../../utils";
import CameraIcon from "../../assets/camera.svg";
import UserIcon from "../../assets/profile-annotator-bk.svg";

const ProfileImg = ({ image, onClick }) => {
  const fileInput = useRef();
  const img = useRef();

  const dispatch = useDispatch();

  const handleFile = async () => {
    const file = fileInput.current.files[0];

    if (file) {
      const url = URL.createObjectURL(file);
      const result = await cropImage(url, 1);
      img.current.src = result;
      dispatch(updateUserThunk("image", result));
    }
  };

  return (
    <Wrapper onClick={onClick}>
      <StyledInput
        ref={fileInput}
        type="file"
        accept=".jpg,.png"
        onChange={handleFile}
      />
      <StyledImg ref={img} src={image ? image : UserIcon} alt="profile" />
      <StyledIcon src={CameraIcon} alt="upload" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 90px;
  height: 90px;
  position: relative;
`;

const StyledInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const StyledIcon = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export default ProfileImg;
