import { SET_USER, RESET_USER, SET_TEMP_USER } from "./actionTypes";
import { verifyToken, updateUser } from "../api";

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const resetUser = () => ({
  type: RESET_USER,
});

export const setTempUser = () => ({
  type: SET_TEMP_USER,
});

// Actions for thunk
export const verifyUserAsyncThunk = (onSuccess, onFailure) => async (dispatch) => {
  try {
    const respond = await verifyToken();
    const { _id, name, task, role, email, isAgreed, image } = respond.data;

    dispatch(
      setUser({
        id: _id,
        username: name,
        task: task.toLowerCase(),
        role: role,
        email,
        isAgreed,
        image,
      })
    );
    if (onSuccess) onSuccess();
  } catch (err) {
    alert(err.message);
    dispatch(resetUser());
    if (onFailure) onFailure();
  }
};

export const updateUserThunk = (key, value) => async (dispatch, getState) => {
  const user = getState().user;
  try {
    await updateUser(user.id, { [key]: value });
    dispatch(setUser({ ...user, [key]: value }));
  } catch (err) {
    alert(err.message);
  }
};
