import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Button from "../atoms/NewButton.jsx";

import Input from "../atoms/NewInput";
import GradingSite from "../molecules/GradingSite";
import GradingCause from "../molecules/GradingCause";
import GradingCharacteristics from "../molecules/GradingCharacteristics";
import GradingRemedy from "../molecules/GradingRemedy";
import { changeGrading } from "../../redux/ducks/grading";

const GradingInputContainer = ({ onChange, grading, onSave }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { currentGrading, currentIndex } = grading;
  const dispatch = useDispatch();
  useEffect(() => {
    setActiveTab(0);
  }, [grading.currentIndex]);

  const updateInput = useCallback(
    (e) => {
      const { dataset, name, value } = e.target;
      const directAssignList = ["identical", "comment"];
      const c = {};
      if (directAssignList.includes(dataset.id)) {
        if (dataset.id === "identical") {
          if (value === "") {
            c[dataset.id] = "";
          } else {
            c[dataset.id] = +value;
          }
        } else {
          c[dataset.id] = value;
        }
      } else {
        c[dataset.id] = { ...currentGrading[dataset.id] };
        c[dataset.id][name] = value;
      }

      dispatch(changeGrading(c));
    },
    [currentGrading]
  );

  const updateSite = useCallback(
    (e) => {
      const { dataset, name, value } = e.target;

      const c = {};
      c[dataset.id] = { ...currentGrading[dataset.id] };
      c[dataset.id][name] = +value;

      dispatch(changeGrading(c));
    },
    [currentGrading]
  );
  const updateRemedyEffective = useCallback(
    (e, index) => {
      if (
        currentGrading &&
        currentGrading.remedy &&
        currentGrading.remedy.length &&
        currentGrading.remedy.length > index
      ) {
        const c = { remedy: [] };
        for (let i = 0; i < currentGrading.remedy.length; i++) {
          if (i === index) {
            c.remedy.push({
              ...currentGrading.remedy[i],
              isEffective: +e.target.value,
            });
          } else {
            c.remedy.push(currentGrading.remedy[i]);
          }
        }
        dispatch(changeGrading(c));
      } else {
        const c = { remedy: [] };
        for (let i = 0; i < currentGrading?.remedy?.length; i++) {
          c.remedy.push(currentGrading.remedy[i]);
        }
        c.remedy.push({ isEffective: +e.target.value });
        dispatch(changeGrading(c));
      }
    },
    [currentGrading]
  );
  const updateRemedyMethod = useCallback(
    (e, index) => {
      let code;
      if (typeof e === "string") {
        code = e;
      } else {
        code = e.target.value;
      }
      if (
        currentGrading &&
        currentGrading.remedy &&
        currentGrading.remedy.length &&
        currentGrading.remedy.length > index
      ) {
        const c = { remedy: [] };
        for (let i = 0; i < currentGrading.remedy.length; i++) {
          if (i === index) {
            if (currentGrading.remedy[i].method === code) {
              c.remedy.push({});
            } else {
              c.remedy.push({ ...currentGrading.remedy[i], method: code });
            }
          } else {
            c.remedy.push(currentGrading.remedy[i]);
          }
        }
        dispatch(changeGrading(c));
      } else {
        const c = { remedy: [] };
        for (let i = 0; i < currentGrading?.remedy?.length; i++) {
          c.remedy.push(currentGrading.remedy[i]);
        }
        c.remedy.push({ method: code });
        dispatch(changeGrading(c));
      }
    },
    [currentGrading]
  );

  const updateCause = useCallback(
    (v) => {
      if (typeof v === "string") {
        const c = {};
        c.cause = v;
        dispatch(changeGrading(c));
      } else {
        const c = {};
        if (!v.target.value) {
          c.cause = "";
          dispatch(changeGrading(c));
        } else {
          c.cause = v.target.value;
          dispatch(changeGrading(c));
        }
      }
    },

    [currentGrading]
  );

  const updateCharacteristic = useCallback(
    (e) => {
      const { dataset, name, value } = e.target;
      const c = {
        characteristics: {},
      };
      if (name === "isOozing") {
        c.characteristics[name] = 1;
      } else {
        if (name === "isPulsatile") {
          c.characteristics.isBrisk = 1;
          c.characteristics[name] = +value;
        } else {
          c.characteristics.isOozing = 1;
          if (+value === 1) {
            c.characteristics[name] = 0;
          } else {
            c.characteristics[name] = 1;
          }
        }
      }
      dispatch(changeGrading(c));
    },
    [currentGrading]
  );

  const handleChangeTab = (index) => {
    if (currentIndex < 0) {
      alert("Annotate 할 Bleeding을 선택해주세요.");
      return;
    }
    const remedy = currentGrading.remedy;
    const indexStart = 5;
    if (index > indexStart) {
      if (!remedy?.length) {
        alert("전 Remedy 데이터를 확인해주세요");
        return;
      } else if (remedy.length < index - indexStart) {
        alert("전 Remedy 데이터를 확인해주세요");
        return;
      }
    }
    setActiveTab(index);
  };
  const copyGrading = () => {
    if (currentGrading?.identical < grading?.grading?.length) {
      if (grading.currentIndex >= 0) {
        if (+currentGrading.identcial !== currentIndex) {
          const c = {
            ...grading.grading[currentGrading.identical],
            identical: currentGrading.identical,
            start: currentGrading.start,
            end: currentGrading.end,
            x: currentGrading.x,
            y: currentGrading.y,
          };
          dispatch(changeGrading(c));
          alert("복사가 완료되었습니다. Save 버튼을 꼭 눌러주세요.");
        } else {
          alert("선택한 Bleeding이 현재 Bleeding 입니다.");
        }
      } else {
        alert("Annotate할 annotation을 선택해주세요.");
      }
    }
  };
  const renderIdentical = () => {
    return (
      <StyledInputWrapper>
        <StyledTitle>Identical</StyledTitle>
        <StyledInput
          id="grading-input"
          value={
            currentGrading?.identical || currentGrading.identical === 0
              ? currentGrading.identical
              : ""
          }
          name="identical"
          onChange={updateInput}
          data-id="identical"
        />
        <StyledButton label="enter" onClick={copyGrading} />
      </StyledInputWrapper>
    );
  };
  const inputOnKeyDown = (e) => {
    e.stopPropagation();
  };
  const renderComment = () => {
    return (
      <StyledInputWrapper>
        <StyledTitle>Comment</StyledTitle>
        <StyledInput
          id="grading-input"
          value={currentGrading?.comment ? currentGrading.comment : ""}
          name="comment"
          onChange={updateInput}
          onClick={updateCause}
          onKeyDown={inputOnKeyDown}
          data-id="comment"
        />
      </StyledInputWrapper>
    );
  };

  const tabs = [
    { title: "Identical", component: renderIdentical() },

    {
      title: "Site",
      component: (
        <GradingSite
          onChange={updateInput}
          onChangeSite={updateSite}
          data={currentGrading?.site}
          onKeyDown={inputOnKeyDown}
        />
      ),
    },
    {
      title: "Cause",
      component: (
        <GradingCause
          onChange={updateCause}
          data={currentGrading?.cause}
          onClick={updateCause}
          annotationType="GRADING"
          onKeyDown={inputOnKeyDown}
        />
      ),
    },
    {
      title: "Characteristics",
      component: (
        <GradingCharacteristics
          data={currentGrading?.characteristics}
          onClick={updateCharacteristic}
        />
      ),
    },
    { title: "Comment", component: renderComment() },
    {
      title: "Remedy1",
      component: (
        <GradingRemedy
          title="remedy1"
          data={
            currentGrading?.remedy?.length >= 1
              ? currentGrading.remedy[0]
              : null
          }
          annotationType="GRADING"
          onRemedyMethod={updateRemedyMethod}
          onChangeEffective={updateRemedyEffective}
          onKeyDown={inputOnKeyDown}
          index={0}
        />
      ),
    },
    {
      title: "Remedy2",
      component: (
        <GradingRemedy
          title="remedy2"
          data={
            currentGrading?.remedy?.length >= 2
              ? currentGrading.remedy[1]
              : null
          }
          annotationType="GRADING"
          onRemedyMethod={updateRemedyMethod}
          onChangeEffective={updateRemedyEffective}
          onKeyDown={inputOnKeyDown}
          index={1}
        />
      ),
    },
    {
      title: "Remedy3",
      component: (
        <GradingRemedy
          title="remedy3"
          data={
            currentGrading?.remedy?.length >= 3
              ? currentGrading.remedy[2]
              : null
          }
          annotationType="GRADING"
          onRemedyMethod={updateRemedyMethod}
          onChangeEffective={updateRemedyEffective}
          onKeyDown={inputOnKeyDown}
          index={2}
        />
      ),
    },
    {
      title: "Remedy4",
      component: (
        <GradingRemedy
          title="remedy4"
          data={
            currentGrading?.remedy?.length >= 4
              ? currentGrading.remedy[3]
              : null
          }
          annotationType="GRADING"
          onRemedyMethod={updateRemedyMethod}
          onChangeEffective={updateRemedyEffective}
          onKeyDown={inputOnKeyDown}
          index={3}
        />
      ),
    },
    {
      title: "Remedy5",
      component: (
        <GradingRemedy
          title="remedy5"
          data={
            currentGrading?.remedy?.length >= 5
              ? currentGrading.remedy[4]
              : null
          }
          index={4}
          annotationType="GRADING"
          onChangeEffective={updateRemedyEffective}
          onRemedyMethod={updateRemedyMethod}
          onKeyDown={inputOnKeyDown}
        />
      ),
    },
  ];

  const renderTabs = () =>
    tabs.map((tab, i) => (
      <StyledTabButton
        key={i}
        active={activeTab === i}
        onClick={() => handleChangeTab(i)}
        name={tab.title}
      >
        <div active={activeTab === i}>{tab.title}</div>
      </StyledTabButton>
    ));

  return (
    <Wrapper>
      <StyledTabContainer>
        {renderTabs()}
        <StyledSaveBtn onClick={onSave}>SAVE</StyledSaveBtn>
        <StyledLabel htmlFor="import_json">Import</StyledLabel>
        <StyledImportInput
          type="file"
          id="import_json"
          name="import_json"
          accept=".json"
          onChange={onChange}
        />
      </StyledTabContainer>
      {tabs[activeTab].component}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  height: 150px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #d1d2d3;
`;

const StyledTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 40px;
`;

const StyledTabButton = styled.div`
  width: 100%;
  height: 30px;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  border-top: ${({ active }) => (active ? "2px solid #00a999" : "none")};
  border-bottom: ${({ active }) => (active ? "none" : "1px solid #d1d2d3")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.6s;
  background: ${({ active }) => (active ? "#ffffff" : "#F2F2F2")};
  color: ${({ active }) => (active ? "#000000" : "#666666")};
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 2px;
  }
`;

const StyledSaveBtn = styled.button`
  width: 100%;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 2px;
  border: 1px solid #00a999;
  color: #00a999;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

const StyledInputWrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  display: flex;
`;

const StyledTitle = styled.div`
  width: fit-content;
  border: 2px solid black;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 30px;
`;

const StyledInput = styled(Input)`
  width: 500px;
  border-radius: 5px;
`;
const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const StyledImportInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  width: 100%;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  margin-top: 2px;
  border: 1px solid #00a999;
  color: #00a999;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export default GradingInputContainer;
