import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CheckboxWithLabel from './CheckboxWithLabel';

const CheckboxList = ({title, onChangeAll, datas, onChange, onClick}) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const changeAll = (e) => {
    onChangeAll(e);
    setCheckedAll(e.target.checked);
  }

  const checkAll = () => {
    let allFlag = true
    if(datas.length) {
      for(let i=0;i<datas.length;i++) {
        if (!datas[i].checked) allFlag =false;
      }
      if(allFlag) setCheckedAll(true)
      else setCheckedAll(false)
    } else {
      setCheckedAll(false)
    }   
  }  
  useEffect(()=> {
    checkAll();
  })
  const titleStyle = {fontSize:"20px", fontWeight:'bold'}
  return (
    <>   
      <StyledListTitle>
        <CheckboxWithLabel labelStyle={titleStyle} onChange={changeAll} labelText= {title} checked= {checkedAll}/>
      </StyledListTitle>
      <StyledList>
        {datas.map((data, i) => (
          <StyledListItem selected={data.selected} key={i} value ={i} onClick ={onClick} >
            <CheckboxWithLabel  onChange={onChange} checked={data.checked} labelText= {data.label} value={i} />
          </StyledListItem>
        ))}          
      </StyledList>
    </>
  );
};

const StyledListTitle = styled.div`
  width: 96%;
  max-width: 96%;
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #A0A0A0;
  padding-left: 4%;
`;

const StyledList = styled.div`
  flex: 1;
  width: 100%;
  overflow-y: auto;
`;

const StyledListItem = styled.li`
  width: 100%;
  max-width: 96%;
  height:52px;
  padding-left:4%;
  display:flex;
  align-items: center;
  cursor:pointer;
  background:${props => props.selected ? '#B9E8DF;' : '#FFFFFF;'};
`;

export default CheckboxList;