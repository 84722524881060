import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRequest } from "../hooks";
import { useSelector } from "react-redux";
import { getUserState } from "../redux/selectors";
import { ReactComponent as UnlockIcon } from "../assets/unlock.svg";
import Form from "../components/molecules/Form.jsx";
import styled from "styled-components";

// !! TEMPORAL VALIDATION !!
// TODO password validation (according to security policy)
const EditPassword = () => {
  const history = useHistory();

  const { id } = useSelector(getUserState);
  const [result, send] = useRequest();

  const [newPassword, setNewPassword] = useState();

  const cmpInputs = () => newPassword?.length;

  const putUserPassword = (data) => {
    const request = {
      method: "PUT",
      url: `/users/${id}/password`,
      data,
    };
    send(request);
  };

  const clearInputs = () => {
    alert("Check password again.");
    setNewPassword(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const isMatch = cmpInputs();
    isMatch ? putUserPassword({ password: newPassword }) : clearInputs();
  };

  const formActions = {
    onSubmit: {
      label: "Done",
      action: onSubmit,
      disabled: !newPassword?.length,
    },
  };

  const items = [
    {
      type: "password",
      value: newPassword || "",
      onChange: ({ target: { value } }) => setNewPassword(value),
      // isValid: isValidPassword,
      placeholder: "************",
    },
  ];

  useEffect(() => {
    if (result.type === "success") {
      history.push({ pathname: "/" });
    }
  }, [result, send, history]);

  return (
    <Wrapper>
      <Container>
        <UnlockIcon />
        <StyledTitle>Set Password</StyledTitle>
        <StyledDescription>
          Please reset your password before using annotator platform
        </StyledDescription>
        <Form formActions={formActions} items={items} />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  width: 670px;
  height: 500px;
  max-width: 670px;
  max-height: 500px;
  padding: 45px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const StyledDescription = styled.div`
  font-size: 12px;
  margin-bottom: 30px;
`;

export default EditPassword;
