import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import Button from "../components/atoms/NewButton.jsx";
import ButtonGroup from "../components/atoms/ButtonGroup.jsx";
import VideoPlayer from "../components/organisms/VideoPlayer.jsx";
import { setArmes, setOnlyArmes, resetPhase, setId } from "../redux/ducks/phase.js";
import PhaseListContainer from "../components/organisms/PhaseListContainer";
import PhaseInputContainer from "../components/organisms/PhaseInputContainer";
import ModalPortal from "../components/organisms/ModalPortal.jsx";
import Modal from "../components/organisms/Modal.jsx";
import Message from "../components/organisms/Message.jsx";
import { updateStatus } from "../api";
import { getBasename } from "../utils";

const PhaseAnnotation = ({ task }) => {
  const history = useHistory();

  const videoRef = useRef();
  const stopFrameRef = useRef();
  const progressInfoRef = useRef();

  const [modal, setModal] = useState(null);
  const [submit, setSubmit] = useState(false);

  const dispatch = useDispatch();

  const phase = useSelector((state) => state.phase);

  const videoMeta = useMemo(() => {
    if (task?.data?.annotationType) {
      const {
        annotationType,
        video: { indexCode, path, totalFrame, frameRate },
      } = task.data;
      const basename = getBasename(path);
      const title = `${indexCode}-${basename}-${annotationType}`;
      return { indexCode, totalFrame, title, frameRate, annotationType, path};
    }
  }, [task]);

  const src = useMemo(() => {
    if (task?.data?.video) {
      const videoId = task.data.video._id;
      return `${window.env.API_URL}/videos/${videoId}`;
    }
  }, [task]);

  const totalFrame = useMemo(() => task?.data?.video?.totalFrame, [task]);
  const frameRate = useMemo(() => (task?.data?.video?.frameRate ? Math.ceil(task?.data?.video?.frameRate) : 30), [task]);

  const errModal = {
    type: "error",
    message: "Annotation cannot be submitted because the annoations of Armes and Only Armes are not completed!",
    actions: [
      {
        label: "Ok",
        theme: "secondary",
        onClick: () => {
          setModal(null);
        },
        submitOnEnter: true,
      },
    ],
  };

  const warningModal = {
    type: "warning",
    message: "Annotations cannot be modified after submission. Would you like to submit?",
    actions: [
      {
        label: "Yes",
        theme: "secondary",
        onClick: () => {
          setSubmit(true);
          setModal(null);
        },
        submitOnEnter: true,
      },
      {
        label: "No",
        onClick: () => setModal(null),
        cancelOnEsc: true,
      },
    ],
  };

  const mvFrameTo = (to) => {
    videoRef.current.currentTime = to * (1 / frameRate);
  };

  const exit = () => history.push("/");

  const checkBeforeSubmit = async (event) => {
    event.target.blur();

    const { rate, finished } = progressInfoRef.current;
    if (rate < 100 || !finished) {
      setModal(errModal);
      return;
    }
    setModal(warningModal);
  };

  useEffect(() => () => dispatch(resetPhase()), []);

  useEffect(() => {
    if (task?.data?.annotations) {
      const [armes, onlyArmes] = task.data.annotations;
      dispatch(setId({ armes: armes._id, onlyArmes: onlyArmes._id }));
      dispatch(setArmes(armes.data));
      dispatch(setOnlyArmes(onlyArmes.data));
    }
  }, [task]);

  useEffect(() => {
    if (submit) {
      updateStatus(task?.data?._id, "DONE")
        .then((res) => {
          if (res.status === 200) history.replace("/");
        })
        .catch((err) => alert(err.message));
    }
  }, [submit]);

  return task?.data ? (
    <>
      <Wrapper>
        <Container>
          <StyledLeftStack>
            <StyledVideoPlayer isPhase={true} videoMeta={videoMeta} src={src} refs={{ videoRef, stopFrameRef }} frameRate={frameRate} />
            <StyledPhaseController totalFrame={totalFrame} phaseState={phase} frameRate={frameRate} />
          </StyledLeftStack>
          <StyledRightStack>
            <PhaseListContainer
              totalFrame={totalFrame}
              phaseState={phase}
              onClickListItem={mvFrameTo}
              ref={progressInfoRef}
              frameRate={frameRate}
            />
            <StyledButtonGroup direction="row" align="right">
              <Button label="Exit" onClick={exit} />
              <Button label="Submit" theme="secondary" onClick={checkBeforeSubmit} />
            </StyledButtonGroup>
          </StyledRightStack>
        </Container>
      </Wrapper>
      {modal && (
        <ModalPortal>
          <Modal>
            <Message {...modal} />
          </Modal>
        </ModalPortal>
      )}
    </>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledLeftStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const StyledRightStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 300px;
  margin-left: 32px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: auto;
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-bottom:15px;
`;

const StyledPhaseController = styled(PhaseInputContainer)`
  width: 100%;
  height: 100px;
  border-radius: 10px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
  
`;

export default PhaseAnnotation;
