import React, { useState, useEffect } from "react";
import styled from "styled-components";

const SkillAssessmentBody = ({ skill, onClickRow, resetSelection, reset, annotationType }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  console.log(annotationType);
  const renderPhaseSkillAssessment = () => {
    const phases = Object.keys(skill);

    return phases.map((phase, i) => {
      return (
        <PhaseContainer
          onClick={() => {
            if (i !== selectedIndex) {
              setSelectedIndex(i);
              if (onClickRow) onClickRow({ [`${phase}`]: skill[phase] });
            } else {
              resetSelection();
            }
          }}
          selected={i === selectedIndex}
        >
          {phase}
          <PhaseSA>{skill[phase]["Depth Perception"] > 0 ? skill[phase]["Depth Perception"] : ""}</PhaseSA>
          <PhaseSA>{skill[phase]["Bimanual Dexterity"] > 0 ? skill[phase]["Bimanual Dexterity"] : ""}</PhaseSA>
          <PhaseSA>{skill[phase]["Efficiency"] > 0 ? skill[phase]["Efficiency"] : ""}</PhaseSA>
          {annotationType?.includes("GOALS") ||
            (!annotationType && <PhaseSA>{skill[phase]["Tissue Handling"] > 0 ? skill[phase]["Tissue Handling"] : ""}</PhaseSA>)}
          {annotationType?.includes("GEARS") && (
            <PhaseSA>{skill[phase]["Tissue Handling"] > 0 ? skill[phase]["Tissue Handling"] : ""}</PhaseSA>
          )}
          {annotationType?.includes("GEARS") && (
            <PhaseSA>{skill[phase]["Force Sensitivity"] > 0 ? skill[phase]["Force Sensitivity"] : ""}</PhaseSA>
          )}
          {annotationType?.includes("GEARS") && (
            <PhaseSA>{skill[phase]["Robotic Control"] > 0 ? skill[phase]["Robotic Control"] : ""}</PhaseSA>
          )}
          <PhaseSA>{skill[phase]["Difficulty"] > 0 ? skill[phase]["Difficulty"] : ""}</PhaseSA>
        </PhaseContainer>
      );
    });
  };

  useEffect(() => {
    if (reset) {
      setSelectedIndex(null);
    }
  }, [reset]);

  return <Wrapper>{renderPhaseSkillAssessment()}</Wrapper>;
};

const PhaseSA = styled.div`
  width: 55px;
  padding-left: 20px;

  &:first-child {
    padding-left: 25px;
  }
`;

const PhaseContainer = styled.div`
  font-size: 12px;
  margin: 8px;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 7px;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  background-color: ${(props) => (props.selected ? "#00a999" : "#f3f3f3")};
  color: ${(props) => (props.selected ? "#ffffff" : "#000000")};

  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-top: 30px;

    div {
      &:first-child {
        padding-left: 25px;
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #999da0;
  overflow: scroll;
`;

export default SkillAssessmentBody;
