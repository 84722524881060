import React, { useEffect } from "react";
import styled from "styled-components";

const Modal = ({ children, onClose }) => {
  const onClickWrapper = ({ target: { id } }) => {
    if (id === "modal-wrapper" && onClose) return onClose();
  };

  const onESCAPE = ({ code }) => {
    if (code === "Escape" && onClose) return onClose();
  };

  useEffect(() => {
    window.addEventListener("keydown", onESCAPE);
    window.addEventListener("click", onClickWrapper);

    return () => {
      window.removeEventListener("keydown", onESCAPE);
      window.removeEventListener("click", onClickWrapper);
    };
  }, []);

  return <StyledModal id="modal-wrapper">{children}</StyledModal>;
};

const StyledModal = styled.div`
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export default Modal;
