import { request } from "../libs";

const saveArmes = (armesId, data) => {
  const url = `/annotations/${armesId}/data`;
  return request.put(url, { data });
};

const saveOnlyArmes = (onlyArmesId, data) => {
  const url = `/annotations/${onlyArmesId}/data`;
  return request.put(url, { data });
};

export { saveArmes, saveOnlyArmes };
