import React, { useEffect, useMemo } from "react";
import { Switch, Route, Redirect, useParams, useHistory } from "react-router-dom";

import AdminMember from "../pages/Admin/Member";
import AdminAssign from "../pages/Admin/Assign";
import EditPassword from "../pages/EditPassword.jsx";
import AdminStatus from "../pages/Admin/Status";
import PhaseAnnotation from "../pages/PhaseAnnotation.jsx";
import BleedingAnnotation from "../pages/BleedingAnnotation.jsx";
import ActiveBleeding from "../pages/ActiveBleedingAnnotation";
import ActiveBleedingIntervention from "../pages/ActiveBleedingIntervention";
import CholeSkillAssessment from "../pages/CholeSkillAssessment";
import GastrectomyAssessment from "../pages/GastrectomyAssessment";

import TemporalAnnotation from "../pages/TemporalAnnotation";
import Grading from "../pages/Grading";
import ABFrame from "../pages/ActiceBleedingFrame";
import FrameSelection from "../pages/FrameSelection";
import PresenceAnnotation from "../pages/PresenceAnnotation";
import VideoUpdate from "../pages/Admin/VideoUpdate";
import { useRequest } from "../hooks";

const AnnotationRoute = () => {
  const history = useHistory();
  const { taskId } = useParams();
  const [task, getTask] = useRequest();

  const annotationType = useMemo(() => task?.data?.annotationType, [task]);

  const getComponent = (annotationType) => {
    switch (annotationType) {
      case "PHASE":
        return <PhaseAnnotation task={task} />;
      case "HSDB_SELECTION":
        return <BleedingAnnotation task={task} />;
      case "ORGAN":
      case "NIR_OOB":
        return <TemporalAnnotation task={task} />;
      case "ACTIVE_BLEEDING":
        return <ActiveBleeding task={task} />;
      case "INTERVENTION":
        return <ActiveBleedingIntervention task={task} />;
      case "GRADING":
        return <Grading task={task} />;
      case "AB_FRAME":
        return <ABFrame task={task} />;
      case "CHOLEC_SKILL_ASSESSMENT":
        return <CholeSkillAssessment task={task} />;
      case "GASTRECTOMY_GEARS":
      case "GASTRECTOMY_GOALS":
        return <GastrectomyAssessment task={task} />;
      case "FRAME_SELECTION":
        return <FrameSelection task={task} />;
      case "PRESENCE":
      case "INSTRUMENTS_PRESENCE":
     
    
        return <PresenceAnnotation task={task} />;
      default:
        return <Redirect to="/" />;
    }
  };

  useEffect(() => {
    const request = {
      method: "GET",
      url: `/video-tasks/${taskId}`,
    };
    getTask(request);
  }, []);

  // TODO api로 변경?
  useEffect(() => {
    if (task.type === "failure") return history.replace("/");
  }, []);

  return annotationType ? getComponent(annotationType) : <></>;
};

const AdminRoute = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/members" />} />
      <Route path="/members" component={AdminMember} />
      <Route exact path="/assign" component={AdminAssign} />
      <Route exact path="/status" component={AdminStatus} />
      <Route exact path="/password" component={EditPassword} />
      <Route exact path="/video-update" component={VideoUpdate} />
      <Route path="/video-tasks/:taskId" component={AnnotationRoute} />

      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default AdminRoute;
