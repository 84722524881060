import deepEqual from "deep-equal";
import { getBasename } from "./common";

const greaterThan = (a) => (b) => a < b;
const lessThan = (a) => (b) => a > b;

const contains = (a, b) => (aa, bb) => greaterThan(aa)(a) && lessThan(bb)(b); // aa < a, b < bb

const isValid = ({ start, end }) => -1 < start && start <= end;
const isValidLoc = ({ x, y }) => 0 < x && y > 0;
const toTimestamp = (second) => {
  Number.prototype.round = function (places) {
    return +(Math.round(this + "e+" + places) + "e-" + places);
  };
  const ss = (second % 60).round(2).toString();
  const minutes = Math.floor(second / 60).toLocaleString("en", {
    minimumIntegerDigits: 2,
  });
  const mm = (minutes % 60).toLocaleString("en", { minimumIntegerDigits: 2 });
  const hh = Math.floor(minutes / 60).toLocaleString("en", {
    minimumIntegerDigits: 2,
  });

  return `${hh}:${mm}:${ss}`;
};
// TODO data가 유효하지 않은 경우, 에러 메시지 같은 걸 보내야 하나?

const checkABConflict = (arr, data) => {
  return arr.reduce((acc, curr) => {
    const { start, end, code, x, y } = curr;

    if (
      (start <= data.start &&
        data.start <= end &&
        data.code === code &&
        data.x === x &&
        data.y === y) ||
      (contains(start, end)(data.start, data.end) &&
        code === data.code &&
        data.x === x &&
        data.y === y) ||
      (start <= data.end &&
        data.end <= end &&
        code === data.code &&
        data.x === x &&
        data.y === y)
    ) {
      acc.push(curr);
    }

    return acc;
  }, []);
};

const checkFrameConflict = (arr, data, frameRate) => {
  return arr.reduce((acc, curr) => {
    const { start } = curr;
    if (
      start - frameRate * 0.5 <= data.start &&
      data.start <= start + frameRate * 0.5
    ) {
      acc.push(start);
    }
    return acc;
  }, []);
};

const checkConflict = (arr, data) => {
  return arr.reduce((acc, curr) => {
    const { start, end, code } = curr;

    if (
      (start <= data.start && data.start <= end && data.code === code) ||
      (contains(start, end)(data.start, data.end) && code === data.code) ||
      (start <= data.end && data.end <= end && code === data.code)
    ) {
      acc.push(curr);
    }

    return acc;
  }, []);
};
const checkPresenceConflict = (arr, data) => {
  return arr.reduce((acc, curr, ind) => {

    const { start, end, label } = curr;
    if (data.index === undefined || data.index !== ind) {     // data.label ==> 선택한 애
      console.log(label, data.label);
    } else {
      if (label === data.label) {
        if (
          (start <= data.start && data.start <= end) ||
          contains(start, end)(data.start, data.end) ||
          (start <= data.end && data.end <= end)
        ) {
          acc.push(curr);
        }
      }
    }
    return acc;
  }, []);
};

const checkInterventionConflict = (arr, data) => {
  return arr.reduce((acc, curr) => {
    const { start, end, index, code } = curr;

    if (
      (start <= data.start &&
        data.start <= end &&
        data.index === index &&
        code === data.code) ||
      (contains(start, end)(data.start, data.end) &&
        data.index === index &&
        code === data.code) ||
      (start <= data.end &&
        data.end <= end &&
        data.index === index &&
        code === data.code)
    ) {
      acc.push(curr);
    }

    return acc;
  }, []);
};

const insertPhase = (arr, data) => {
  if (!isValid(data)) return arr;
  if (!arr.length) return [data];

  let isDataAdded = false;
  let memo;

  let acc = [];
  if (data.start <= arr[0].start) {
    acc.push(data);
    isDataAdded = true;
  }

  const result = arr.reduce((acc, curr, i) => {
    const { code, start, end } = curr;

    if (data.start <= start && end <= data.end) {
      if (!isDataAdded) {
        acc.push(data);
        isDataAdded = true;
      }
    } else if (start <= data.start && data.end <= end) {
      if (data.start > 1 && code !== data.code) {
        acc.push({ ...curr, end: data.start - 1 });
      }
      if (!isDataAdded) {
        acc.push(data);
        isDataAdded = true;
      }
      // FIXME
      if (data.code !== code && start < data.end && data.end < end) {
        acc.push({ ...curr, start: data.end + 1 });
      }
    } else if (start <= data.start && data.start <= end) {
      if (start < data.start) {
        acc.push({ ...curr, end: data.start - 1 });
      }

      if (!isDataAdded) {
        acc.push(data);
        isDataAdded = true;

        if (data.end < end) {
          acc.push({ ...curr, start: data.end + 1 });
        }
      }
    } else if (start <= data.end && data.end <= end) {
      if (!isDataAdded) {
        acc.push(data);
        isDataAdded = true;
      }

      if (data.end < end) {
        acc.push({ ...curr, start: data.end + 1 });
      }
    } else if (!contains(start, end)(data.start, data.end)) {
      if (start < data.start && end < data.end) {
        memo = i;
      }
      acc.push(curr);
    }

    return acc;
  }, acc);

  if (!isDataAdded) {
    result.splice(memo + 1, 0, data);
  }
  return result;
};

const mergePhase = (arr) =>
  arr.reduce((acc, curr) => {
    const last = acc.length - 1;

    if (acc[last]?.code === curr.code && acc[last].end + 1 === curr.start) {
      acc[last].end = curr.end;
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);

const genOnlyArmesFromArmes = (arr) =>
  arr.map((curr) =>
    30 <= curr.code && curr.code <= 35 ? { ...curr, code: 0 } : curr
  );

const editCode = (arr, data) => {
  if (!isValid(data)) return arr;

  return arr.map((curr) => {
    const { start, end } = curr;

    if (start === data.start && end === data.end)
      return { ...curr, code: data.code };

    return curr;
  });
};

const insertActiveBleeding = (arr, data) => {
  if (!isValid(data)) return arr;
  if (!arr.length) return [data];

  let isDataAdded = false;

  let acc = [];
  if (data.start <= arr[0].start) {
    acc.push(data);
    isDataAdded = true;
  }

  const result = arr.reduce((acc, curr, i) => {
    const { start, end, code, x, y } = curr;
    if (
      (data.start <= start &&
        end <= data.end &&
        data.code === code &&
        data.x === x &&
        data.y === y) ||
      (start <= data.start &&
        data.end <= end &&
        data.code === code &&
        data.x === x &&
        data.y === y) ||
      (start <= data.start &&
        data.start <= end &&
        data.code === code &&
        data.x === x &&
        data.y === y) ||
      (start <= data.end &&
        data.end <= end &&
        data.code === code &&
        data.x === x &&
        data.y === y)
    ) {
      if (!isDataAdded) {
        acc.push(data);
        isDataAdded = true;
      }
    } else {
      if ((start > data.start) & !isDataAdded) {
        acc.push(data);
        isDataAdded = true;
      }
      acc.push(curr);
    }

    return acc;
  }, acc);
  if (!isDataAdded) {
    result.push(data);
  }
  return result;
};

const insertIntervention = (arr, data) => {
  if (!isValid(data)) return arr;
  if (!arr.length) return [data];

  let isDataAdded = false;

  let acc = [];

  if (data.start <= arr[0].start) {
    acc.push(data);
    isDataAdded = true;
  }

  const result = arr.reduce((acc, curr) => {
    const { start, end, index, code } = curr;

    if (
      (data.start <= start &&
        end <= data.end &&
        data.index === index &&
        code === data.code) ||
      (start <= data.start &&
        data.end <= end &&
        data.index === index &&
        code === data.code) ||
      (start <= data.start &&
        data.start <= end &&
        data.index === index &&
        code === data.code) ||
      (start <= data.end &&
        data.end <= end &&
        data.index === index &&
        code === data.code)
    ) {
      if (!isDataAdded) {
        acc.push(data);
        isDataAdded = true;
      }
    } else {
      if (start > data.start && !isDataAdded) {
        acc.push(data);
        isDataAdded = true;
      }
      acc.push(curr);
    }
    return acc;
  }, acc);

  if (!isDataAdded) {
    result.push(data);
  }
  return result;
};

const insertFrame = (arr, data) => {
  if (!arr.length) return [data];

  let isDataAdded = false;
  let memo;
  let acc = [];
  if (data.start <= arr[0].start) {
    acc.push(data);
    isDataAdded = true;
  }
  const result = arr.reduce((acc, curr, i) => {
    const { start } = curr;
    if (data.start <= start) {
      if (!isDataAdded) {
        acc.push(data);
        isDataAdded = true;
      }
    }
    acc.push(curr);
    return acc;
  }, acc);
  if (!isDataAdded) {
    result.push(data);
  }
  return result;
};
const editPresence = (arr, data) => {
  if (!isValid(data)) return arr;

  if (data.index === undefined) return arr;

  let newData = { label: data.label, start: data.start, end: data.end };
  const conflict = arr.reduce(
    (acc, curr, ind) => {
      if (data.index !== ind) {
        const { start, end, label } = curr;
        if (label === data.label) {
          if (
            (start <= data.start && data.start <= end) ||
            contains(start, end)(data.start, data.end) ||
            (start <= data.end && data.end <= end)
          ) {
            acc.push(ind);
            if (newData.start > start) {
              newData.start = start;
            }
            if (newData.end < end) {
              newData.end = end;
            }
          }
        }
      }
      return acc;
    },
    [data.index]
  );
  let isDataAdded;
  const result = arr.reduce((acc, curr, ind) => {
    const { start } = curr;
    if (!conflict.includes(ind)) {
      if (newData.start < start && !isDataAdded) {
        acc.push(newData);
        isDataAdded = true;
      }
      acc.push(curr);
    }
    return acc;
  }, []);
  if (!isDataAdded) {
    result.push(newData);
  }
  return result;
};
const insertPresence = (arr, data) => {
  if (!isValid(data)) return arr;

  if (!arr.length) return [data];

  let newData = { label: data.label, start: data.start, end: data.end };
  const conflict = arr.reduce((acc, curr, ind) => {
    const { start, end, label } = curr;

    if (label === data.label) {
      if (
        (start <= data.start && data.start <= end) ||
        contains(start, end)(data.start, data.end) ||
        (start <= data.end && data.end <= end)
      ) {
        acc.push(ind);
        if (newData.start > start) {
          newData.start = start;
        }
        if (newData.end < end) {
          newData.end = end;
        }
      }
    }
    return acc;
  }, []);
  let isDataAdded;
  const result = arr.reduce((acc, curr, ind) => {
    const { start } = curr;
    if (!conflict.includes(ind)) {
      if (newData.start < start && !isDataAdded) {
        acc.push(newData);
        isDataAdded = true;
      }
      acc.push(curr);
    }
    return acc;
  }, []);
  if (!isDataAdded) {
    result.push(newData);
  }
  return result;
};
const insertBleeding = (arr, data) => {
  if (!isValid(data)) return arr;
  if (!arr.length) return [data];

  let isDataAdded = false;
  let memo;

  let acc = [];
  if (data.start <= arr[0].start) {
    acc.push(data);
    isDataAdded = true;
  }

  const result = arr.reduce((acc, curr, i) => {
    const { start, end, code } = curr;
    if (
      (data.start <= start && end <= data.end && data.code === code) ||
      (start <= data.start && data.end <= end && data.code === code) ||
      (start <= data.start && data.start <= end && data.code === code) ||
      (start <= data.end && data.end <= end && data.code === code)
    ) {
      if (!isDataAdded) {
        acc.push(data);
        isDataAdded = true;
      }
    } else {
      if (start < data.start && end < data.end) {
        memo = i;
      }
      acc.push(curr);
    }

    if (i === arr.length - 1 && !isDataAdded) {
      acc.splice(memo + 1, 0, data);
    }
    return acc;
  }, acc);

  return result;
};

const mergeBleeding = (arr) =>
  arr.reduce((acc, curr) => {
    const last = acc.length - 1;

    if (acc[last]?.end + 1 === curr.start && acc[last]?.code === curr.code) {
      acc[last].end = curr.end;
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);

const updateGrading = (arr, data, index) => {
  if (!arr.length) return [data];

  arr.splice(index, 1, data);

  return arr;
};

const remove = (arr, data) => arr.filter((curr) => !deepEqual(curr, data));

const getCompletionRate = (totalFrame, arr) => {
  let prev;
  const remains = arr.reduce((remains, curr) => {
    const { start, end } = curr;
    let base = remains - (end - start);
    if (base === remains || (prev && prev.end + 1 === start)) {
      base -= 1;
    }
    prev = curr;
    return base;
  }, totalFrame);
  const done = totalFrame - remains;
  return (done / totalFrame) * 100;
};

/**
 * Return current frame according to current hhmmssff
 */
const toFrame = (hhmmssff, frameRate) => {
  const [hh, mm, ss, ff] = hhmmssff.split(":").map((el) => parseInt(+el));

  let totalFrame = 0;
  // totalFrame 반복적으로 추가 가능 (한줄로 가능)
  totalFrame += ff;
  totalFrame += ss * frameRate;
  totalFrame += mm * frameRate * 60;
  totalFrame += hh * frameRate * 60 * 60;
  totalFrame = Math.round(totalFrame);
  return totalFrame;
};
/**
 * return hhmmssff according to frame
 * @param {} frame
 */
const toHHMMSSFF = (frame, frameRate) => {
  const ff = Math.round(frame % frameRate).toLocaleString("en", {
    minimumIntegerDigits: 2,
  });
  const seconds = Math.floor(frame / frameRate);
  const ss = (seconds % 60).toLocaleString("en", { minimumIntegerDigits: 2 });
  const minutes = Math.floor(seconds / 60).toLocaleString("en", {
    minimumIntegerDigits: 2,
  });
  const mm = (minutes % 60).toLocaleString("en", { minimumIntegerDigits: 2 });
  const hh = Math.floor(minutes / 60).toLocaleString("en", {
    minimumIntegerDigits: 2,
  });

  return `${hh}:${mm}:${ss}:${ff}`;
};

/**
 *
 * Return the completed HHMMSSFF from incompleted HHMMSSFF
 *
 *
 * Exmaples
 *  input     :33
 *  output     00:00:00:33
 *
 *  input     66:30
 *  output    00:00:66:30
 * @param {ed} incompletedHHMMSSFF
 */
const completeHHMMSSFF = (incompletedHHMMSSFF) => {
  if (incompletedHHMMSSFF) {
    const count = incompletedHHMMSSFF.match(/:/g)
      ? incompletedHHMMSSFF.match(/:/g).length
      : 0;
    let hhmmssff;
    if (count) {
      const split = incompletedHHMMSSFF.split(":");
      hhmmssff = "00:".repeat(3 - count);
      for (let i = 0; i < split.length; i++) {
        hhmmssff += +split[i].toString();
        if (i < split.length - 1) {
          hhmmssff += ":";
        }
      }
    } else {
      hhmmssff = `00:00:00:${incompletedHHMMSSFF}`;
    }
    return hhmmssff;
  } else {
    return "00:00:00:00";
  }
};

const checkOnlyArmes = (arr) => arr.filter(({ code }) => code === 0);

const getExportDataToAnnotationData = (annotationType, taskData) => {
  const annotations = [];
  if (annotationType === "ACTIVE_BLEEDING") {
    taskData.annotations[1].data.forEach((annotation) => {
      annotations.push({
        start: Math.round(annotation.start),
        end: Math.round(annotation.end),
        x: annotation.x,
        y: annotation.y,
        code: annotation.code,
      });
    });
  } else if (annotationType === "INTERVENTION") {
    taskData.annotations[0].data.forEach((annotation) => {
      annotations.push({
        ...annotation,
        start: Math.round(annotation.start),
        end: Math.round(annotation.end),
      });
    });
    taskData.annotations[1].data.forEach((annotation) => {
      annotations.push({
        ...annotation,
        start: Math.round(+annotation.start),
        end: Math.round(+annotation.end),
      });
    });
  } else if (annotationType === "AB_FRAME") {
    taskData.annotations[1].data.forEach((annotation) => {
      delete annotation.index;
      annotations.push({
        ...annotation,
        start: Math.round(+annotation.start),
        end: Math.round(+annotation.end),
      });
    });
  } else {
    taskData.annotations.forEach((annotation) => {
      annotation.data.forEach((a) => {
        annotations.push({
          ...a,
          start: Math.round(a.start),
          end: Math.round(a.end),
        });
      });
    });
  }
  return annotations;
};

const getExportDataToCholecPhase = (taskData) => {
  const phaseData = [];
  const skillData = [];

  taskData.annotations[0].data.forEach((annotation) => {
    phaseData.push({
      ...annotation,
      start: Math.round(+annotation.start),
      end: Math.round(+annotation.end),
    });
  });

  taskData.annotations[1].data.forEach((annotation) => {
    skillData.push({
      ...annotation,
    });
  });

  return [phaseData, skillData];
};

const exportDataToJSON = (videoData) => {
  const { name, annotationType, annotator } = videoData;
  const exportData = JSON.stringify(videoData, null, 2);
  const blob = new Blob([exportData], { type: "application/json" });
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = `${name}_${annotationType}_${annotator}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const exportDataToCholec = (videoData) => {
  if (videoData.length === 0) return;
  videoData.forEach((data, i) => {
    const exportData = JSON.stringify(data, null, 2);
    const blob = new Blob([exportData], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download =
      i === 0
        ? `${data.name}_${data.annotationType}_${data.annotator}.json`
        : `${data.name}_${data.annotationType}_${data.annotator}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const exportAnnotationData = async (data, video, frameRate) => {
  const taskId = data?._id;
  const videoName = getBasename(data?.video?.path);
  const indexCode = data?.video?.indexCode;
  const annotationType = data?.annotationType;
  const annotatorName = data?.annotatorId?.name;
  const videoWidth = video.current.videoWidth;
  const videoHeight = video.current.videoHeight;
  const annotations = getExportDataToAnnotationData(annotationType, data);

  const createdAt = data?.createdAt;
  const updatedAt = data?.updatedAt;
  const totalFrame = data?.video?.totalFrame;

  const videoData = {
    _id: taskId,
    indexCode: `${indexCode}`,
    name: `${videoName}`,
    annotationType: annotationType,
    annotator: annotatorName,
    width: videoWidth,
    height: videoHeight,
    frameRate,
    annotations,
    created_at: createdAt,
    updated_at: updatedAt,
    totalFrame: totalFrame,
  };

  exportDataToJSON(videoData);
};

const exportCholecAnnotationData = (data, video, frameRate) => {
  const taskId = data?._id;
  const videoName = getBasename(data?.video?.path);
  const indexCode = data?.video?.indexCode;
  const annotationType = data?.annotationType;
  const annotatorName = data?.annotatorId?.name;
  const videoWidth = video.current.videoWidth;
  const videoHeight = video.current.videoHeight;
  const annotations = getExportDataToCholecPhase(data);
  const createdAt = data?.createdAt;
  const updatedAt = data?.updatedAt;
  const totalFrame = data?.video?.totalFrame;

  const phase = {
    _id: taskId,
    indexCode: `${indexCode}`,
    name: `${videoName}`,
    annotationType: "CHOLEC_PHASE",
    annotator: annotatorName,
    width: videoWidth,
    height: videoHeight,
    frameRate,
    annotations: annotations[0],
    created_at: createdAt,
    updated_at: updatedAt,
    totalFrame: totalFrame,
  };

  const skill_assessment = {
    _id: taskId,
    indexCode: `${indexCode}`,
    name: `${videoName}`,
    annotationType: annotationType,
    annotator: annotatorName,
    width: videoWidth,
    height: videoHeight,
    frameRate,
    annotations: annotations[1],
    created_at: createdAt,
    updated_at: updatedAt,
    totalFrame: totalFrame,
  };

  exportDataToCholec([phase, skill_assessment]);
};

const insertSkillAssessment = (arr, data, code) => {
  const result = [];
  arr.forEach((skill) => {
    result.push({
      ...skill,
      [code]: data,
    });
  });

  return result;
};

export {
  isValid,
  checkConflict,
  insertPhase,
  mergePhase,
  genOnlyArmesFromArmes,
  editCode,
  insertBleeding,
  mergeBleeding,
  updateGrading,
  remove,
  getCompletionRate,
  checkOnlyArmes,
  toHHMMSSFF,
  toFrame,
  completeHHMMSSFF,
  exportAnnotationData,
  exportCholecAnnotationData,
  insertActiveBleeding,
  isValidLoc,
  checkABConflict,
  insertIntervention,
  checkInterventionConflict,
  checkFrameConflict,
  toTimestamp,
  insertFrame,
  insertSkillAssessment,
  checkPresenceConflict,
  insertPresence,
  editPresence,
};
