import {
  checkConflict,
  insertPhase,
  mergePhase,
  remove,
  genOnlyArmesFromArmes,
  editCode,
} from "../../utils";
import { saveArmes, saveOnlyArmes } from "../../api";

// Actions
const SET_ID = "phase/SET_ID";
const SET_SELECTED = "phase/SET_SELECTED";
const SET_ARMES = "phase/SET_ARMES";
const CHECK_ARMES = "phase/CHECK_ARMES";
const SET_ONLY_ARMES = "phase/SET_ONLY_ARMES";
const SWITCH_PHASE = "phase/SWITCH_PHASE";
const RESET_PHASE = "phase/RESET_PHASE";
const CLEAR_WARNING = "phase/CLEAR_WARNING";

const ARMES = "ARMES";
const ONLY_ARMES = "ONLY_ARMES";

const initialState = {
  id: { armes: null, onlyArmes: null },
  selected: null,
  armes: [],
  onlyArmes: [],
  currPhase: ARMES,
  warning: { data: null, list: [] },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ID:
      return { ...state, id: action.payload };
    case SET_SELECTED:
      const selected = action.payload
        ? { ...state.selected, ...action.payload }
        : initialState.warning.selected;
      return { ...state, selected };
    case SET_ARMES:
      return {
        ...state,
        armes: action.payload,
        selected: initialState.selected,
        warning: initialState.warning,
      };
    case CHECK_ARMES:
      return {
        ...state,
        warning: {
          data: action.payload,
          list: checkConflict(state.armes, action.payload),
        },
      };
    case SET_ONLY_ARMES:
      return {
        ...state,
        onlyArmes: action.payload,
        selected: initialState.selected,
        warning: initialState.warning,
      };
    case SWITCH_PHASE:
      return {
        ...state,
        currPhase: state.currPhase === ARMES ? ONLY_ARMES : ARMES,
        selected: initialState.selected,
      };
    case CLEAR_WARNING:
      return { ...state, warning: initialState.warning };
    case RESET_PHASE:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setSelected(payload) {
  return { type: SET_SELECTED, payload };
}

export function setArmes(payload) {
  return { type: SET_ARMES, payload };
}

export function checkArmes(payload) {
  return { type: CHECK_ARMES, payload };
}

export function setOnlyArmes(payload) {
  return { type: SET_ONLY_ARMES, payload };
}

export function switchPhase() {
  return { type: SWITCH_PHASE };
}

export function resetPhase() {
  return { type: RESET_PHASE };
}

export function setId(payload) {
  return { type: SET_ID, payload };
}

export function clearWarning() {
  return { type: CLEAR_WARNING };
}

export const insertArmesThunk = (payload) => (dispatch, getState) => {
  try {
    const state = getState().phase;
    const result = insertPhase(state.armes, payload);
    const armes = mergePhase(result);
    const onlyArmes = genOnlyArmesFromArmes(armes);

    Promise.all([
      saveArmes(state.id.armes, armes),
      saveOnlyArmes(state.id.onlyArmes, onlyArmes),
    ]).then((values) => {
      console.log(values);
      dispatch(setArmes(armes));
      dispatch(setOnlyArmes(onlyArmes));
    });
  } catch (err) {
    console.log(err);
  }
};

export const removeArmesThunk = (payload) => (dispatch, getState) => {
  try {
    const state = getState().phase;
    const armes = remove(state.armes, payload);
    const onlyArmes = genOnlyArmesFromArmes(armes);

    Promise.all([
      saveArmes(state.id.armes, armes),
      saveOnlyArmes(state.id.onlyArmes, onlyArmes),
    ]).then((values) => {
      console.log(values);
      dispatch(setArmes(armes));
      dispatch(setOnlyArmes(onlyArmes)); 
    });
  } catch (err) {
    console.log(err);
  }
};

export const editOnlyArmesThunk = (payload) => (dispatch, getState) => {
  try {
    const state = getState().phase;
    const onlyArmes = mergePhase(editCode(state.onlyArmes, payload));

    Promise.all([saveOnlyArmes(state.id.onlyArmes, onlyArmes)]).then(
      (values) => {
        console.log(values);
        dispatch(setOnlyArmes(onlyArmes));
      }
    );
  } catch (err) {
    console.log(err);
  }
};
