import { checkFrameConflict, insertFrame, importFrame, remove } from "../../utils";
import { saveAnnotation } from "../../api";

// Actions
const SET_ID = "frame/SET_ID";
const SET_SELECTED = "frame/SET_SELECTED";
const SET_FRAME = "frame/SET_FRAME";
const CHECK_FRAME = "frame/CHECK_FRAME";
const RESET_FRAME = "frame/RESET_FRAME";
const CLEAR_WARNING = "frame/CLEAR_WARNING";
const SET_FRAMERATE = "frame/SET_FRAMERATE";
const initialState = {
  id: null,
  selected: null,
  frame: [],
  warning: { data: null, list: [] },
  frameRate: 0,
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ID:
      return { ...state, id: action.payload };
    case SET_SELECTED:
      const selected = action.payload ? { ...state.selected, ...action.payload } : null;
      return { ...state, selected: selected };
    case SET_FRAME:
      return {
        ...state,
        frame: action.payload,
        selected: null,
        warning: initialState.warning,
      };
    case SET_FRAMERATE:
      return {
        ...state,
        frameRate: action.payload,
      };
    case CHECK_FRAME:
      return {
        ...state,
        warning: {
          data: action.payload,
          list: checkFrameConflict(state.frame, action.payload, state.frameRate),
        },
      };

    case CLEAR_WARNING:
      return { ...state, warning: initialState.warning };
    case RESET_FRAME:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setSelected(payload) {
  return { type: SET_SELECTED, payload };
}

export const importGuidedFrame = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().frame;
    let i = 0;
    let j = 0;
    const result = [];
    while (i < state.frame.length || j < payload.length) {
      if (i === state.frame.length) {
        result.push(payload[j]);
        j += 1;
      } else if (j === payload.length) {
        if (state.frame[i].code === 2) {
          result.push(state.frame[i]);
        }
        i += 1;
      } else if (state.frame[i].code !== 2) {
        i += 1;
      } else if (state.frame[i].start === payload[j].start) {
        result.push(state.frame[i]);
        i += 1;
        j += 1;
      } else if (state.frame[i].start < payload[j].start) {
        result.push(state.frame[i]);
        i += 1;
      } else {
        result.push(payload[j]);
        j += 1;
      }
    }
    const { status } = await saveAnnotation(state.id, payload);
    if (status === 200) {
      dispatch(setFrame(payload));
    }
  } catch (err) {
    console.error(err);
  }
};
export function setFrame(payload) {
  return { type: SET_FRAME, payload };
}

export function check(payload) {
  return { type: CHECK_FRAME, payload };
}

export function resetFrame() {
  return { type: RESET_FRAME };
}

export function setId(payload) {
  return { type: SET_ID, payload };
}

export function clearWarning() {
  return { type: CLEAR_WARNING };
}
export function setFrameRate(payload) {
  return { type: SET_FRAMERATE, payload };
}
export const resetImport = (payload) => async (dispatch, getState) => {
  console.log(payload);
  try {
    const state = getState().frame;
    const { status } = await saveAnnotation(state.id, []);
    if (status === 200) {
      dispatch(setFrame([]));
    }
  } catch (err) {
    console.error(err);
  }
};

export const insertThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().frame;
    const frame = insertFrame(state.frame, payload);

    const { status } = await saveAnnotation(state.id, frame);
    if (status === 200) {
      dispatch(setFrame(frame));
    }
  } catch (err) {
    console.log(err);
  }
};

export const removeThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().frame;
    console.log(payload);
    const frame = remove(state.frame, payload);

    const { status } = await saveAnnotation(state.id, frame);
    if (status === 200) {
      dispatch(setFrame(frame));
    }
  } catch (err) {
    console.log(err);
  }
};
