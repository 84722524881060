import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "../components/atoms/NewButton.jsx";
import ButtonGroup from "../components/atoms/ButtonGroup.jsx";
import VideoPlayer from "../components/organisms/VideoPlayer.jsx";
import ModalPortal from "../components/organisms/ModalPortal.jsx";
import Modal from "../components/organisms/Modal.jsx";
import Message from "../components/organisms/Message.jsx";
import GastrectomySAInputContainer from "../components/organisms/GastrectomySkillAssessment/GastrectomySAInputContainer";
import GastrectomySAListContainer from "../components/organisms/GastrectomySkillAssessment/GastrectomySAListContainer";

import { exportCholecAnnotationData } from "../utils";
import { updateStatus } from "../api";
import { setId, setPhase, setSkillAssessment, resetPhase, changeToGastrectomy } from "../redux/ducks/skillAssessment";

const GastrectomyAssessment = ({ task }) => {
  const [modal, setModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const state = useSelector((state) => state.skillAssessment);

  const stopTime = useRef(Number.MAX_VALUE);
  const videoRef = useRef();

  const history = useHistory();
  const dispatch = useDispatch();

  const videoMeta = useMemo(() => {
    if (task?.data?.annotationType) {
      const {
        annotationType,
        video: { indexCode, totalFrame, frameRate, path },
      } = task.data;
      return { indexCode, totalFrame, frameRate, annotationType, path };
    }
  }, [task]);

  const frameRate = useMemo(() => (task?.data?.video?.frameRate ? task?.data?.video?.frameRate : 30), [task]);

  const src = useMemo(() => {
    if (task?.data?.video) {
      const videoId = task.data.video._id;
      return `${window.env.API_URL}/videos/${videoId}`;
    }
  }, [task]);

  const annotationType = useMemo(() => task?.data?.annotationType.replaceAll("_", " "), [task]);

  const mvToHome = () => history.push("/");

  const exportData = () => {
    if (task?.data) {
      exportCholecAnnotationData(task.data, videoRef, frameRate);
    }
  };

  const renderCheckUserBtn = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const role = Object.keys(user.role);

    return role[0] === "admin" ? (
      <Button label="Export" theme="secondary" onClick={exportData} disabled={disabled} />
    ) : (
      <Button label="Submit" theme="secondary" onClick={showModal} />
    );
  };

  const showModal = () => setModal(true);

  const warningModal = {
    type: "warning",
    message: "Annotations cannot be modified after submission. Would you like to submit?",
    actions: [
      {
        label: "Yes",
        theme: "secondary",
        onClick: () => {
          setSubmit(true);
          setModal(false);
        },
        submitOnEnter: true,
      },
      {
        label: "No",
        onClick: () => setModal(false),
        cancelOnEsc: true,
      },
    ],
  };

  const selectList = (data) => {
    videoRef.current.currentTime = data.start / frameRate;
    stopTime.current = data.end / frameRate;
  };

  useEffect(() => {
    const player = videoRef.current;

    player.addEventListener("timeupdate", () => {
      if (player.currentTime >= stopTime.current) {
        player.pause();
        player.currentTime = stopTime.current;
        stopTime.current = Number.MAX_VALUE;
      }
    });

    return () => {
      dispatch(resetPhase());
    };
  }, []);

  useEffect(() => {
    if (task?.data?.annotations) {
      task.data.annotationType.includes("GEARS") ? dispatch(changeToGastrectomy("GEARS")) : dispatch(changeToGastrectomy("GOALS"));
      const [cholecPhase, cholecSkill] = task.data.annotations;
      dispatch(setId({ phase: cholecPhase._id, skillAssessment: cholecSkill._id }));
      dispatch(setPhase(cholecPhase.data));
      if (cholecSkill.data.length > 0) dispatch(setSkillAssessment(cholecSkill.data));
    }
  }, [task]);

  useEffect(() => {
    if (submit) {
      updateStatus(task?.data?._id, "DONE")
        .then((res) => {
          if (res.status === 200) history.replace("/");
        })
        .catch((err) => alert(err.message));
    }
  }, [submit]);

  useEffect(() => {
    if (task?.data?.status === "DONE") {
      setDisabled(false);
    }
  }, [task]);

  return task?.data ? (
    <>
      <Wrapper>
        <Container>
          <StyledLeftStack>
            <StyledVideoPlayer videoMeta={videoMeta} frameRate={frameRate} refs={{ videoRef }} src={src} />
            <GastrectomySAInputContainer annotationType={annotationType} state={state} videoMeta={videoMeta} />
          </StyledLeftStack>
          <StyledRightStack>
            <GastrectomySAListContainer frameRate={frameRate} annotationType={annotationType} state={state} onClickListItem={selectList} />
            <StyledButtonGroup direction="row" align="center">
              <Button label="Exit" onClick={mvToHome} />
              {renderCheckUserBtn()}
            </StyledButtonGroup>
          </StyledRightStack>
        </Container>
      </Wrapper>
      {modal && (
        <ModalPortal>
          <Modal>
            <Message {...warningModal} />
          </Modal>
        </ModalPortal>
      )}
    </>
  ) : (
    <></>
  );
};

const StyledButtonGroup = styled(ButtonGroup)`
  margin: auto;
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  margin-bottom: 15px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
`;

const StyledRightStack = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin-left: 30px;
`;

const StyledLeftStack = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
`;

export default GastrectomyAssessment;
