import React from "react";
import styled from "styled-components";
import ProfileImg from "../molecules/ProfileImg.jsx";
import Button from "../atoms/NewButton.jsx";
import ButtonGroup from "../atoms/ButtonGroup.jsx";

const UserMenu = ({ userInfo, onClickEditPassword, onClickLogout }) => {
  const { image, username, email } = userInfo;

  return (
    <Wrapper>
      <Container>
        <ProfileImg image={image} />
        <StyledUsername>{username}</StyledUsername>
        <StyledEmail>{email}</StyledEmail>
        <ButtonGroup direction="column" gap="16px">
          <StyledButton
            label="Edit Password"
            theme="secondary"
            onClick={onClickEditPassword}
          />
          <StyledButton label="Log Out" onClick={onClickLogout} />
        </ButtonGroup>
      </Container>
    </Wrapper>
  );
};

const AlignedCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled(AlignedCenter)`
  width: fit-content;
  padding: 30px;
`;

const Container = styled(AlignedCenter)`
  width: fit-content;
  flex-direction: column;
`;

const StyledUsername = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-top: 15px;
`;

const StyledEmail = styled.div`
  text-align: center;
  font-size: 12px;
  margin-bottom: 30px;
`;

const StyledButton = styled(Button)`
  font-size: 12px;
`;

export default UserMenu;
