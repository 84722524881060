import React from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import * as S from "./style";

/**
 * * 모달에 들어갈 컨텐츠를 감싸서 사용합니다.
 * * 팝업 애니메이션이 기본으로 설정되어 있습니다. 애니메이션을 사용하지 않으려면 `transition`을 `false`로 지정하세요.
 *
 * @component
 * @example
 * const show = true
 * return <Modal show={show} transition={false}><Contents /></Modal>
 */
export default function Modal(props) {
  const { children, show, transition = true, ...rest } = props;

  const content = (
    <CSSTransition in={show} classNames="modal-transition" unmountOnExit timeout={200}>
      <S.ModalWrapper transition={transition} data-testid="modal-wrapper" {...rest}>
        <S.Modal data-testid="modal">{children}</S.Modal>
      </S.ModalWrapper>
    </CSSTransition>
  );

  const el = document.getElementById("modal");
  return el ? createPortal(content, el) : null;
}
