export const isInRange = (start, end) => (x) => start <= x && x < end;

export const getSeperatedFrames = (frameRate, data) => {
  const sep = 60;
  const sepFrame = sep * frameRate;
  let step = 0;

  const result = data.reduce((acc, curr) => {
    const { start } = curr;

    if (isInRange(sepFrame * step, sepFrame * (step + 1))(start)) {
      acc.push(curr);
    } else {
      if (sepFrame * step < start) {
        const leapedStep = Math.trunc((start - sepFrame * step) / sepFrame);
        step += leapedStep;
        acc.push(null);
      }
      acc.push(curr);
    }
    return acc;
  }, []);
  return result;
};
