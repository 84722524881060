import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";

import refreshIcon from "../../assets/refresh.png";

import Button from "../../components/atoms/NewButton";
import TypeDropdown from "../../components/atoms/TypeDropdown";
import { HeadCheckbox, Checkbox, resetCheckboxes } from "../../components/atoms/CheckboxGroup";

import ScrollTable from "../../utils/scroll-table";
import ModalPortal from "../../components/organisms/ModalPortal";
import Modal from "../../components/organisms/Modal";
import Message from "../../components/organisms/Message";

import { useRequest } from "../../hooks";
import { getFileName } from "../../utils/common";
import { getDate } from "../../utils/date";
import { updateTasks, deleteTasks } from "../../api";
import LoadingModal from "../../components/organisms/LoadingModal";

const tableHeadData = ["Date", "Index", "Video", "Annotation Type", "Name", "Status", ""];
const defaultFilters = {
  index: { value: "Index", label: "Index" },
  annotationType: { value: "Annotation Type", label: "Annotation Type" },
  user: { value: "User", label: "User" },
  status: { value: "Status", label: "Status" },
};
const statusTypes = [
  { value: "NOT_STARTED", label: "Not Started" },
  { value: "IN_PROGRESS", label: "In Progress" },
  { value: "DONE", label: "Done" },
];
const annotationTypes = [
  { value: "PHASE", label: "Phase" },
  { value: "ACTIVE_BLEEDING", label: "Active Bleeding" },
  // { value: "ACTIVE_BLEEDING_INTERVENTION", label: "Active Bleeding Intervention" },
  { value: "BLOOD_STAINED_TISSUE", label: "Blood Stained Tissue" },
  { value: "BLOOD_COLLECTED", label: "Blood Colleted" },
  { value: "AI_EMPOWERED", label: "AI Empowered" },
  { value: "ORGAN", label: "Organ" },
  { value: "INTERVENTION", label: "AB Intervention" },
  { value: "HSDB_SELECTION", label: "HSDB Selection" },
  { value: "FRAME_SELECTION", label: "Frame Selection" },
  { value: "GRADING", label: "Grading" },
  { value: "AB_FRAME", label: "AB Frame" },
  { value: "PRESENCE", label: "Presence" },
  { value: "INSTRUMENTS_PRESENCE", label: "Instruments Presence" },
  { value: "ORGAN_PRESENCE", label: "Organ Presence" },
  { value: "GASTRECTOMY_GOALS", label: "Gastrectomy GOALS" },
  { value: "GASTRECTOMY_GEARS", label: "Gastrectomy GEARS" },
  { value: "CHOLEC_SKILL_ASSESSMENT", label: "Cholec Skill Assessment" },
];

const AdminStatus = () => {
  const [result, send] = useRequest();
  const [indexFilter, setIndexFilter] = useState(defaultFilters.index);
  const [annotationTypeFilter, setAnnotationTypeFilter] = useState(defaultFilters.annotationType);
  const [userFilter, setUserFilter] = useState(defaultFilters.user);
  const [statusFilter, setStatusFilter] = useState(defaultFilters.status);
  const [showLoading, setShowLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [indexTypes, setIndexTypes] = useState([]); // [{ value: video.indexCode, label: video.indexCode }]
  const [userTypes, setUserTypes] = useState([]); // [{ value: user._id, label: user.name }]
  const [checkedSet, setCheckedSet] = useState(new Set());
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [modalMessage, setModalMessage] = useState(null);

  const requestGetVideoTasks = useCallback(() => {
    const request = {
      method: "GET",
      url: `/video-tasks`,
    };
    send(request);
  }, [send]);

  useEffect(() => {
    requestGetVideoTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (result.error) {
      setShowLoading(false);
      console.error(result.error);
    }
    if (result.data) {
      setShowLoading(false);
      const taskMeta = getTaskMeta(result.data);
      setIndexTypes(taskMeta.indexTypes);
      setUserTypes(taskMeta.userTypes);
      setTasks(result.data);
    }
  }, [result]);

  useEffect(() => {
    resetCheckboxes();
    setCheckedSet(new Set());
    if (tasks.length === 0) {
      return setFilteredTasks([]);
    }

    let filtered = [];
    // filter status
    if (statusFilter.value === defaultFilters.status.value) {
      filtered = tasks;
    } else {
      filtered = tasks.filter((task) => task.status === statusFilter.value);
    }
    // filter index code
    if (indexFilter.value !== defaultFilters.index.value) {
      filtered = filtered.filter((task) => task.video.indexCode === indexFilter.value);
    }
    // filter annotation type
    if (annotationTypeFilter.value !== defaultFilters.annotationType.value) {
      filtered = filtered.filter((task) => task.annotationType === annotationTypeFilter.value);
    }
    // filter user
    if (userFilter.value !== defaultFilters.user.value) {
      filtered = filtered.filter((task) => task.annotatorId._id === userFilter.value);
    }
    setFilteredTasks(filtered);
  }, [tasks, annotationTypeFilter, indexFilter, userFilter, statusFilter]);

  const handleDelete = async () => {
    const checkedIds = [...checkedSet];
    const deleteChecked = document.getElementById("checkbox-delete").checked;
    try {
      deleteChecked ? await deleteTasks(checkedIds) : await updateTasks(checkedIds, { isActive: false });
    } catch (error) {
      alert(error.message);
    } finally {
      requestGetVideoTasks();
      setShowModal(false);
      setShowLoading(true);
    }
  };

  const handleStatusUpdate = async () => {
    const checkedIds = [...checkedSet];
    const selectedStatus = document.querySelector('input[name="radio-status"]:checked')?.value;
    if (!selectedStatus) {
      return alert("status를 선택해주세요");
    }
    try {
      await updateTasks(checkedIds, { status: selectedStatus });
    } catch (error) {
      alert(error.message);
    } finally {
      requestGetVideoTasks();
      setShowModal(false);
      setShowLoading(true);
    }
  };

  const deleteModal = {
    type: "warning",
    message: (
      <>
        <div>선택된 task들이 숨김 처리됩니다. task의 삭제를 원할 경우 아래 체크박스를 선택한 후에 Yes 버튼을 클릭하세요.</div>
        <CheckboxWrapper>
          <StyledInput id="checkbox-delete" type="checkbox" />
          <div>데이터를 삭제</div>
        </CheckboxWrapper>
      </>
    ),
    actions: [
      {
        label: "Yes",
        theme: "secondary",
        onClick: handleDelete,
        submitOnEnter: true,
      },
      {
        label: "No",
        onClick: () => setShowModal(false),
        cancelOnEsc: true,
      },
    ],
  };

  const statusModal = {
    type: "warning",
    message: (
      <>
        <div>선택된 task들의 status가 변경됩니다. 변경할 status를 선택 후 update를 눌러주세요.</div>
        <RadioWrapper>
          {statusTypes.map((statusType) => (
            <StyledRadio>
              <StyledInput type="radio" name="radio-status" value={statusType.value} />
              &nbsp; {statusType.label}
            </StyledRadio>
          ))}
        </RadioWrapper>
      </>
    ),
    actions: [
      {
        label: "Update",
        theme: "secondary",
        onClick: handleStatusUpdate,
        submitOnEnter: true,
      },
      {
        label: "Cancel",
        onClick: () => setShowModal(false),
        cancelOnEsc: true,
      },
    ],
  };

  const onClickReset = useCallback(() => {
    resetCheckboxes();
    setCheckedSet(new Set());
    setIndexFilter(defaultFilters.index);
    setAnnotationTypeFilter(defaultFilters.annotationType);
    setUserFilter(defaultFilters.user);
    setStatusFilter(defaultFilters.status);
  }, []);

  const onClickDelete = useCallback(() => {
    setShowModal(true);
    setModalMessage(deleteModal);
  }, [deleteModal]);

  const onClickStatus = useCallback(() => {
    setShowModal(true);
    setModalMessage(statusModal);
  }, [statusModal]);

  const tableHeadRow = useMemo(() => {
    const onChangeHeadCheckbox = (e) => {
      if (e.target.checked) {
        setCheckedSet(new Set(filteredTasks.map((task) => task._id)));
      } else {
        setCheckedSet(new Set());
      }
    };

    return [<HeadCheckbox disabled={filteredTasks.length === 0} onChange={onChangeHeadCheckbox} />, ...tableHeadData];
  }, [filteredTasks]);

  const tableBodyRows = useMemo(() => {
    const onChangeCheckbox = (taskId, isChecked) => {
      setCheckedSet((prev) => {
        if (isChecked) {
          prev.add(taskId);
        } else {
          prev.delete(taskId);
        }
        return new Set(prev);
      });
    };

    return filteredTasks.map((task) => {
      const { _id, createdAt, status, annotationType, annotatorId, video } = task;
      return [
        <Checkbox onChange={(e) => onChangeCheckbox(_id, e.target.checked)} />,
        getDate(createdAt),
        video.indexCode,
        getFileName(video.path),
        annotationType,
        annotatorId.name,
        status,
        <Button onClick={() => moveToVideoPage(_id)} disabled={!video} label="Check" theme="secondary" />,
      ];
    });
  }, [filteredTasks]);

  return (
    <Wrapper>
      <PageHeader>
        <FilterGroup>
          <TypeDropdown
            options={indexTypes}
            onSelect={setIndexFilter}
            label={indexFilter.label}
            defaultLabel={defaultFilters.index.label}
            width="250px"
          />
          <TypeDropdown
            options={annotationTypes}
            onSelect={setAnnotationTypeFilter}
            label={annotationTypeFilter.label}
            defaultLabel={defaultFilters.annotationType.label}
            width="250px"
          />
          <TypeDropdown options={userTypes} onSelect={setUserFilter} label={userFilter.label} defaultLabel={defaultFilters.user.label} />
          <TypeDropdown
            options={statusTypes}
            onSelect={setStatusFilter}
            label={statusFilter.label}
            defaultLabel={defaultFilters.status.label}
          />
          <StyledResetButton onClick={onClickReset} src={refreshIcon} />
          <StyledTotal>{`Total : ${filteredTasks.length}`}</StyledTotal>
        </FilterGroup>
        <StyledButtonGroup>
          {checkedSet.size !== 0 && <StyledSelected>{`Selected : ${checkedSet.size}`}</StyledSelected>}
          {statusFilter.value === "DONE" && (
            <StyledButton
              disabled={checkedSet.size === 0}
              label="Download"
              theme="primary"
              onClick={() => downloadTasks([...checkedSet])}
            />
          )}
          <StyledButton label="Delete" theme="primary" disabled={checkedSet.size === 0} onClick={onClickDelete} />
          <StyledButton label="Status" theme="primary" disabled={checkedSet.size === 0} onClick={onClickStatus} />
        </StyledButtonGroup>
      </PageHeader>
      <StyledScrollTable headRow={tableHeadRow} bodyRows={tableBodyRows} firstWidth="30px" />
      {showModal && (
        <ModalPortal>
          <Modal>
            <Message {...modalMessage} />
          </Modal>
        </ModalPortal>
      )}
      <LoadingModal show={showLoading}></LoadingModal>
    </Wrapper>
  );
};

const getTaskMeta = (tasks) => {
  const indexSet = new Set();
  const userSet = new Set();

  return tasks.reduce(
    (acc, task) => {
      if (!indexSet.has(task.video.indexCode)) {
        indexSet.add(task.video.indexCode);
        acc.indexTypes.push({ value: task.video.indexCode, label: task.video.indexCode });
      }
      if (!userSet.has(task.annotatorId._id)) {
        userSet.add(task.annotatorId._id);
        acc.userTypes.push({ value: task.annotatorId._id, label: task.annotatorId.name });
      }
      return acc;
    },
    { indexTypes: [], userTypes: [] }
  );
};

const downloadTasks = (taskIds) => {
  window.location.href = `${window.env.API_URL}/video-tasks/download?taskIds=${taskIds}`;
};

const moveToVideoPage = (taskId) => {
  window.location.href = `video-tasks/${taskId}`;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 45px - 30px - 30px);
  padding: 20px 60px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTotal = styled.p`
  margin: 10px 0px 0px 20px;
  font-size: large;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const StyledSelected = styled.p`
  margin: 0 20px 0 0;
  font-size: large;
`;

const StyledResetButton = styled.img`
  width: 24px;
  height: 24px;
  margin-top: 10px;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  width: 100px;
  font-weight: bold;
  margin-left: 10px;
`;

const StyledScrollTable = styled(ScrollTable)`
  flex: 1;
  width: 100%;
  margin-top: 20px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  div {
    margin-left: 6px;
  }
`;

const RadioWrapper = styled.div`
  width: 150px;
  margin: 20px 0 0 50px;
`;

const StyledRadio = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 10px;
`;

const StyledInput = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export default AdminStatus;
