import React from "react";
import styled from "styled-components";

const NotiMsg = ({ msg, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <StyledMsg>{msg}</StyledMsg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 300px;
  max-width: 300px;
  height: fit-content;
  padding: 10px 10px;
`;

const StyledMsg = styled.div`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
`;

export default NotiMsg;
