import React from "react";
import styled from "styled-components";

const Table = ({ children, ...rest }) => {
  return <StyledTable {...rest}>{children}</StyledTable>;
};

const StyledTable = styled.table`
  display: flex;
  flex-direction: column;
  table-layout: fixed;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-spacing: 0px;
  border-radius: 10px;
  overflow-y: hidden;
`;

export default React.memo(Table);
