import React from "react";
import styled from "styled-components";

const Table = ({ children, className, ...rest }) => {
  return <StyledTable className={className} {...rest}>{children}</StyledTable>;
};

const StyledTable = styled.table`
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-spacing: 0px;
  border-radius: 10px;
  overflow: hidden;
`;

export default React.memo(Table);
