import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Selector from "./Selector";
import Input from "../atoms/NewInput";
import { GRADING } from "./constants";

const GradingRemedy = ({ title, index, onRemedyMethod, data, annotationType, onChangeEffective, onKeyDown }) => {
  const [activeOther, setActiveOther] = useState(false);
  useEffect(() => {
    let isOther = false;
    console.log(data);
    if (data && data.method) {
      isOther = true;

      for (const [key, value] of Object.entries(GRADING.Method)) {
        if (value === data.method) {
          isOther = false;
        }
      }
      console.log("CURRENT", activeOther);
      console.log("ISOTHER", isOther);
    }
    if (activeOther !== isOther) {
      setActiveOther(isOther);
    }
  }, [data]);
  const handleShowOtherInput = (e) => {
    onRemedyMethod(" ", index);

    setActiveOther(!activeOther);
  };

  const handleClickMethod = (e) => {
    if (activeOther) {
      setActiveOther(!activeOther);
    }
    onRemedyMethod(e, index);
  };
  return (
    <>
      <StyledInputWrapper>
        <StyledTitle>Method</StyledTitle>
        <div style={{ width: "fit-content", display: "flex" }}>
          <Selector
            contents={GRADING["Method"]}
            annotationType={annotationType}
            active={data?.method ? data.method : false}
            clickable
            onClick={(e) => handleClickMethod(e)}
          />
          <StyledOtherBtn active={activeOther} onClick={handleShowOtherInput}>
            O
          </StyledOtherBtn>
        </div>
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledTitle>Effectiveness</StyledTitle>
        <StyledRadioBtn
          id="effective"
          data-id={title}
          data-nested={title}
          type="radio"
          value="1"
          name="effectiveness"
          checked={data?.isEffective === 1}
          onChange={(e) => onChangeEffective(e, index)}
        />
        <StyledRadioLabel htmlFor="effective">{`Effective`}</StyledRadioLabel>
        <StyledRadioBtn
          id="ineffective"
          data-id={title}
          data-nested={title}
          type="radio"
          value="0"
          name="effectiveness"
          checked={data?.isEffective === 0}
          onChange={(e) => onChangeEffective(e, index)}
        />
        <StyledRadioLabel htmlFor="ineffective" style={{ marginLeft: "20px" }}>
          {`Ineffective`}
        </StyledRadioLabel>
        {activeOther && (
          <div style={{ display: "flex", marginLeft: "20px", fontSize: "14px", height: "28px" }}>
            <StyledTitle>Other</StyledTitle>
            <StyledInput
              id="grading-input"
              data-id={title}
              data-nested={title}
              value={data?.method ? data.method : ""}
              onChange={(e) => onRemedyMethod(e, index)}
              onKeyDown={onKeyDown}
            />
          </div>
        )}
      </StyledInputWrapper>
    </>
  );
};

const StyledInputWrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  display: flex;
`;

const StyledTitle = styled.div`
  width: 145px;
  border: 2px solid black;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
`;

const StyledInput = styled(Input)`
  width: 200px;
  border-radius: 5px;
`;

const StyledOtherBtn = styled.button`
  min-width: 25px;
  min-height: 25px;
  margin-left: 15px;
  background-color: ${({ active }) => (active ? "#00A999" : "#30363b")};
  color: ${({ disabled }) => (disabled ? "#889198" : "#ffffff")};
  font-size: 10px;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  outline: none;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    background-color: #889198;
  }
`;

const StyledRadioLabel = styled.label`
  width: 130px;
  text-align: center;
  font-size: 16px;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid black;
  cursor: pointer;
`;

const StyledRadioBtn = styled.input`
  display: none;

  &:checked + label {
    background: #00a999;
    color: #ffffff;
  }
`;

export default GradingRemedy;
