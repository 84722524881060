import { checkInterventionConflict, insertIntervention, remove } from "../../utils";
import { saveAnnotation } from "../../api";

// Actions
const SET_ID = "intervention/SET_ID";
const SET_SELECTED = "intervention/SET_SELECTED";
const SET_ACTIVE_BLEEDING = "intervention/SET_ACTIVE_BLEEDING";
const SET_INTERVENTION = "intervention/SET_INTERVENTION";
const CHECK_INTERVENTION = "intervention/CHECK_INTERVENTION";
const RESET_INTERVENTION = "intervention/RESET_INTERVENTION";
const CLEAR_WARNING = "intervention/CLEAR_WARNING";

const initialState = {
  id: { activeBleeding: null, intervention: null },
  selected: null,
  activeBleeding: [],
  intervention: [],
  warning: { data: null, list: [] },
};

// Reducer

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ID:
      return { ...state, id: action.payload };
    case SET_SELECTED:
      const selected = action.payload ? { ...state.selected, ...action.payload } : null;
      return { ...state, selected: selected };
    case SET_ACTIVE_BLEEDING:
      return {
        ...state,
        activeBleeding: action.payload,
        selected: null,
        warning: initialState.warning,
      };
    case SET_INTERVENTION:
      return {
        ...state,
        intervention: action.payload,
        selected: { index: state.selected?.index, x: state.selected?.x, y: state.selected?.y },
        warning: initialState.warning,
      };
    case CHECK_INTERVENTION:
      return {
        ...state,
        warning: {
          data: action.payload,
          list: checkInterventionConflict(state.intervention, action.payload),
        },
      };
    case CLEAR_WARNING:
      return {
        ...state,
        warning: initialState.warning,
      };
    case RESET_INTERVENTION:
      return initialState;
    default:
      return state;
  }
}

// Action creator

export function setId(payload) {
  return { type: SET_ID, payload };
}

export function setSelected(payload) {
  return { type: SET_SELECTED, payload };
}

export function setActiveBleeding(payload) {
  return { type: SET_ACTIVE_BLEEDING, payload };
}

export function setIntervention(payload) {
  return { type: SET_INTERVENTION, payload };
}

export function check(payload) {
  return { type: CHECK_INTERVENTION, payload };
}

export function resetIntervention() {
  return { type: RESET_INTERVENTION };
}

export function clearWarning() {
  return { type: CLEAR_WARNING };
}

export const insertThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().intervention;
    const interventionData = {
      start: payload.start,
      end: payload.end,
      index: payload.index,
      code: payload.code,
      x: payload.x,
      y: payload.y,
    };

    console.log(payload.index)
    
    if (payload.type) {
      interventionData.type = payload.type;
    }
    const intervention = insertIntervention(state.intervention, interventionData);
    const { status } = await saveAnnotation(state.id.intervention, intervention);
    if (status === 200) {
      dispatch(setIntervention(intervention));
    }
  } catch (err) {
    console.error(err);
  }
};

export const importActiveBleeding = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().intervention;
    const { status } = await saveAnnotation(state.id.activeBleeding, payload);
    if (status === 200) {
      dispatch(setActiveBleeding(payload));
    }
  } catch (err) {
    console.error(err);
  }
};
export const importIntervention = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().intervention;
    const { status } = await saveAnnotation(state.id.intervention, payload);
    if (status === 200) {
      dispatch(setIntervention(payload));
    }
  } catch (err) {
    console.error(err);
  }
};
export const removeIntervention = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().intervention;
    const interventionData = {
      start: payload.start,
      end: payload.end,
      index: payload.index,
      code: payload.code,
    };
    if (payload.type) {
      interventionData.type = payload.type;
    }
    if (payload.x) {
      interventionData.x = payload.x;
    }
    if (payload.y) {
      interventionData.y = payload.y;
    }
    const intervention = remove(state.intervention, interventionData);

    const { status } = await saveAnnotation(state.id.intervention, intervention);
    if (status === 200) {
      dispatch(setIntervention(intervention));
    }
  } catch (err) {
    console.error(err);
  }
};
