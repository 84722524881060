const getFileName = (path) => {
  if (path) {
    let fileName = path.split("/").pop();
    let idx = fileName.lastIndexOf(".");
    
    // return path.split("/").pop();
    return fileName.substring(0, idx);
  }
};

// const getBasename = (path) => getFileName(path).split(".").shift();
const getBasename = (path) => getFileName(path);

export { getFileName, getBasename };
