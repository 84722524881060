import React, { useState } from "react";
import styled from "styled-components";

import Input from "../atoms/Input";
import Button from "../atoms/Button";
import { ReactComponent as CloseButton } from "../../assets/icnClose.svg";

// TODO 모두 입력된 상태, 렌더를 반환하는 useInputList 작성 후 리팩토링
const AddUserModal = ({ actions: { onCancel, onSubmitAdd }, closeModal }) => {
  const [user, setUser] = useState({ name: "", email: "", role: {annotator: true}});

  const caption =
    "Once you click ‘Add’, confirmation link will be sent to the email address you filled in.";

  const setUserName = ({ target: { value } }) => {
    setUser({ ...user, name: value });
  };

  const setUserEmail = ({ target: { value } }) =>
    setUser({ ...user, email: value });

  return (
    <Wrapper>
      <StyledCloseButton onClick={closeModal} />
      <StyledTitle>Add Member</StyledTitle>
      <StyledInputContainer>
        <Input
          label="Name"
          value={user.name}
          onChange={setUserName}
          style={{ width: "400px" }}
        />
        <Input
          label="Email"
          caption={caption}
          value={user.email}
          onChange={setUserEmail}
          style={{ width: "400px" }}
        />
      </StyledInputContainer>
      <StyledButtonGroup>
        <Button label="Cancel" onClick={onCancel} />
        <Button
          label="Add"
          onClick={() => onSubmitAdd(user)}
          disabled={!user.name || !user.email}
        />
      </StyledButtonGroup>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  padding: 60px 180px;
  position: relative;
  background-color: white;
  border-radius: 10px;
`;

const StyledTitle = styled.h1`
  font-size: 48px;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 45px;
  right: 45px;
  cursor: pointer;
`;

const StyledInputContainer = styled.div`
  margin-bottom: 60px;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default AddUserModal;
