import React from "react";
import styled from "styled-components";

import Input from "../atoms/NewInput";

const GradingSite = ({ data, onChange, onChangeSite, onKeyDown }) => {
  return (
    <>
      <StyledInputWrapper>
        <StyledTitle>description</StyledTitle>
        <StyledInput
          id="grading-input"
          data-id="site"
          data-nested="site"
          value={data?.description ? data.description : ""}
          name="description"
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledTitle>Vessel_bleeding</StyledTitle>
        <StyledRadioBtn
          id="site-yes"
          data-id="site"
          data-nested="site"
          type="radio"
          value="1"
          name="isVesselBleeding"
          checked={data?.isVesselBleeding === 1 ? true : false}
          onChange={onChangeSite}
        />
        <StyledRadioLabel htmlFor="site-yes" data-text="Yes">
          Y
        </StyledRadioLabel>
        <StyledRadioBtn
          id="site-no"
          data-id="site"
          data-nested="site"
          type="radio"
          value="0"
          name="isVesselBleeding"
          checked={data?.isVesselBleeding === 0 ? true : false}
          onChange={onChangeSite}
        />
        <StyledRadioLabel htmlFor="site-no" style={{ marginLeft: "20px" }} data-text="No">
          N
        </StyledRadioLabel>
      </StyledInputWrapper>
    </>
  );
};

const StyledInputWrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  display: flex;
`;

const StyledTitle = styled.div`
  width: 145px;
  border: 2px solid black;
  border-radius: 5px;
  text-align: center;
  margin-right: 30px;
`;

const StyledInput = styled(Input)`
  width: 500px;
  border-radius: 5px;
`;

const StyledRadioLabel = styled.label`
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  font-size: 16px;
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  background-color: #30363b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  position: relative;

  &:before {
    content: "";
    display: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #1a1a1a;
    position: absolute;
    bottom: 30px;
    left: 13px;
    z-index: 8;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }

  &:after {
    display: none;
    content: attr(data-text);
    position: absolute;
    bottom: 35px;
    left: 0px;
    padding: 5px 8px;
    background: #1a1a1a;
    color: #fff;
    z-index: 9;
    font-size: 0.75em;
    height: 18px;
    line-height: 18px;
    border-radius: 3px;
    white-space: nowrap;
    word-wrap: normal;
  }

  &:hover:before,
  &:hover:after {
    display: block;
  }
`;

const StyledRadioBtn = styled.input`
  display: none;

  &:checked + label {
    background: #00a999;
    color: #ffffff;
  }
`;

export default GradingSite;
