import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "../components/atoms/NewButton.jsx";
import ButtonGroup from "../components/atoms/ButtonGroup.jsx";
import VideoPlayer from "../components/organisms/VideoPlayer.jsx";
import AbInterventionList from "../components/organisms/ListContainer/AbInterventionList";
import AbInterventionInputContainer from "../components/organisms/AbInterventionInputContainer";
import ModalPortal from "../components/organisms/ModalPortal.jsx";
import Modal from "../components/organisms/Modal.jsx";
import Message from "../components/organisms/Message.jsx";

import { updateStatus } from "../api";
import { getBasename, exportAnnotationData } from "../utils";
import {
  importActiveBleeding,
  setIntervention,
  setActiveBleeding,
  resetIntervention,
  setId,
  importIntervention,
} from "../redux/ducks/intervention.js";

const ActiveBleedingIntervention = ({ task }) => {
  const history = useHistory();
  const videoRef = useRef();
  const [modal, setModal] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [pointOn, setPointOn] = useState(true);
  const stopTime = useRef(Number.MAX_VALUE);

  const dispatch = useDispatch();
  const intervention = useSelector((state) => state.intervention);
  const selected = intervention.selected;
  let playerPosition;

  const videoMeta = useMemo(() => {
    if (task?.data?.annotationType) {
      const {
        annotationType,
        video: { indexCode, path, totalFrame, frameRate },
      } = task.data;
      const basename = getBasename(path);
      const title = `${indexCode}-${basename}-${annotationType}`;
      return { indexCode, totalFrame, title, frameRate, annotationType, path };
    }
  }, [task]);

  const src = useMemo(() => {
    if (task?.data?.video) {
      const videoId = task.data.video._id;
      return `${window.env.API_URL}/videos/${videoId}`;
    }
  }, [task]);

  const totalFrame = useMemo(() => task?.data?.video?.totalFrame, [task]);

  const frameRate = useMemo(
    () => (task?.data?.video?.frameRate ? task?.data?.video?.frameRate : 30),
    [task]
  );

  const annotationType = useMemo(
    () => task?.data?.annotationType.replaceAll("_", " "),
    [task]
  );

  const warningModal = {
    type: "warning",
    message:
      "Annotations cannot be modified after submission. Would you like to submit?",
    actions: [
      {
        label: "Yes",
        theme: "secondary",
        onClick: () => {
          setSubmit(true);
          setModal(false);
        },
        submitOnEnter: true,
      },
      {
        label: "No",
        onClick: () => setModal(false),
        cancelOnEsc: true,
      },
    ],
  };

  const selectList = (data) => {
    setPointOn(true);

    videoRef.current.currentTime = data.start / frameRate;
    stopTime.current = data.end / frameRate;
  };

  const mvToHome = () => history.push("/");

  const showModal = () => setModal(true);

  const exportData = () => {
    if (task?.data) {
      exportAnnotationData(task.data, videoRef, frameRate);
    }
  };

  const renderCheckUserBtn = () => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const role = Object.keys(user.role);

    return role[0] === "admin" ? (
      <Button label="Export" theme="secondary" onClick={exportData} />
    ) : (
      <Button label="Submit" theme="secondary" onClick={showModal} />
    );
  };

  const getHeightStart = () => {
    //get according height size
    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;
    const convertRatio = 900 / videoWidth;
    const visibleHeight = videoHeight * convertRatio;
    const heightStart = (720 - visibleHeight) / 2;
    return heightStart;
  };

  // player 한테 보내줄때
  const convertVideoPositionToPlayerSize = (playerPoint) => {
    let x = playerPoint.x;
    let y = playerPoint.y;

    x = (x / videoRef.current.videoWidth) * 900;
    y = (y / videoRef.current.videoHeight) * 720 + getHeightStart();

    return { x, y };
  };

  // Selected Position true => yes
  if (selected?.x) {
    playerPosition = convertVideoPositionToPlayerSize({
      x: selected.x,
      y: selected.y,
    });
  }

  const getBleedingPosition = (e) => {};

  const loadJSONFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      let json;
      reader.onload = (e) => {
        json = JSON.parse(e.target.result);
        const abList = [];
        const interventionList = [];
        json.annotations.forEach((el) => {
          if (el.index || el.index === 0) {
            if (!el.type) {
              el.type = "--";
            }
            console.error(el);
            interventionList.push(el);
          } else {
            abList.push(el);
          }
        });

        dispatch(importActiveBleeding(abList));
        dispatch(importIntervention(interventionList));
      };
      reader.readAsText(e.target.files[0]);
    } else {
      alert("Failed import JSON file");
    }
  };

  const turnOnOffPoint = () => {
    setPointOn(!pointOn);
  };

  const keyDownEventHandler = useCallback(
    (e) => {
      if (e.code === "KeyP") {
        setPointOn(!pointOn);
      }
    },
    [pointOn]
  );

  useEffect(() => {
    window.addEventListener("keydown", keyDownEventHandler);

    return () => {
      window.removeEventListener("keydown", keyDownEventHandler);
    };
  }, [keyDownEventHandler]);

  useEffect(() => {
    const player = videoRef.current;

    player.addEventListener("timeupdate", () => {
      if (player.currentTime >= stopTime.current) {
        player.pause();
        player.currentTime = stopTime.current;
        stopTime.current = Number.MAX_VALUE;
      }
    });
    return () => {
      dispatch(resetIntervention());
    };
  }, []);

  useEffect(() => {
    if (task?.data?.annotations) {
      const [activeBleeding, intervention] = task.data.annotations;

      dispatch(
        setId({
          activeBleeding: activeBleeding._id,
          intervention: intervention._id,
        })
      );
      dispatch(setIntervention(intervention.data));
      dispatch(setActiveBleeding(activeBleeding.data));
    }
  }, [task]);

  useEffect(() => {
    if (submit) {
      updateStatus(task?.data?._id, "DONE")
        .then((res) => {
          if (res.status === 200) history.replace("/");
        })
        .catch((err) => alert(err.message));
    }
  }, [submit]);

  useEffect(() => {
    if (task?.data?.status === "DONE") {
      setDisabled(false);
    }
  }, [task]);

  return task?.data ? (
    <>
      <Wrapper>
        <Container>
          <StyledLeftStack>
            <StyledVideoPlayer
              videoMeta={videoMeta}
              src={src}
              refs={{ videoRef }}
              isPointOn={pointOn}
              bleedingPoint={intervention?.selected?.x && playerPosition}
              onClick={getBleedingPosition}
              frameRate={frameRate}
              isActiveBleeding={true}
            />
            <StyledAbInterventionController
              totalFrame={totalFrame}
              bleedingState={intervention}
              annotationType={annotationType}
              bleedingPoint={null}
              frameRate={frameRate}
              onChange={loadJSONFile}
              point={pointOn}
              onClickOnOffPoint={turnOnOffPoint}
            />
          </StyledLeftStack>
          <StyledRightStack>
            <AbInterventionList
              totalFrame={totalFrame}
              bleedingState={intervention}
              annotationType={annotationType}
              onClickListItem={selectList}
              frameRate={frameRate}
            />
            <StyledButtonGroup direction="row" align="center">
              <Button label="Exit" onClick={mvToHome} />
              {renderCheckUserBtn()}
            </StyledButtonGroup>
          </StyledRightStack>
        </Container>
      </Wrapper>
      {modal && (
        <ModalPortal>
          <Modal>
            <Message {...warningModal} />
          </Modal>
        </ModalPortal>
      )}
    </>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 auto;
`;

const StyledLeftStack = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRightStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 300px;
  margin-left: 30px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin: auto;
  margin-left: 50px;
`;

const StyledVideoPlayer = styled(VideoPlayer)`
  margin-bottom: 15px;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
`;

const StyledAbInterventionController = styled(AbInterventionInputContainer)`
  width: 100%;
  height: 100px;
  border-radius: 10px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
`;

export default ActiveBleedingIntervention;
