/**
 * @description 메일 주소 형식은 [RFC 5322](https://www.ietf.org/rfc/rfc5322.txt)를 따릅니다. 비밀번호는 **영대소문자, 숫자, 특수문자 포함 8자 이상**으로 구성되어 있습니다.
 */
const EXPRESSIONS = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()-+]).{8,}$/,
  hhmmssff: /^(([0-9]{0,}):?([0-9]{0,}):?([0-9]{0,}):?([0-9]{0,}))?$/,
};

export const validate = (value, type) => {
  const validator = EXPRESSIONS[type];
  if (!validator) return true;

  return validator.test(value);
};

export const checkAtLeastLength = (value, length) =>
  value && value.trim().length >= length;

export const checkIsfilled = (value) => value && value.length > 0;
