import React from "react";
import styled from "styled-components";

const TableBody = ({ children, ...rest }) => {  
  return (
    <StyledTableBody onScroll={onScroll} {...rest}>{children}</StyledTableBody>
  )
};

const onScroll = (e) => {
  if (e.target.scrollTop === 0) {
    e.target.style.borderTop = "3px solid #cccccc";
    e.target.style.boxShadow = "unset";
    return
  }
  e.target.style.borderTop =  "unset";
  e.target.style.boxShadow = "inset 0em 1.2em 1em -1em #cccccc"
}

const StyledTableBody = styled.tbody`
  flex: 1;
  display: block;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-top: 3px solid #dddddd;
  overflow-y: overlay;
`

export default React.memo(TableBody);
