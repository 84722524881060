import React from "react";
import { toHHMMSSFF } from "../../../utils/annotation";

import "./style.scss";

const GradingListItem = ({ index, row, onClickRow, disableRemove, isSelected, frameRate, style }) => {
  const { start, end, site, cause, characteristics, identical, comment, remedy } = row;
  const getClassName = () => {
    if (isSelected) return "grading-item selected";
    return "grading-item";
  };

  const getRemedyCount = () => {
    if (remedy) {
      return `${remedy.length}회`;
    } else {
      return "0회";
    }
  };

  const renderCharacteristic = () => {
    if (characteristics?.isBrisk) {
      return characteristics?.isPulsatile ? "_P" : "_No-P";
    } else {
      return characteristics?.isMicroBleeding && "_M";
    }
  };

  const headerData = [
    { label: "Timestamp", colspan: 2 },
    { label: "Site", colspan: 3 },
    { label: "Cause", colspan: 1 },
    { label: "Characteristics", colspan: 4 },
    { label: "Identical" },
    { label: "Comment" },
    { label: "Remedy1", colspan: 2 },
    { label: "Remedy2", colspan: 2 },
    { label: "Remedy3", colspan: 2 },
    { label: "Remedy4", colspan: 2 },
    { label: "Remedy5", colspan: 2 },
  ];

  const subHeaderData = [
    { label: "start" },
    { label: "end" },
    { label: "description", colspan: 2 },
    { label: "vessel" },
    { label: "cause" },
    { label: "brisk" },
    { label: "pulsatile" },
    { label: "oozing" },
    { label: "micro" },
    { label: "" },
    { label: "" },
    { label: "method" },
    { label: "effectiveness" },
    { label: "method" },
    { label: "effectiveness" },
    { label: "method" },
    { label: "effectiveness" },
    { label: "method" },
    { label: "effectiveness" },
    { label: "method" },
    { label: "effectiveness" },
  ];

  const renderGradingClassHeader = () => {
    return headerData.map((data) => {
      const { label, colspan } = data;
      return <th colspan={colspan ? colspan : null}>{label}</th>;
    });
  };

  const renderGradingClassSubHeader = () => {
    return subHeaderData.map((data) => {
      const { label, colspan } = data;
      return <td colspan={colspan ? colspan : null}>{label}</td>;
    });
  };
  const renderRemedy =
    remedy &&
    remedy.map((r) => (
      <>
        <td>{r.method}</td>
        <td>{r.isEffective ? "O" : "X"}</td>
      </>
    ));

  const renderGradingClassData = () => {
    return (
      <tr>
        <td>{toHHMMSSFF(start, frameRate)}</td>
        <td>{toHHMMSSFF(end, frameRate)}</td>
        <td colspan="2">{site?.description}</td>
        <td>{site?.isVesselBleeding && site.isVesselBleeding === 1 ? "O" : site?.isVesselBleeding === 0 ? "X" : ""}</td>
        <td>{cause}</td>
        <td>{characteristics?.isBrisk === 1 ? "O" : characteristics?.isBrisk === 0 ? "X" : ""}</td>
        <td>{characteristics?.isPulsatile === 1 ? "O" : characteristics?.isPulsatile === 0 ? "X" : ""}</td>
        <td>{characteristics?.isOozing === 1 ? "O" : characteristics?.isOozing === 0 ? "X" : ""}</td>
        <td>{characteristics?.isMicroBleeding === 1 ? "O" : characteristics?.isMicroBleeding === 0 ? "X" : ""}</td>
        <td>{identical}</td>
        <td>{comment}</td>
        {renderRemedy}
      </tr>
    );
  };

  const renderGradingAnnotationClass = () => {
    return row ? (
      <table class="grading-class">
        <thead>
          <tr>{renderGradingClassHeader()}</tr>
        </thead>
        <tbody>
          <tr>{renderGradingClassSubHeader()}</tr>
          {renderGradingClassData()}
        </tbody>
      </table>
    ) : (
      <></>
    );
  };

  return (
    <div className={getClassName()} onClick={onClickRow} style={style}>
      <div className={disableRemove ? "item-frame" : "item-frame enabled"}>
        <div className="index">{index}</div>
        {toHHMMSSFF(start, frameRate)} - {toHHMMSSFF(end, frameRate)}
        <div className="grading-vessel-bleeding grading" style={{ marginLeft: "15px" }}>
          {site?.isVesselBleeding ? "Y" : site?.isVesselBleeding === 0 ? "N" : ""}
        </div>
        <div className="grading-cause grading">{cause}</div>
        <div className="grading-char grading">
          {characteristics?.isBrisk ? "B" : characteristics?.isOozing ? "O" : ""}
          {renderCharacteristic()}
        </div>
        <div className="grading-remedy grading">{getRemedyCount()}</div>
      </div>
      {renderGradingAnnotationClass()}
    </div>
  );
};

export default GradingListItem;
