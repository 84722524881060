import React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";

import { Provider } from "react-redux";
import store from "./redux/store";
import { setTempUser, verifyUserAsyncThunk } from "./redux/actions.js";

import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

const loadUser = () => {
  const user = localStorage.getItem("user");
  if (!user) return;
  store.dispatch(setTempUser());
  store.dispatch(verifyUserAsyncThunk());
};

loadUser();

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Noto Sans KR", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:focus {
      outline: none;
    }
  }

  body {
    margin: 0;
    background: #f4f4f4;
  }
  
  #root {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <React.StrictMode>
      <Routes />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
