const normalizeString = (str) => {
  if(str) {
    str.replaceAll("_", " ").toLowerCase();
  }
}

const capitalizeFirstLetter = (str) => {
  if(str) {
    str.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
  }
}

export { normalizeString, capitalizeFirstLetter };
