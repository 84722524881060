import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

/** 각 Page에서 사용하는 `Button` component 입니다. */

const NewButton = ({ label, ...rest }) => {
  return <StyledButton {...rest}>{label}</StyledButton>;
};

NewButton.defaultProps = {
  size: "regular",
  label: "",
  theme: "primary",
  shape: "round",
  disabled: false,
};

NewButton.propTypes = {
  /** `size`는 Button의 크기를 지정합니다. */
  size: PropTypes.oneOf(["small", "regular", "large"]),
  /** `label`은 Button에 들어갈 content를 보여줍니다. */
  label: PropTypes.string,
  /** `theme`는 Button의 색상을 지정합니다. */
  theme: PropTypes.oneOf(["primary", "secondary"]),
  /** `shape`는 버튼의 모서리 모양을 지정합니다. */
  shape: PropTypes.oneOf(["round", "square"]),
  /** `disabled`는 옵션에 따라 버튼을 활성화,비활성화 시킵니다. */
  disabled: PropTypes.bool,
  /** button을 click할 때 일어나는 이벤트 입니다. */
  onClick: PropTypes.func.isRequired,
};

const small = css`
  width: 100px;
  height: 28px;
`;

const regular = css`
  width: 140px;
  height: 32px;
`;

const large = css`
  width: 160px;
  height: 36px;
`;

const primary = css`
  background-color: #30363b;
`;

const secondary = css`
  background-color: #00a999;
`;

const square = css`
  border-radius: 5px;
`;

const round = css`
  border-radius: 70px;
`;

const sizeStyles = {
  small,
  regular,
  large,
};

const themeStyles = {
  primary,
  secondary,
};

const borderStyles = {
  square,
  round,
};

// 기본 스타일.
const StyledButton = styled.button`
  border: none;
  font-size: 14px;
  font-weight: 500;
  outline: none;

  color: ${({ disabled }) => (disabled ? "#c2c4c7" : "#ffffff")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  ${({ theme }) => themeStyles[theme]};
  ${({ size }) => sizeStyles[size]};
  ${({ shape }) => borderStyles[shape]};
`;

export default NewButton;
