import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useRequest } from "../../hooks";
import Button from "../../components/atoms/Button";
import AssignBody from "../../components/organisms/AssignBody";
import { ASSIGN_TYPE } from "../../components/organisms/constants";
import LoadingModal from "../../components/organisms/LoadingModal";

const Assign = (props) => {
  const [currentPage, setCurrentPage] = useState(ASSIGN_TYPE.VIDEO);
  const [videoDataReady, setVideoDataReady] = useState(false);
  const [userDataReady, setUserDataReady] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [videoResult, videoSend] = useRequest();
  const [userResult, userSend] = useRequest();
  const [taskPostResult, taskPostSend] = useRequest();
  const userData = useRef([]);
  const videoData = useRef([]);
  const taskTypeData = useRef([
    { label: "Phase", checked: false, value: "PHASE" },
   
    { label: "Active Bleeding", checked: false, value: "ACTIVE_BLEEDING" },
    { label: "NIR/OOB", checked: false, value: "NIR_OOB" },
    { label: "AB Intervention", checked: false, value: "INTERVENTION" },
    { label: "AB Frame", checked: false, value: "AB_FRAME" },
    {
      label: "Gastrectomy GOALS(라파)",
      checked: false,
      value: "GASTRECTOMY_GOALS",
    },
    {
      label: "Gastrectomy GEARS(로봇)",
      checked: false,
      value: "GASTRECTOMY_GEARS",
    },
    { label: "Frame Selection", checked: false, value: "FRAME_SELECTION" },
    { label: "Instruments Presence", checked: false, value: "INSTRUMENTS_PRESENCE" },

    { label: "Presence", checked: false, value: "PRESENCE" },
    {
      label: "Cholec Skill Assessment",
      checked: false,
      value: "CHOLEC_SKILL_ASSESSMENT",
    },
  ]);

  const nextBtnClick = () => {
    let isVideoChecked = false;
    for (let vid of videoData.current) {
      if (vid.checked) {
        isVideoChecked = true;
        break;
      }
    }
    if (isVideoChecked) setCurrentPage(ASSIGN_TYPE.TASK);
    else alert("비디오 선택해주세요 ");
  };
  /**
   * 완료 버튼 눌렀을시, 유저 및 태스크 타입
   */
  const doneBtnClick = () => {
    let isUserChecked = false;
    let isTaskTypeChecked = false;
    for (let user of userData.current) {
      if (user.checked) {
        isUserChecked = true;
        break;
      }
    }
    for (let taskType of taskTypeData.current) {
      if (taskType.checked) {
        isTaskTypeChecked = true;
        break;
      }
    }

    if (isTaskTypeChecked && isUserChecked) {
      const tasks = [];
      let currentUserId = JSON.parse(localStorage.getItem("user")).id;
      for (let user of userData.current) {
        for (let video of videoData.current) {
          for (let taskType of taskTypeData.current) {
            if (user.checked && video.checked && taskType.checked) {
              tasks.push({
                managerId: currentUserId,
                video: video._id,
                annotatorId: user._id,
                annotationType: taskType.value,
                taskType: "ANNOTATION",
              });
            }
          }
        }
      }
      const data = { tasks };
      const taskPostRequest = {
        method: "POST",
        url: `/video-tasks`,
        data,
        withCredentials: true,
      };

      setShowLoading(true);
      taskPostSend(taskPostRequest);
    } else if (!isTaskTypeChecked) {
      alert("Task 를 Assign 해주세요");
    } else {
      alert("User 를 선택해주세요.");
    }
  };
  /**
   * Task Post Result 성공할시 메시지 와 함께 맴버스 페이지로 이동
   */
  useEffect(() => {
    if (taskPostResult.type === "success") {
      setShowLoading(false);
      alert("success");
      props.history.push("/members");
    }
  }, [taskPostResult]);

  /**
   * 유저데이터 셋 후 유저데이터 준비 완료 상태변경
   */
  useEffect(() => {
    if (userResult.data) {
      for (let user of userResult.data) {
        userData.current.push({ ...user, checked: false, label: user.name });
      }
      setUserDataReady(true);
    }
  }, [userResult]);

  /**
   * 유저데이터 셋 후 비디오 데이터  준비 완료 상태변경
   */
  useEffect(() => {
    if (videoResult.data) {
      for (let video of videoResult.data) {
        videoData.current.push({ ...video, checked: false });
      }
      setVideoDataReady(true);
    }
  }, [videoResult]);

  /**
   * 페이지 처음 들어왔을때, Assign을 위한 유저 정보, 비디오정보를 받기위해
   * 2가지 리퀘스트 전달
   */
  useEffect(() => {
    const data = {};
    const userRequest = {
      method: "GET",
      url: `/users`,
      data,
      withCredentials: true,
    };

    userSend(userRequest);
    const videoRequest = {
      method: "GET",
      url: `/videos`,
      data,
      withCredentials: true,
    };
    videoSend(videoRequest);
  }, []);

  return (
    <>
      {userDataReady && videoDataReady && (
        <Wrapper>
          <StyledPageHeader>
            <StyledH2>Assign</StyledH2>
            {currentPage === ASSIGN_TYPE.VIDEO ? (
              <ButtonWrapper label="Next" onClick={nextBtnClick} />
            ) : (
              <ButtonWrapper label="Done" onClick={doneBtnClick} />
            )}
          </StyledPageHeader>
          {currentPage === ASSIGN_TYPE.VIDEO ? (
            <AssignBody videoData={videoData.current} currentPage={currentPage} />
          ) : (
            <AssignBody taskTypeData={taskTypeData.current} currentPage={currentPage} userData={userData.current} />
          )}
          <LoadingModal show={showLoading}></LoadingModal>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 45px - 30px - 30px);
  display: flex;
  flex-direction: column;
  margin: 30px 60px;
`;

const StyledPageHeader = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledH2 = styled.h2`
  font-size: 3rem;
  font-weight: 900;
  margin: 15px;
`;

const ButtonWrapper = styled(Button)`
  font-weight: bold;
`;

export default Assign;
