import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Input from "../atoms/NewInput.jsx";
import Button from "../atoms/NewButton.jsx";
import ButtonGroup from "../atoms/ButtonGroup.jsx";
import ModalPortal from "./ModalPortal.jsx";
import Modal from "./Modal.jsx";
import Message from "./Message.jsx";

import { setSelected, check, insertThunk, clearWarning } from "../../redux/ducks/activebleeding.js";
import { validate } from "../../utils/validate.js";
import { toFrame, toHHMMSSFF, completeHHMMSSFF, isValidLoc } from "../../utils/annotation.js";

const ActiveBleedingInputContainer = ({ className, totalFrame, bleedingState, frameRate, onChange, point, onClickOnOffPoint }) => {
  const dispatch = useDispatch();
  const { selected, warning } = bleedingState;
  const bleedingPoint = { x: selected?.x, y: selected?.y };
  const [frameInputValues, setFrameInputValues] = useState({
    start: "00:00:00:00",
    end: "00:00:00:00",
  });
  const [reset, setReset] = useState(0);

  const updateInput = useCallback(
    ({ target: { name, value } }) => {
      if (validate(value, "hhmmssff")) {
        const newValues = {
          ...frameInputValues,
          [name]: value,
        };
        // const startHHMMSSFF = completeHHMMSSFF(newValues.start);
        // const endHHMMSSFF = completeHHMMSSFF(newValues.end);

        // const dataValue = {
        //   ...frameInputValues,
        //   start: toFrame(startHHMMSSFF, frameRate),
        //   end: toFrame(endHHMMSSFF, frameRate),
        // };
        setFrameInputValues(newValues);
      }
    },
    [frameInputValues]
  );

  /**
   * Update validated Inputs as hh:mm:ss:ff and End hh:mm:ss:ff
   */
  const updateValidatedInputs = () => {
    const startHHMMSSFF = completeHHMMSSFF(frameInputValues.start);
    const endHHMMSSFF = completeHHMMSSFF(frameInputValues.end);
    const startFrame = toFrame(startHHMMSSFF, frameRate);
    const endFrame = toFrame(endHHMMSSFF, frameRate);
    dispatch(setSelected({ start: startFrame, end: endFrame }));

    setFrameInputValues({
      ...frameInputValues,
      start: toHHMMSSFF(startFrame < totalFrame ? startFrame : totalFrame, frameRate),
      end: toHHMMSSFF(endFrame < totalFrame ? endFrame : totalFrame, frameRate),
    });
  };

  const inputOnKeyDown = (e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      updateValidatedInputs();
    }
  };

  const insertBleeding = () => dispatch(insertThunk(warning.data));
  const closeWarning = () => dispatch(clearWarning());

  const inputProps = [
    {
      placeholder: "Start",
      name: "start",
      value: frameInputValues?.start !== undefined ? frameInputValues?.start : "",
      onChange: updateInput,
      onKeyDown: inputOnKeyDown,
      onBlur: updateValidatedInputs,
      onFocus: (e) => e.target.setSelectionRange(0, e.target.value.length),
      autoComplete: "off",
    },
    {
      placeholder: "End",
      name: "end",
      value: frameInputValues?.end !== undefined ? frameInputValues?.end : "",
      onChange: updateInput,
      onKeyDown: inputOnKeyDown,
      onBlur: updateValidatedInputs,
      onFocus: (e) => e.target.setSelectionRange(0, e.target.value.length),
      autoComplete: "off",
    },
  ];

  const renderInputs = () => inputProps.map((item, i) => <StyledInput key={"" + reset + i} {...item} />);

  const resetInput = () => {
    dispatch(setSelected(null));
  };

  const makeString = (obj) => {
    const { code, start, end } = obj;
    return `(${code}) ${start} - ${end}`;
  };

  const getMessage = () => {
    const msg = warning.list.map((curr) => makeString(curr)).join(", ");

    return `The section you filled in, ${makeString(warning.data)} is overlapped with ${msg}. Do you want to change it?`;
  };

  const warningModal = useMemo(() => {
    if (!warning.data) return;

    return {
      type: "warning",
      message: getMessage(),
      actions: [
        {
          label: "Yes",
          theme: "secondary",
          onClick: insertBleeding,
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: closeWarning,
          cancelOnEsc: true,
        },
      ],
    };
  }, [warning]);

  const dispatchBleeding = useCallback(() => {
    const data = {
      ...frameInputValues,
      start: toFrame(frameInputValues.start, frameRate),
      end: toFrame(frameInputValues.end, frameRate),
      code: 1,
      x: selected?.x,
      y: selected?.y,
      background: "",
    };

    if (isValidLoc(data)) {
      dispatch(check(data));
    }
  }, [dispatch, selected, frameInputValues, frameRate]);

  useEffect(() => {
    if (selected) {
      selected.start = !selected.start
        ? toFrame(frameInputValues.start, frameRate)
        : selected.start < totalFrame
        ? selected.start
        : totalFrame;
      selected.end = !selected.end ? toFrame(frameInputValues.end, frameRate) : selected.end < totalFrame ? selected.end : totalFrame;

      setFrameInputValues({
        ...selected,
        start: toHHMMSSFF(selected.start, frameRate),
        end: toHHMMSSFF(selected.end, frameRate),
      });
    } else {
      setFrameInputValues({ start: "00:00:00:00", end: "00:00:00:00" });
    }

    setReset(reset + 1);
  }, [selected]);

  useEffect(() => {
    const { data, list } = warning;
    if (data && !list?.length) dispatch(insertThunk(warning.data));

    setFrameInputValues({ start: "00:00:00:00", end: "00:00:00:00" });
    setReset(reset + 1);
  }, [warning]);

  useEffect(() => {
    const saveOnEnter = (e) => {
      if (e.key === "Enter") {
        dispatchBleeding();
      }
    };

    window.addEventListener("keydown", saveOnEnter);
    return () => {
      window.removeEventListener("keydown", saveOnEnter);
    };
  }, [dispatchBleeding]);

  return (
    <>
      <Wrapper className={className}>
        <StyledContent>
          <StyledInputWrapper>
            {renderInputs()}
            <StyledLabel htmlFor="import_json">Import JSON</StyledLabel>
            <StyledImportInput type="file" id="import_json" name="import_json" accept=".json" onChange={onChange} />
            <StyledOnOffBtn label="Point On/Off" size="small" theme={point ? "secondary" : "primary"} onClick={onClickOnOffPoint} />
          </StyledInputWrapper>
          <StyledButtonWrapper>
            {selected && <Button label="Cancel" size="small" onClick={resetInput} />}
            <Button label={selected?.code ? "Save" : "Add"} theme="secondary" size="small" onClick={dispatchBleeding} />
          </StyledButtonWrapper>
        </StyledContent>
        <StyledDiv>
          x: {bleedingPoint && bleedingPoint.x ? bleedingPoint.x : 0}, y:
          {bleedingPoint && bleedingPoint.y ? bleedingPoint.y : 0}
        </StyledDiv>
      </Wrapper>
      {warning?.list?.length > 0 && (
        <ModalPortal>
          <Modal>
            <Message {...warningModal} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  height: 120px;
  max-height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  align-content: space-around;
  padding: 0px 40px;
  box-sizing: border-box;
`;

const StyledInput = styled(Input)`
  font-size: 10px;

  :not(:first-of-type) {
    margin-left: 32px;
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButtonWrapper = styled(ButtonGroup)`
  margin: auto 0 auto auto;
`;

const StyledDiv = styled.div`
  margin-left: 50px;
  margin-bottom: 15px;
`;

const StyledImportInput = styled.input`
  display: none;

  &:hover {
    cursor: point;
  }
`;

const StyledLabel = styled.label`
  background-color: #00a999;
  padding: 5px 5px;
  border-radius: 70px;
  width: 100px;
  height: auto;
  font-size: 0.8rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;
`;

const StyledOnOffBtn = styled(Button)`
  margin-left: 10px;
`;

export default ActiveBleedingInputContainer;
