import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import AnnotationListTab from "../../molecules/AnnotationListTab";
import AnnotationListBody from "../../molecules/AnnotationListBody";
import { setSelected, removeThunk } from "../../../redux/ducks/presence.js";
import ModalPortal from "../ModalPortal.jsx";
import Modal from "../Modal.jsx";
import Message from "../Message.jsx";

const PresenceListContainer = ({ annotationType, onClickListItem, presenceState, frameRate }) => {
  const dispatch = useDispatch();
  const { selected, presence, frame } = presenceState;
  const [msg, setMsg] = useState();
  const [reset, setReset] = useState(0);
  const onClickRow = (row) => {
    dispatch(setSelected({ ...row }));
    onClickListItem(row);
  };

  const onClickDelete = (row) => {
    const deleteWarningMsg = {
      type: "warning",
      message: "Are you sure you want to delete this annotation?",
      actions: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(removeThunk(row));
            setMsg(null);
          },
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: () => setMsg(null),
          type: "gray",
          cancelOnEsc: true,
        },
      ],
    };

    setMsg(deleteWarningMsg);
  };

  useEffect(() => {
    if (!selected) setReset(reset + 1);
  }, [selected]);
  return (
    <>
      <div className="annotation-list-container">
        <div className="container-header">
          <AnnotationListTab tabs={[annotationType]} nRows={presence ? presence.length : frame.length} active={annotationType} />
        </div>
        <div className="container-body">
          <AnnotationListBody
            taskType="presence"
            rows={presence ? presence : frame}
            onClickRow={onClickRow}
            onClickDelete={onClickDelete}
            resetSelection={() => dispatch(setSelected(null))}
            reset={reset}
            frameRate={frameRate}
            selected={selected}
          />
        </div>
      </div>
      {msg && (
        <ModalPortal>
          <Modal>
            <Message {...msg} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

export default PresenceListContainer;
