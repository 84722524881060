import React from "react";
import Table from "./Table";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableRow from "./TableRow";
import TableCell from "./TableCell";

const ScrollTable = ({ headRow, bodyRows, firstWidth, ...rest }) => {
  return (
    <Table {...rest}>
      <TableHead>
        {renderTableHead(headRow, firstWidth)}
      </TableHead>
      {bodyRows.length !== 0 &&
        <TableBody>
          {renderTableBody(bodyRows, firstWidth)}
        </TableBody>}
    </Table>
  )
};

const renderTableHead = (HeadRowData, firstWidth) => (
  <TableRow>
    {HeadRowData.map((cellData, index) => {
      return (
        index === 0
          ? <TableCell width={firstWidth} key={index}>{cellData}</TableCell>
          : <TableCell key={index}>{cellData}</TableCell>
      )
    })}
  </TableRow>
)

const renderTableBody = (BodyRows, firstWidth) => (
  BodyRows.map((bodyRow, index) => (
    <TableRow key={index}>
      {bodyRow.map((cellData, index) => {
        return (
          index === 0
            ? <TableCell width={firstWidth} key={index}>{cellData}</TableCell>
            : <TableCell key={index}>{cellData}</TableCell>
        )
      })}
    </TableRow>
  ))
)

export default React.memo(ScrollTable);
