import { combineReducers } from "redux";
import user from "./user.js";
import phase from "../ducks/phase.js";
import bleeding from "../ducks/bleeding.js";
import activebleeding from "../ducks/activebleeding";
import intervention from "../ducks/intervention";
import grading from "../ducks/grading";
import presence from "../ducks/presence";
import frame from "../ducks/frame";
import skillAssessment from "../ducks/skillAssessment";

export default combineReducers({
  user,
  phase,
  bleeding,
  activebleeding,
  intervention,
  grading,
  frame,
  skillAssessment,
  presence,
});
