import React from "react";
import styled from "styled-components";

import Button from "../atoms/Button";
import { ReactComponent as CloseButton } from "../../assets/icnClose.svg";

const DeleteUserModal = ({ actions: { onYes, onNo }, closeModal }) => {
  return (
    <Wrapper>
      <StyledCloseButton onClick={closeModal} />
      <StyledTitle>Warning!</StyledTitle>
      <StyledInputContainer>
        Are you sure you want to delete this member from the list?
      </StyledInputContainer>
      <StyledButtonGroup>
        <Button label="Yes" onClick={onYes} />
        <Button label="No" onClick={onNo} />
      </StyledButtonGroup>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: fit-content;
  padding: 60px 120px;
  position: relative;
  background-color: white;
  border-radius: 10px;
`;

const StyledTitle = styled.h1`
  font-size: 48px;
  text-align: center;
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 45px;
  right: 45px;
  cursor: pointer;
`;

const StyledInputContainer = styled.div`
  margin-bottom: 60px;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default DeleteUserModal;
