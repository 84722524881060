import React from "react";
import styled from "styled-components";
import SelectorItem from "../atoms/SelectorItem.jsx";

/**
 * @param {Object} contents - ARMES의 code와 phase명을 담고 있음
 * @param {number} disabledIndex - 해당 index부터 마지막 index까지 비활성화
 * @param {function} onClick - onChangeSelector를 받아옴, (onChangeSelector - selector 클릭 시 selectedCode를 해당 code로 변경하는 함수)
 */
const Selector = ({
  contents,
  disabledIndex,
  onClick,
  active,
  clickable,
  annotationType,
}) => {
  const renderSelectorItem = () =>
    Object.entries(contents).map((content, i) => {
      const disabled = disabledIndex && i >= disabledIndex;
      const [code, value] = content;

      return (
        <SelectorItem
          key={i}
          content={content}
          disabled={disabled}
          onClick={
            annotationType === "GRADING"
              ? (e) => {
                  if (e.target.value) {
                    if (clickable) onClick(e);
                  } else {
                    onClick(value);
                  }
                }
              : () => {
                  if (clickable) onClick(+code);
                }
          }
          active={
            annotationType === "GRADING" ? active === value : active === +code
          }
        />
      );
    });

  return <Wrapper>{renderSelectorItem()}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`;

export default Selector;
