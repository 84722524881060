import { checkConflict, insertBleeding, mergeBleeding, remove } from "../../utils";
import { saveAnnotation } from "../../api";

// Actions
const SET_ID = "bleeding/SET_ID";
const SET_SELECTED = "bleeding/SET_SELECTED";
const SET_BLEEDING = "bleeding/SET_BLEEDING";
const CHECK_BLEEDING = "bleeding/CHECK_BLEEDING";
const RESET_BLEEDING = "bleeding/RESET_BLEEDING";
const CLEAR_WARNING = "bleeding/CLEAR_WARNING";

const initialState = {
  id: null,
  selected: null,
  bleeding: [],
  warning: { data: null, list: [] },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ID:
      return { ...state, id: action.payload };
    case SET_SELECTED:
      const selected = action.payload ? { ...state.selected, ...action.payload } : null;
      return { ...state, selected: selected };
    case SET_BLEEDING:
      return {
        ...state,
        bleeding: action.payload,
        selected: null,
        warning: initialState.warning,
      };
    case CHECK_BLEEDING:
      console.log(state.bleeding);
      console.log("list: ", checkConflict(state.bleeding, action.payload));

      return {
        ...state,
        warning: {
          data: action.payload,
          list: checkConflict(state.bleeding, action.payload),
        },
      };
    case CLEAR_WARNING:
      return { ...state, warning: initialState.warning };
    case RESET_BLEEDING:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setSelected(payload) {
  return { type: SET_SELECTED, payload };
}

export const importBleeding = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().bleeding;
    const { status } = await saveAnnotation(state.id, payload);
    if (status === 200) {
      dispatch(setBleeding(payload));
    }
  } catch (err) {
    console.error(err);
  }
};
export function setBleeding(payload) {
  return { type: SET_BLEEDING, payload };
}

export function check(payload) {
  return { type: CHECK_BLEEDING, payload };
}

export function resetBleeding() {
  return { type: RESET_BLEEDING };
}

export function setId(payload) {
  return { type: SET_ID, payload };
}

export function clearWarning() {
  return { type: CLEAR_WARNING };
}

export const insertThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().bleeding;
    const bleeding = insertBleeding(state.bleeding, payload);

    const { status } = await saveAnnotation(state.id, bleeding);
    if (status === 200) {
      dispatch(setBleeding(bleeding));
    }
  } catch (err) {
    console.log(err);
  }
};

export const removeThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().bleeding;
    const bleeding = mergeBleeding(remove(state.bleeding, payload));

    const { status } = await saveAnnotation(state.id, bleeding);
    if (status === 200) {
      dispatch(setBleeding(bleeding));
    }
  } catch (err) {
    console.log(err);
  }
};
