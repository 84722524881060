import React from "react";
import "./style.scss";

/**
 * @param {number} nRows 어노테이션 배열 사이즈
 * @param {object} onClickTab 어노테이션 분류 선택
 */
const CholecTab = ({ tabs }) => {
  const renderTab = () =>
    tabs.map((tab, i) => {
      return (
        <div key={i} className="cholec-list-tab">
          {tab}
        </div>
      );
    });

  return <div className="annotation-list-tab">{renderTab()}</div>;
};

export default CholecTab;
