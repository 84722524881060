import React from "react";
import styled from "styled-components";

/**
 * 수정사항
 * 1. input에 속한 caption 추가
 */
const Input = ({ label, caption, ...rest }) => {
  return (
    <Wrapper>
      <StyledLabel htmlFor={label}>{label}</StyledLabel>
      <StyledInput id={label} {...rest} />
      <StyledCaption style={rest.style}>{caption}</StyledCaption>
    </Wrapper>
  );
};

Input.defaultProps = {
  type: "text",
  isValid: true,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ margin_bottom }) =>
    margin_bottom ? margin_bottom : "30px"};
`;

const StyledLabel = styled.label`
  font-size: 1.2em;
  font-weight: 600;
`;

const StyledInput = styled.input`
  border: none;
  border-bottom: 2px solid
    ${({ value, isValid }) =>
      !value || (value && isValid) ? "black" : "crimson"};
  background: none;
  padding: 15px;
  width: ${({ width }) => (width ? width : "470px")};
  max-width: ${({ width }) => (width ? width : "470px")};
  box-sizing: border-box;
  font-size: 1em;

  &:focus {
    outline: none;
  }
`;

const StyledCaption = styled.div`
  width: ${({ width }) => (width ? width : "470px")};
  margin-top: 15px;
  font-size: 14px;
`;

export default Input;
