import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const NewInput = (props) => {
  const { value, isValid, helperText } = props;

  return (
    <>
      <StyledInput {...props} />
      {!!value && !isValid && <StyledHelperText>{helperText}</StyledHelperText>}
    </>
  );
};

NewInput.propTypes = {
  /** 사용자가 값을 입력하기 전에 input 요소에 표시되는 짧은 힌트입니다. */
  placeholder: PropTypes.string,
  /** 입력한 값이 유효하지 않을 때 나타나는 안내문입니다. */
  helperText: PropTypes.string,
  /** `true`일 경우 input 요소가 비활성화됩니다. */
  disabled: PropTypes.bool,
  /** `true`일 경우 input 요소의 너비가 100%가 됩니다. */
  fill: PropTypes.bool,
  /** `true`일 경우 입력한 값이 유효하지 않음을 나타냅니다. */
  isValid: PropTypes.bool,
  /** 사용자가 입력한 값입니다. */
  value: PropTypes.string,
  /** input 요소의 유형입니다. (text | password) */
  type: PropTypes.string,
};

NewInput.defaultProps = {
  isValid: true,
  type: "text",
};

const StyledInput = styled.input`
  width: ${({ fill }) => (fill ? "100%" : "144px")};
  height: 32px;
  background-color: ${({ disabled }) => (disabled ? "#dddddd" : "#ffffff")};
  border: 1px solid ${({ value, isValid }) => (!value || (value && isValid) ? "#000000" : "#ff0000")};
  box-sizing: border-box;
  border-radius: 25px;
  padding: 0 13px;
  color: #000000;
  font-size: 12px;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset;
  }

  &::placeholder {
    color: #cccccc;
  }

  &:focus {
    outline: none;
  }
`;

export const StyledHelperText = styled.div`
  color: #ff0000;
  font-size: 10px;
  margin-top: 5px;
`;

export default NewInput;
