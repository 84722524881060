export const renderViewData = (activeBleeding, intervention, selectedIndex) => {
  const viewData = { ab: [], inter: [] };
  let interIndex = 0;
  console.log(activeBleeding.length);

  for (let i = 0; i < activeBleeding.length; i++) {
    if (selectedIndex === i) {
      viewData.ab.push({
        ...activeBleeding[i],
        index: i,
        selected: true,
      });
    } else {
      viewData.ab.push({
        ...activeBleeding[i],
        index: i,
      });
    } 

    let cnt = 0;
    for (let j = 0; j < intervention.length; j++) {
      if (intervention[j].index === i) {
        if (cnt > 0) {
          viewData.ab.push(null);
        }
        viewData.inter.push({
          ...intervention[j],
          index: `${i}-${cnt}`,
        });
        cnt++;
      }
    }

    if (cnt === 0) {
      viewData.inter.push(null);
    }
  }

  return viewData;
};
