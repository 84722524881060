import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import downArrow from "../../assets/Dropdown_down_arrow.svg";
import upArrow from "../../assets/Dropdown_up_arrow.svg";

/**
 * options: { value: string; label: string; }
 */
const TypeDropdown = ({ options, onSelect, label, defaultLabel, width }) => {
  const dropdownRef = useRef();
  const [showDropdown, setShowDropdown] = useState(false);

  const onClickTrigger = () => setShowDropdown(!showDropdown);

  const handleChangeValue = (value, label) => {
    onSelect({ value, label });
    setShowDropdown(false);
  };


  // 모달 외부 클릭 시 모달 닫기
  useEffect(() => {
    const onClickOutside = (e) => {
      !dropdownRef.current.contains(e.target) && setShowDropdown(false)
    };

    document.addEventListener("click", onClickOutside);

    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, []);

  const typeOptions = useMemo(() => {
    return [
      <StyledOption color="gray" key={defaultLabel} onClick={() => handleChangeValue(defaultLabel, defaultLabel)}>
        {defaultLabel}
      </StyledOption>, // default option
      ...options.map(({ value, label }) => {   // options
        return (
          <StyledOption color="black" key={value} onClick={() => handleChangeValue(value, label)}>
            {label}
          </StyledOption>
        );
      })
    ]
  }, [options, defaultLabel])

  return (
    <StyledDropDownWrapper ref={dropdownRef}>
      <StyledTrigger width={width} onClick={onClickTrigger} color={label === defaultLabel ? "gray" : "black"}>
        {label}
        <StyledArrowIcon isOpen={showDropdown}></StyledArrowIcon>
      </StyledTrigger>
      {showDropdown && <StyledOptions>{typeOptions}</StyledOptions>}
    </StyledDropDownWrapper>
  );
};

TypeDropdown.propTypes = {
  /** dropdown 요소들 입니다. */
  options: PropTypes.array,
  /** 기본적으로 나타내는 value 요소 입니다. */
  defaultLabel: PropTypes.string,
  /** defaultLabel 변경 및 list filtering 함수 입니다. */
  onClick: PropTypes.func,
  /** 해당하는 요소의 id 값 입니다. */
};

TypeDropdown.defaultProps = {
  defaultLabel: "",
  width: "150px",
  onClick: () => {},
  options: [],
};

const StyledDropDownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const StyledTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 10px;
  background: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  width: ${({ width }) => width};
  color: ${({ color }) => color};
`;

const StyledOptions = styled.div`
  position: absolute;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
  width: 100%;
  top: 40px;
  z-index: 2;
  max-height: 80vh;
  overflow-y: overlay;
`;

const StyledOption = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  cursor: pointer;
  height: 40px;
  z-index: 2;
  background: white;
  color: ${({ color }) => color};

  &:hover {
    cursor: pointer;
    background: #dddddd;
  }
`;

const StyledArrowIcon = styled.div`
  position: relative;
  background: ${({isOpen}) => (isOpen ? css`url(${upArrow}) center no-repeat;` : css`url(${downArrow}) center no-repeat;`)}
  width: 20px;
  height: 20px;
`;

export default TypeDropdown;
