import React, { useEffect } from "react";
import styled from "styled-components";
import successIcon from "../../assets/msg-success.svg";
import errorIcon from "../../assets/msg-error.svg";
import warningIcon from "../../assets/msg-warning.svg";
import Button from "../atoms/NewButton.jsx";

const Content = {
  success: { title: "Success!", src: successIcon },
  error: { title: "Error!", src: errorIcon },
  warning: { title: "Warning!", src: warningIcon },
};

/**
 * 메시지 화면을 렌더링합니다. 결과물은 figma를 참조하세요.
 *
 * * ModalPortal과 Modal로 감싸서 사용하세요.
 * * 지원하는 `type` 은 `success | error | warning` 입니다.
 * * 각 `action` 객체의 내용은 `Button` 컴포넌트로 전달됩니다.
 *
 * @component
 * @example
 * const type = "success";
 * const message = "Some message";
 * const actions = [ { label: 'OK', onClick, type, ... } ]
 * return (
 *   <Message type={type} message={message} actions={actions} />
 * )
 */
const Message = ({ type, message, actions }) => {
  const renderBtns = () =>
    actions.map((action) => <Button key={action.label} {...action} />);

  const { src, title } = Content[type];

  const onEnter = () => {
    for (const action of actions) {
      if (action?.submitOnEnter) {
        action.onClick();
        return;
      }
    }
  };

  const onEsc = () => {
    for (const action of actions) {
      if (action?.cancelOnEsc) {
        action.onClick();
        return;
      }
    }
  };

  const onKeyDown = ({ code }) => {
    switch (code) {
      case "Enter":
        onEnter();
        break;
      case "Escape":
        onEsc();
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <Wrapper>
      <StyledImg src={src} alt={type} />
      <StyledTitle>{title}</StyledTitle>
      <StyledMsg>{message}</StyledMsg>
      <StyledButtonGroup>{renderBtns()}</StyledButtonGroup>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 500px;
  min-height: 340px;

  background: #ffffff;
  border: 1px solid #a0a0a0;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

const StyledImg = styled.img`
  width: 70px;
`;

const StyledTitle = styled.h1`
  text-align: center;
  margin: 15px;
  color: black;
  font-size: 20px;
`;

const StyledMsg = styled.p`
  text-align: center;
  color: black;
  font-size: 14px;
`;

const StyledButtonGroup = styled.div`
  margin-top: 30px;

  button:nth-child(2) {
    margin-left: 30px;
  }
`;

export default Message;
