import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import AnnotationListTab from "../../molecules/AnnotationListTab";
import AnnotationListBody from "../../molecules/AnnotationListBody";
import { setSelected as setSelectedBleeding, removeThunk as removeThunkBleeding } from "../../../redux/ducks/bleeding.js";
import { setSelected as setSelectedFrame, removeThunk as removeThunkFrame } from "../../../redux/ducks/frame.js";
import ModalPortal from "../ModalPortal.jsx";
import Modal from "../Modal.jsx";
import Message from "../Message.jsx";

const ListContainer = ({ annotationType, onClickListItem, bleedingState, frameRate }) => {
  const dispatch = useDispatch();
  const { selected, bleeding, frame } = bleedingState;
  const [msg, setMsg] = useState();
  const [reset, setReset] = useState(0);
  const onClickRow = (row) => {
    dispatch(annotationType === "FRAME SELECTION" ? setSelectedFrame(row) : setSelectedBleeding(row));
    onClickListItem(row);
  };

  const onClickDelete = (row) => {
    const deleteWarningMsg = {
      type: "warning",
      message: "Are you sure you want to delete this annotation?",
      actions: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(annotationType === "FRAME SELECTION" ? removeThunkFrame(row) : removeThunkBleeding(row));
            setMsg(null);
          },
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: () => setMsg(null),
          type: "gray",
          cancelOnEsc: true,
        },
      ],
    };

    setMsg(deleteWarningMsg);
  };

  useEffect(() => {
    if (!selected) setReset(reset + 1);
  }, [selected]);
  return (
    <>
      <div className="annotation-list-container">
        <div className="container-header">
          <AnnotationListTab tabs={[annotationType]} nRows={bleeding ? bleeding.length : frame.length} active={annotationType} />
        </div>
        <div className="container-body">
          <AnnotationListBody
            taskType={annotationType === "HSDB SELECTION" || annotationType === "FRAME SELECTION" ? "frame_selection" : "bleeding"}
            rows={bleeding ? bleeding : frame}
            onClickRow={onClickRow}
            onClickDelete={onClickDelete}
            resetSelection={() => dispatch(annotationType === "FRAME SELECTION" ? setSelectedFrame(null) : setSelectedBleeding(null))}
            reset={reset}
            frameRate={frameRate}
            selected={selected}
          />
        </div>
      </div>
      {msg && (
        <ModalPortal>
          <Modal>
            <Message {...msg} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

export default ListContainer;
