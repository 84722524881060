import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import CholecTab from "../../molecules/AnnotationListTab/CholecTab";
import AnnotationListBody from "../../molecules/AnnotationListBody";
import SkillAssessmentBody from "../../molecules/AnnotationListBody/SkillAssessmentBody";
import ModalPortal from "../ModalPortal.jsx";
import Modal from "../Modal.jsx";
import Message from "../Message.jsx";

import { setSelected, setSelectedSkill, removePhase } from "../../../redux/ducks/skillAssessment";

const CholeSAListContainer = ({ onClickListItem, state, annotationType, frameRate }) => {
  const { selected, selectedSkill, phase, skillAssessment } = state;
  const [rows, setRows] = useState(phase);
  const [reset, setReset] = useState(0);
  const [resetSkill, setResetSkill] = useState(0);
  const [msg, setMsg] = useState(null);

  const dispatch = useDispatch();

  const phaseHeader = ["No.", "Timestamp"];
  console.log(annotationType);
  const listHeader = annotationType.includes("GOALS")
    ? ["Depth Perception", "Bimanual Dexterity", "Efficiency", "Tissue Handling", "Difficulty"]
    : ["Depth Perception", "Bimanual Dexterity", "Efficiency", "Force Sensitivity", "Robotic Control", "Difficulty"];

  const renderPhaseHeader = () => {
    return (
      <StyledUl>
        {phaseHeader.map((header) => (
          <StyledLi>{header}</StyledLi>
        ))}
      </StyledUl>
    );
  };

  const renderListHeader = () => {
    return (
      <StyledUl>
        {listHeader.map((header) =>
          annotationType.includes("GEARS") ? <StyledGEARSLi>{header}</StyledGEARSLi> : <StyledLi>{header}</StyledLi>
        )}
      </StyledUl>
    );
  };

  const setFilterPhase = (phaseNumber) => {
    const code = Object.keys(phaseNumber)[0];

    const codeNumber = code === "PhaseAll" ? +"All" : +code.split("Phase")[1];
    if (codeNumber === "All") {
      setRows(phase);
    } else {
      setRows(phase.filter((p) => p.code === codeNumber));
    }
  };

  const onClickRow = (row) => {
    dispatch(setSelected(row));
    onClickListItem(row);
  };

  const onClickSkillRow = (row) => {
    const code = Object.keys(row)[0];
    dispatch(setSelectedSkill([row[code], code]));
    setFilterPhase(row);
  };

  const onClickDelete = (row) => {
    const deleteWarningMsg = {
      type: "warning",
      message: "Are you sure you want to delete this phase?",
      actions: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(removePhase(row));
            setMsg(null);
          },
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: () => setMsg(null),
          type: "gray",
          cancelOnEsc: true,
        },
      ],
    };

    setMsg(deleteWarningMsg);
  };

  useEffect(() => {
    if (!selected) setReset(reset + 1);
  }, [selected]);

  useEffect(() => {
    if (!selectedSkill) {
      setResetSkill(resetSkill + 1);
      setRows(phase);
    }
  }, [selectedSkill]);

  useEffect(() => {
    setRows(phase);
  }, [phase]);

  return (
    <>
      <Wrapper>
        <Header>
          <CholecTab tabs={["Phase", "Skill Assessment"]} />
        </Header>
        <Body>
          <BodyHeader>
            <PhaseHeader>{renderPhaseHeader()}</PhaseHeader>
            {renderListHeader()}
          </BodyHeader>
          <CholecBody>
            <AnnotationListBody
              taskType={annotationType}
              frameRate={frameRate}
              rows={rows}
              disableRemove={true}
              onClickRow={onClickRow}
              onClickDelete={onClickDelete}
              resetSelection={() => dispatch(setSelected(null))}
              reset={reset}
            />
            <SkillAssessmentBody
              skill={skillAssessment[0]}
              onClickRow={onClickSkillRow}
              resetSelection={() => dispatch(setSelectedSkill(null))}
              reset={resetSkill}
              annotationType={annotationType}
            />
          </CholecBody>
        </Body>
      </Wrapper>
      {msg && (
        <ModalPortal>
          <Modal>
            <Message {...msg} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

const CholecBody = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const StyledGEARSLi = styled.li`
  float: left;
  border: 1px solid;
  font-size: 5px;
  white-space: normal;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50 px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #f3f3f3;
  font-weight: 700;
`;
const StyledLi = styled.li`
  float: left;
  border: 1px solid;
  font-size: 5px;
  white-space: normal;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 60px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #f3f3f3;
  font-weight: 700;
`;

const StyledUl = styled.ul`
  display: flex;
  list-style: none;
  font-size: 10px;
  padding-left: 50px;
`;

const PhaseHeader = styled.div`
  width: 35%;
  border-right: 1px solid #999da0;

  ul {
    width: 100%;
    height: 30px;
    padding-left: 20px;

    li:first-child {
      width: 40px;
    }

    li:last-child {
      width: 120px;
    }
  }
`;

const BodyHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #999da0;
`;

const Body = styled.div`
  min-height: calc(100% - 50px);
  display: flex;
  flex-direction: column;

  &:first-child {
    border-right: 1px solid #999da0;
  }
`;

const Header = styled.div`
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #dddddd;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 95%;
  max-height: 820px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 12px rgba(71, 77, 81, 0.2);
  overflow: hidden;
`;

export default CholeSAListContainer;
