import { useReducer } from "react";
import { request } from "../libs";

const initialState = { status: "pending" };

const reducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case "success":
      return { type, ...action };
    case "failure":
      return { type, ...action };
    default:
      return initialState;
  }
};

// TODO 요청 취소 구현
const useRequest = () => {
  const [result, dispatch] = useReducer(reducer, initialState);

  const send = async (config) => {
    try {
      const response = await request(config);
      dispatch({
        type: "success",
        status: response?.status,
        data: response?.data,
      });
    } catch (error) {
      console.error(error.response);
      dispatch({
        type: "failure",
        error:
          error?.response?.data?.message ||
          error?.response?.data ||
          error.message,
        status: error?.response?.status,
      });
    }
  };
  return [result, send];
};

export { useRequest };
