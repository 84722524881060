import React from "react";
import styled from "styled-components";

export const HeadCheckbox = ({ onChange, ...rest }) => {
  return <StyledInput
    type="checkbox"
    id="checkbox-head"
    onChange={e => {
      checkAllCheckboxes(e.target.checked)
      onChange(e)
    }}
    { ...rest }
  />;
};

export const Checkbox = ({ onChange, ...rest }) => {
  return <StyledInput
    type="checkbox"
    className="checkbox-group"
    onChange={e => {
      checkHeadIfAllChecked()
      onChange(e)
    }}
    { ...rest }
  />
}

export function resetCheckboxes() {
  const headCheckbox = getHeadCheckbox()
  if (headCheckbox) {
    headCheckbox.checked = false
  }
  const checkboxes = getAllCheckboxes()
  checkboxes.forEach(checkbox => checkbox.checked = false)
}

function getHeadCheckbox() {
  return document.getElementById("checkbox-head")
}

function getAllCheckboxes() {
  return [...document.getElementsByClassName("checkbox-group")]
}

function checkHeadIfAllChecked() {
  const headCheckbox = getHeadCheckbox()
  if (!headCheckbox) return

  const checkboxes = getAllCheckboxes()
  if (checkboxes.length !== 0 && checkboxes.every(checkbox => checkbox.checked)
  ) {
    headCheckbox.checked = true
  } else {
    headCheckbox.checked = false
  }
}

function checkAllCheckboxes(isChecked) {
  const checkboxes = getAllCheckboxes()
  return (
    isChecked
      ? checkboxes.forEach(checkbox => checkbox.checked = true)
      : checkboxes.forEach(checkbox => checkbox.checked = false)
  )
}

const hutomColors = {
  green: "#46b297",
  purple: "#7E5FD3",
  red: "#FF5F5F",
  black: "black",
};

const StyledInput = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
