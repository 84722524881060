import React, { useEffect } from "react";
import styled from "styled-components";
import NotiMsg from "../molecules/NotiMsg.jsx";

const MSG_ALL_CLEAR = "You're all caught up.";

const NotiList = ({ unreads, onClose }) => {
  const renderList = () =>
    unreads.map((unread, i) => (
      <NotiMsg key={unread + i} msg={unread.message} />
    ));

  useEffect(() => () => onClose(), []);

  return <Container>{unreads.length ? renderList() : MSG_ALL_CLEAR}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

export default NotiList;
