const GRADING = {
  Cause: {
    D: "Blunt Dissection",
    H: "Harmonic Cutting",
    M: "Monopolar Cautery",
    B: "Bipolar Cautery",
    C: "Dislodged Clip",
    S: "Stapler Cutting",
    N: "Suture Needle Injury",
    G: "Grasping",
    T: "Traction",
    TI: "Trocar insertion",
  },
  Method: {
    G: "Compression Gauze",
    H: "Harmonic Coagulation",
    M: "Monopolar Coagulation",
    B: "Biopolar Coagulation",
    C: "Metal Clip Ligation",
    HL: "Hemolock Ligation",
    S: "Suture Ligation",
    HS: "Hemostatic Agent",
    X: "Spontaneous Resolved",
    Z: "Neglected",
  },
  Other: {
    O: "Other",
  },
};

export { GRADING };
