import { checkConflict, insertPhase, remove, insertSkillAssessment, mergePhase } from "../../utils";
import { saveAnnotation } from "../../api";

// actions
const SET_ID = "skillAssessment/SET_ID";
const SET_SELECTED = "skillAssessment/SET_SELECTED";
const SET_SELECTED_SKILL = "skillAssessment/SET_SELECTED_SKILL";
const SET_PHASE = "skillAssessment/SET_PHASE";
const SET_SKILL_ASSESSMENT = "skillAssessment/SET_SKILL_ASSESSMENT";
const RESET_PHASE = "skillAssessment/RESET_PHASE";
const CHECK_PHASE = "skillAssessment/CHECK_PHASE";
const CLEAR_WARNING = "skillAssessment/CLEAR_WARNING";

const initialState = {
  id: { phase: "", skillAssessment: "" },
  selected: "",
  selectedSkill: "",
  phase: [],

  skillAssessment: [
    {
      Phase1: {
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
        label: "Preparation",
      },
      Phase2: {
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
        label: "Calot Triangle Dissection",
      },
      Phase3: {
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
        label: "Clipping and Cutting",
      },
      Phase4: {
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
        label: "Gallbladder Dissection",
      },
      Phase5: {
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
        label: "Gallbladder Packaging",
      },
      Phase6: {
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
        label: "Cleaning and Coagulation",
      },
      Phase7: {
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
        label: "Gallbladder Retraction",
      },
      PhaseAll: {
        "Depth Perception": "",
        "Bimanual Dexterity": "",
        Efficiency: "",
        "Tissue Handling": "",
        Difficulty: "",
        label: "All",
      },
    },
  ],
  warning: { data: "", list: [] },
};

// reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ID:
      return { ...state, id: action.payload };
    case SET_SELECTED:
      const selected = action.payload ? { ...state.selected, ...action.payload } : null;
      return { ...state, selected: selected };
    case SET_SELECTED_SKILL:
      const selectedSkill = action.payload ? action.payload : null;
      return { ...state, selectedSkill };
    case SET_PHASE:
      return {
        ...state,
        phase: action.payload,
        selected: { ...initialState.selected, start: state?.selected?.end + 1 },
        warning: initialState.warning,
      };

    case SET_SKILL_ASSESSMENT:
      return {
        ...state,
        skillAssessment: [...action.payload],
        selected: initialState.selected,
        selectedSkill: initialState.selectedSkill,
      };
    case CHECK_PHASE:
      return {
        ...state,
        warning: {
          data: action.payload,
          list: checkConflict(state.phase, action.payload),
        },
      };
    case CLEAR_WARNING:
      return { ...state, warning: initialState.warning };
    case RESET_PHASE:
      return initialState;
    default:
      return state;
  }
}

// action creators
export function setId(payload) {
  return { type: SET_ID, payload };
}

export function setSelected(payload) {
  return { type: SET_SELECTED, payload };
}

export function setSelectedSkill(payload) {
  return { type: SET_SELECTED_SKILL, payload };
}

export function setPhase(payload) {
  return { type: SET_PHASE, payload };
}

export function setSkillAssessment(payload) {
  return { type: SET_SKILL_ASSESSMENT, payload };
}

export function checkPhase(payload) {
  return { type: CHECK_PHASE, payload };
}

export function resetPhase() {
  return { type: RESET_PHASE };
}

export function clearWarning() {
  return { type: CLEAR_WARNING };
}

export const insertPhaseThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().skillAssessment;
    const result = mergePhase(insertPhase(state.phase, payload));
    const { status } = await saveAnnotation(state.id.phase, result);
    if (status === 200) dispatch(setPhase(result));
  } catch (error) {
    console.error(error);
  }
};

export const insertSkillAssessmentThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().skillAssessment;
    const selected = state.selectedSkill ? state.selectedSkill : state.selected;
    const code = state.selectedSkill ? selected[1] : `Phase${selected.code}`;
    const result = insertSkillAssessment(state.skillAssessment, payload, code);
    const { status } = await saveAnnotation(state.id.skillAssessment, result);
    if (status === 200) dispatch(setSkillAssessment(result));
  } catch (error) {
    console.error(error);
  }
};

export const removePhase = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().skillAssessment;
    const phase = remove(state.phase, payload);
    const { status } = await saveAnnotation(state.id.phase, phase);
    if (status === 200) dispatch(setPhase(phase));
  } catch (error) {
    console.error(error);
  }
};
export const changeToGastrectomy = (payload) => async (dispatch, getState) => {
  const gast = [{}];
  for (let i = 1; i <= 21; i++) {
    gast[0]["Phase" + i.toString()] =
      payload !== "GEARS"
        ? {
            "Depth Perception": "",
            "Bimanual Dexterity": "",
            Efficiency: "",
            "Tissue Handling": "",
            Difficulty: "",
          }
        : {
            "Depth Perception": "",
            "Bimanual Dexterity": "",
            Efficiency: "",
            "Force Sensitivity": "",
            "Robotic Control": "",
            Difficulty: "",
          };
  }
  gast[0].PhaseAll =
    payload !== "GEARS"
      ? {
          "Depth Perception": "",
          "Bimanual Dexterity": "",
          Efficiency: "",
          "Tissue Handling": "",
          Difficulty: "",
        }
      : {
          "Depth Perception": "",
          "Bimanual Dexterity": "",
          Efficiency: "",
          "Force Sensitivity": "",
          "Robotic Control": "",
          Difficulty: "",
        };

  dispatch(setSkillAssessment(gast));
};
// import 부분
export const importPhase = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().skillAssessment;
    const { status } = await saveAnnotation(state.id.phase, payload);
    if (status === 200) dispatch(setPhase(payload));
  } catch (error) {
    console.error(error);
  }
};

export const importSkillAssessment = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().skillAssessment;
    const { status } = await saveAnnotation(state.id.skillAssessment, payload);
    if (status === 200) dispatch(setSkillAssessment(payload));
  } catch (error) {
    console.error(error);
  }
};
