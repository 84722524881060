import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Account from "../molecules/Account.jsx";
import Dropdown from "../molecules/Dropdown.jsx";
import NotiBadge from "../molecules/NotiBadge.jsx";
import NotiList from "./NotiList.jsx";
import UserMenu from "./UserMenu.jsx";
import { Links } from "./constants.js";
import { deleteCookie } from "../../utils";
import { resetUser } from "../../redux/actions.js";
import { useRequest } from "../../hooks";
import { ReactComponent as Logo } from "../../assets/hutom-logo.svg";

const NavBar = ({ userInfo, currPath }) => {
  const [result, send] = useRequest();
  const [markAsReadResult, sendMarkAsRead] = useRequest();

  const history = useHistory();
  const dispatch = useDispatch();

  const [showAccount, setShowAccount] = useState(false);
  const [showNoti, setShowNoti] = useState(false);

  const renderLinks = (links) =>
    links.map(({ name, url }) => {
      const isActive = currPath.includes(url) ? 1 : 0;

      return (
        <StyledLink key={name} to={url} isactive={isActive}>
          <span>{name}</span>
        </StyledLink>
      );
    });

  const getLinks = () => {
    if (userInfo?.role?.admin === true) return renderLinks(Links.admin);
    if (userInfo?.role?.annotator === true) return renderLinks(Links.annotator);
  };

  const onClickAccount = () => {
    setShowAccount(true);
  };

  const onClickNotiIcon = () => {
    setShowNoti(true);
  };

  const closeDropdown = () => {
    if (showAccount) {
      setShowAccount(false);
    } else if (showNoti) {
      setShowNoti(false);
    }
  };

  const onClickEditPassword = () => {
    closeDropdown();
    history.push({ pathname: "/password" });
  };

  const onClickLogout = () => {
    deleteCookie("accessToken");
    dispatch(resetUser());
    history.push({ pathname: "/" });
  };

  const getNotifications = () => {
    const request = {
      method: "GET",
      url: "/notifications",
    };
    send(request);
  };

  const postMarkAsRead = () => {
    if (!result.data) return;

    const ids = result.data.map(({ _id }) => _id);
    const request = {
      method: "POST",
      url: "/notifications/markAsRead",
      data: { ids },
    };
    sendMarkAsRead(request);
  };

  useEffect(() => {
    getNotifications();
  }, [currPath]);

  useEffect(() => {
    if (markAsReadResult.type === "success") {
      getNotifications();
    }
  }, [markAsReadResult]);

  return (
    <>
      <Wrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        {userInfo ? (
          <>
            <StyledLinkGroup>{getLinks()}</StyledLinkGroup>
            <Account
              image={userInfo.image}
              username={userInfo.username}
              onClick={onClickAccount}
            />
            <StyledNotiBadge
              nunreads={result?.data?.length ?? 0}
              onClick={onClickNotiIcon}
            />
          </>
        ) : (
          <StyledText href={"/login"}>Sign In</StyledText>
        )}
      </Wrapper>
      {showAccount && (
        <Dropdown onClose={closeDropdown} offset={{ right: "60px" }}>
          <UserMenu
            userInfo={userInfo}
            onClickEditPassword={onClickEditPassword}
            onClickLogout={onClickLogout}
          />
        </Dropdown>
      )}
      {showNoti && (
        <Dropdown onClose={closeDropdown}>
          <NotiList
            unreads={Array.isArray(result?.data) ? result.data : []}
            onClose={postMarkAsRead}
          />
        </Dropdown>
      )}
    </>
  );
};

const Wrapper = styled.div`
  background-color: #313541;
  width: 100%;
  height: 45px;
  display: flex;
`;

const LogoWrapper = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  box-sizing: border-box;
`;

const StyledText = styled.a`
  color: white;
  font-weight: 700;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;
`;

const StyledLinkGroup = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  margin-right: auto;
`;

const StyledLink = styled(Link)`
  display: flex;
  height: 100%;
  text-decoration: none;
  color: ${({ isactive }) => (isactive ? "white" : "#A0A0A0")};
  font-weight: ${({ isactive }) => (isactive ? 700 : 500)};
  font-size: 14px;
  cursor: pointer;

  span {
    margin: auto 60px;
  }
`;

const StyledNotiBadge = styled(NotiBadge)`
  width: 15px;
  margin: auto 10px;
  margin-right: 30px;
`;

export default NavBar;
