import React from "react";
import styled from "styled-components";

const TableCell = ({ children, align, fontSize, ...rest }) => {
  return (
    <StyledTd align={align} fontSize={fontSize} {...rest}>
      {children}
    </StyledTd>
  );
};

TableCell.defaultProps = {
  align: "left",
  fontSize: "12px"
};

export const StyledTd = styled.td`
  border-bottom: 1px solid #dddddd;
  white-space: nowrap;
  font-size: ${({ fontSize }) => fontSize};
  text-align: ${({ align }) => align};
  padding: 15px 30px;
`;

export default React.memo(TableCell);
