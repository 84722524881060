import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Input from "../atoms/NewInput.jsx";
import Button from "../atoms/NewButton.jsx";
import ButtonGroup from "../atoms/ButtonGroup.jsx";
import ModalPortal from "./ModalPortal.jsx";
import Modal from "./Modal.jsx";
import Message from "./Message.jsx";

import { setSelected, check, insertThunk, clearWarning, resetImport } from "../../redux/ducks/frame.js";
import { validate } from "../../utils/validate.js";
import { toFrame, toHHMMSSFF, completeHHMMSSFF } from "../../utils/annotation.js";
import { normalizeString, capitalizeFirstLetter } from "../../utils";

const FrameSelectionInputContainer = ({ className, totalFrame, annotationType, frameState, frameRate, onChange }) => {
  const dispatch = useDispatch();
  const { selected, warning } = frameState;
  const [label, setLabel] = useState("");
  const [frameInputValues, setFrameInputValues] = useState({
    start: "00:00:00:00",
  });

  const [reset, setReset] = useState(0);
  const updateInput = useCallback(
    ({ target: { name, value } }) => {
      if (validate(value, "hhmmssff")) {
        const newValues = {
          ...frameInputValues,
          [name]: value,
        };

        setFrameInputValues(newValues);
      }
    },
    [frameInputValues]
  );
  const inputOnKeyD = (e) => {
    e.stopPropagation();
  };
  const inputOnKeyDown = (e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      updateValidatedInputs();
    }
  };

  /**
   * Update validated Inputs as hh:mm:ss:ff and End hh:mm:ss:ff
   */
  const updateValidatedInputs = () => {
    const startHHMMSSFF = completeHHMMSSFF(frameInputValues.start);
    const startFrame = toFrame(startHHMMSSFF, frameRate);
    dispatch(setSelected({ start: startFrame }));

    setFrameInputValues({
      ...frameInputValues,
      start: toHHMMSSFF(startFrame < totalFrame ? startFrame : totalFrame, frameRate),
    });
  };

  const insertFrameSelection = () => dispatch(insertThunk(warning.data));
  const closeWarning = () => dispatch(clearWarning());
  const onChangeLabel = (e) => {
    dispatch(setSelected({ label: e.target.value }));

    setLabel(e.target.value);
  };
  const inputProps = [
    {
      placeholder: "Start",
      name: "start",
      value: frameInputValues?.start !== undefined ? frameInputValues.start : "",
      onChange: updateInput,
      onKeyDown: inputOnKeyDown,
      onBlur: updateValidatedInputs,
      onFocus: (e) => e.target.setSelectionRange(0, e.target.value.length),

      autoComplete: "off",
    },
  ];

  const renderInputs = () => inputProps.map((item, i) => <StyledInput key={"" + reset + i} {...item} />);

  const resetInput = () => {
    dispatch(setSelected(null));
  };

  const makeString = (obj) => {
    const { label, start, end } = obj;
    return `(${label}) ${start} - ${end}`;
  };

  const getMessage = () => {
    const msg = warning.list.map((curr) => makeString(curr)).join(", ");

    return `This Section is already filled. You can't add the frame.`;
  };

  const warningModal = useMemo(() => {
    if (!warning.data) return;

    return {
      type: "warning",
      message: getMessage(),
      actions: [
        {
          label: "Okay",
          onClick: closeWarning,
          cancelOnEsc: true,
        },
      ],
    };
  }, [warning]);
  const resetFrameSelection = () => {
    dispatch(resetImport([]));
  };
  const dispatchFrameSelection = () => {
    const data = {
      start: toFrame(frameInputValues.start, frameRate),
      label: label,
      code: 1,
    };

    dispatch(check(data));
  };

  useEffect(() => {
    if (selected) {
      setLabel(selected.label);
      selected.start = !selected.start
        ? toFrame(frameInputValues.start, frameRate)
        : selected.start < totalFrame
        ? selected.start
        : totalFrame;

      setFrameInputValues({
        ...selected,
        start: toHHMMSSFF(selected.start, frameRate),
      });
    } else {
      setLabel("");
      setFrameInputValues({ start: "00:00:00:00" });
    }

    setReset(reset + 1);
  }, [selected]);

  useEffect(() => {
    const { data, list } = warning;
    if (data && !list?.length) dispatch(insertThunk(warning.data));

    setFrameInputValues({ start: "00:00:00:00" });
    setReset(reset + 1);
  }, [warning]);

  return (
    <>
      <Wrapper className={className}>
        <StyledContent>
          <StyledInputWrapper>
            {renderInputs()}
            <StyledTitle>Label:</StyledTitle>
            <StyledLabelInput
              id="grading-input"
              data-id="cause"
              data-nested="cause"
              name="other"
              value={label}
              onKeyDown={inputOnKeyD}
              onChange={onChangeLabel}
            />
            <StyledTitle>{capitalizeFirstLetter(normalizeString(annotationType))}</StyledTitle>
          </StyledInputWrapper>
          <StyledButtonWrapper>
            <StyledImportInput type="file" id="import_json" name="import_json" accept=".json" onChange={onChange} />

            <StyledLabel htmlFor="import_json">Import</StyledLabel>
            <Button label="Reset" size="x-small" onClick={resetFrameSelection} />

            {selected && <Button label="Cancel" size="x-small" onClick={resetInput} />}
            <Button label={selected?.code ? "Save" : "Add"} theme="secondary" size="small" onClick={dispatchFrameSelection} />
          </StyledButtonWrapper>
        </StyledContent>
      </Wrapper>
      {warning?.list?.length > 0 && (
        <ModalPortal>
          <Modal>
            <Message {...warningModal} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  height: 120px;
  max-height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  line-height: 30px;
  font-size: 14px;
  padding: 0 40px;
  box-sizing: border-box;
  font-weight: 500;
  margin-top: 30px;
`;

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  align-content: space-around;
  padding: 0px 40px;
  box-sizing: border-box;
`;

const StyledInput = styled(Input)`
  font-size: 10px;
  margin-top: 30px;
  :not(:first-of-type) {
    margin-left: 30px;
  }
`;
const StyledLabelInput = styled(Input)`
  font-size: 10px;
  width: 300px;
  margin-top: 30px;
  :not(:first-of-type) {
    margin-left: 30px;
  }
`;
const StyledInputWrapper = styled.div`
  display: flex;
  ㄴ
  align-items: center;
`;

const StyledButtonWrapper = styled(ButtonGroup)`
  margin: auto 0 auto auto;
  margin-left: -75px;
`;

const StyledDiv = styled.div`
  margin-left: 50px;
  margin-bottom: 15px;
`;
const StyledImportInput = styled.input`
  display: none;

  &:hover {
    cursor: point;
  }
`;

const StyledLabel = styled.label`
  background-color: #00a999;
  padding: 5px 5px;
  border-radius: 70px;
  width: 100px;
  height: auto;
  font-size: 0.8rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;
`;
export default FrameSelectionInputContainer;
