import React from "react";
import "./style.scss";

/**
 * @param {number} nRows 어노테이션 배열 사이즈
 * @param {object} onClickTab 어노테이션 분류 선택
 */
const AnnotationListTab = ({ tabs, nRows, onClickTab, active }) => {
  const renderTab = () =>
    tabs.map((tab, i) => {
      const isActive = tab === active;

      return (
        <div
          key={i}
          className={isActive ? "list-tab selected" : "list-tab"}
          onClick={() => {
            if (!isActive && onClickTab) onClickTab();
          }}
        >
          {active + (isActive ? ` (${nRows})` : "")}
        </div>
      );
    });

  return <div className="annotation-list-tab">{renderTab()}</div>;
};

export default AnnotationListTab;
