import axios from "axios";

const { API_URL } = window.env;

const request = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export { request };
