import React from "react";
import styled from "styled-components";

const TableHead = ({ children }) => {
  return <StyledThead>{children}</StyledThead>;
};

const StyledThead = styled.thead`
  td {
    font-size: 14px;
    font-weight: 700;
    border-bottom: 3px solid #dddddd;
  }
`;

export default TableHead;
