import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

export const Modal = styled.div`
  width: fit-content;
  height: fit-content;
`;

const ModalTransitionStyles = css`
  &.modal-transition-enter ${Modal} {
    transform: scale(0);
    opacity: 0;
  }
  &.modal-transition-enter-active ${Modal} {
    transform: scale(1.05);
    opacity: 0.9;
    transition: all 200ms ease-in-out;
  }
  &.modal-transition-enter-done ${Modal} {
    transform: scale(1);
    opacity: 1;
    transition: all 200ms ease-in-out;
  }
  &.modal-transition-exit ${Modal} {
    transform: scale(1.05);
    opacity: 1;
  }
  &.modal-transition-exit-active ${Modal} {
    transform: scale(0.95);
    opacity: 0;
    transition: transform 200ms ease-in-out, opacity 100ms ease-in-out;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.25);
  ${ifProp({ transition: true }, ModalTransitionStyles)}
`;
