import { useState } from "react";
import { validate } from "../utils";

/**
 * @description input 태그의 value에 대하여 유효성을 검사합니다.
 * @param {string} type 검사 가능한 포맷은 `'email' | 'password'` 입니다.
 * @returns {array} `[onChange, value, isValid]`
 */
const useValidation = (type) => {
  const [value, setInput] = useState();
  const [isValid, setIsValid] = useState();

  const onChange = (event) => {
    if (!event) return setInput("");

    const { value } = event.target;
    setInput(value);
    setIsValid(validate(value, type));
  };

  return [onChange, value, isValid];
};

export { useValidation };
