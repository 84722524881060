import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { io } from 'socket.io-client';

import Button from "../../components/atoms/NewButton";
import { HeadCheckbox, Checkbox, resetCheckboxes } from "../../components/atoms/CheckboxGroup";

import ScrollTable from "../../utils/scroll-table";
import LoadingModal from "../../components/organisms/LoadingModal";

import { useRequest } from "../../hooks";

const VideoUpdate = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [getResult, sendGetUnsaved] = useRequest();
  const [postFrame, sendPostFrame] = useRequest();

  const [postResult, sendPostVideos] = useRequest();
  const [checkedSet, setCheckedSet] = useState(new Set());
  const [videos, setVideos] = useState([]);
  const [tableHead, setTableHead] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [socket, setSocket] = useState(null);
  
  useEffect(() => {
    let socketClient;
    if (process.env.NODE_ENV === "production") {
      socketClient = io(window.location.origin, { path: "/api/socket.io" })
    } else {
      socketClient = io(window.env.API_URL)
    }

    socketClient
      .on('connect', () => setSocket(socketClient))
      .on('error', console.error)
    
    return () => {
      socketClient.disconnect()
    }
  }, [])

  useEffect(() => {
    if (getResult.status === "pending") return
    if (getResult.error) {
      alert(getResult.error)
    }
    if (getResult.data) {
      setCheckedSet(new Set())
      setVideos(getResult.data)
    }
    setShowLoading(false)
  }, [getResult])
  useEffect(() => {
    console.log(postFrame.data)
    setShowLoading(false);

  }, [postFrame])

  useEffect(() => {
    if (postResult.error) {
      setShowLoading(false);
      alert(postResult.error)
    }
  }, [postResult]);

  useEffect(() => {
    const tableBody = getTableBody(videos)
    const tableHead = getTableHead(videos)
    setTableBody(tableBody)
    setTableHead(tableHead)
    resetCheckboxes()
  }, [videos])
  const onClickFrame = useCallback(() => {
    const request = {
      method: "POST",
      url: `${window.env.API_URL}/videos/updateFrameRate`
    }

    setShowLoading(true);
    sendPostFrame(request)
  }, []);
  const onClickSearch = useCallback(() => {
    const request = {
      method: "GET",
      url: `${window.env.API_URL}/videos?saved=false`
    }
    setShowLoading(true);
    sendGetUnsaved(request)
  }, []);

  const onClickUpdate = useCallback(() => {
    const request = {
      method: "POST",
      url: `${window.env.API_URL}/videos`,
      data: {
        paths: Array.from(checkedSet.values())
      }
    };

    socket && socket.once('update-result', result => {
      alert(`
        성공: ${result.success.length}
        실패: ${result.fail.length}
      `);
      setVideos([])
      setCheckedSet(new Set())
      setShowLoading(false)
    })
    
    setLoadingMessage("Updating videos")
    setShowLoading(true);
    sendPostVideos(request);
  }, [checkedSet, socket]);
  
  const handleCheckAll = (isChecked) => {
    isChecked
      ? setCheckedSet(new Set(videos))
      : setCheckedSet(new Set())
  }

  const handleCheck = (videopath, isChecked) => {
    isChecked
      ? checkedSet.add(videopath) && setCheckedSet(new Set(checkedSet.values()))
      : checkedSet.delete(videopath) && setCheckedSet(new Set(checkedSet.values()))
  }

  const getTableHead = (videos) => {
    return [
      <HeadCheckbox
        disabled={videos.length === 0}
        onChange={(e) => handleCheckAll(e.target.checked)}
      />,
      "Index",
      "Video",
    ];
  };

  const getTableBody = (videos) => {
    return videos.map((videopath) => {
      const paths = videopath.split('/')
      const indexCode = paths.slice(-2, -1)[0]
      const filename = paths.slice(-1)[0]
      
      return [
        <Checkbox
          onChange={(e) => handleCheck(videopath, e.target.checked)}
        />,
        indexCode,
        filename,
      ]
    });
  };

  return (
    <Wrapper>
      <LoadingModal show={showLoading} message={loadingMessage}></LoadingModal>
      <PageHeader>
        <StyledP>{`새로운 비디오: ${videos.length}`}</StyledP>
        <StyledButton label="Search" onClick={onClickSearch}/>
        &nbsp; &nbsp;
        <StyledButton label="Update" onClick={onClickUpdate} disabled={checkedSet.size === 0 ? true : false}/>
        {checkedSet.size !== 0 && <div>&nbsp; &nbsp; {checkedSet.size} 개 선택됨</div>}
        <StyledButton label="FrameRate" onClick={onClickFrame}/>

      </PageHeader>
      <StyledScrollTable
        headRow={tableHead}
        bodyRows={tableBody}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 45px - 30px - 30px);
  margin: 20px 60px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledP = styled.p`
  width: 150px;
  margin: 0em;
  padding-right: 1em;
  font-size: large;
`

const StyledButton = styled(Button)`
  font-weight: bold;
`;

const StyledScrollTable = styled(ScrollTable)`
  flex: 1;
  width: 100%;
  margin-top: 20px;
`;

export default VideoUpdate;
