import React, { useEffect } from "react";
import styled from "styled-components";

// FIXME 스타일 재사용성 너무 떨어지는 것 같음...
const Dropdown = ({ children, onClose, offset }) => {
  const onClickWrapper = (e) => {
    e.stopPropagation();
    if (e.target.id === "dropdown-wrapper") onClose();
  };

  const onEscDown = ({ keyCode }) => {
    if (keyCode === 27) onClose();
  };

  useEffect(() => {
    window.addEventListener("keydown", onEscDown);

    return () => window.removeEventListener("keydown", onEscDown);
  }, []);

  return (
    <Wrapper onClick={onClickWrapper} id="dropdown-wrapper">
      <Container offset={offset}>{children}</Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const Container = styled.div`
  position: absolute;
  top: ${({ offset }) => (offset && offset.top) || "55px"};
  right: ${({ offset }) => (offset && offset.right) || "20px"};
  width: fit-content;
  height: fit-content;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 15px 15px;
  background-color: white;
  box-sizing: border-box;
  max-height: 480px;
  overflow-y: auto;
`;

export default Dropdown;
