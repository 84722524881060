import { SET_USER, RESET_USER, SET_TEMP_USER } from "../actionTypes";

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      window.localStorage.setItem("user", JSON.stringify(action.payload));
      return action.payload;
    }
    case RESET_USER: {
      window.localStorage.removeItem("user");
      return initialState;
    }
    case SET_TEMP_USER: {
      const user = JSON.parse(window.localStorage.getItem("user"));
      return user ? user : initialState;
    }
    default:
      return state;
  }
};
