import React from "react";
import styled, { css } from "styled-components";

const Button = ({ label, ...rest }) => {
  return <StyledButton {...rest}>{label}</StyledButton>;
};

const grayButton = css`
  background: linear-gradient(90deg, #8a929b 0%, #98a4af 100%);
`;

const linedButton = css`
  background: none;
  color: black;
  border: 1px solid black;
`;

const small = css`
  width: 140px;
  height: 50px;
  border-radius: 42px;
  font-size: 20px;
`;
const large = css`
  width: 200px;
  height: 80px;
  border-radius: 43px;
  font-size: 28px;
`;
const xlarge = css`
  width: 470px;
  height: 80px;
  border-radius: 65px;
  font-size: 28px;
`;

const StyledButton = styled.button`
  background: linear-gradient(90deg, #37aa9d 0.07%, #48b79b 100.07%);
  color: white;
  border: none;
  width: ${({ width }) => (width ? width : "180px")};
  height: 50px;
  border-radius: 43px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${({ type }) =>
    (type === "lined" && linedButton) || (type === "gray" && grayButton)}

  ${({ size }) =>
    (size === "small" && small) ||
    (size === "large" && large) ||
    (size === "xlarge" && xlarge)}
  
  &:disabled {
    background: linear-gradient(90deg, #879098 0%, #99a6b1 100%);
    cursor: not-allowed;
  }
`;

export default Button;
