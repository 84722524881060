import React from "react";
import "./style.scss";

/**
 * @param {number} nRows 어노테이션 배열 사이즈
 * @param {object} onClickTab 어노테이션 분류 선택
 */
const DualTab = ({ tabs, nRows }) => {
  const renderTab = () =>
    tabs.map((tab, i) => {
      const isActive = true;
      return (
        <div key={i} className="list-tab">
          {tab + (isActive ? ` (${nRows[i]})` : "")}
        </div>
      );
    });

  return <div className="annotation-list-tab">{renderTab()}</div>;
};

export default DualTab;
