import React from "react";
import Button from "../atoms/NewButton.jsx";
import Input from "../atoms/NewInput.jsx";
import styled from "styled-components";

/**
 * 수정사항
 * @param style default form style 이외의 style 적용, warning form
 */
const Form = ({ formActions: { onSubmit, onCancel }, items, classNames }) => {
  const renderItems = () =>
    items.map((item, i) => <StyledInput key={i} {...item} fill />);

  return (
    <StyledForm
      onSubmit={onSubmit.action}
      autoComplete="off"
      classNames={classNames}
    >
      {items && renderItems()}
      <StyledButtonWrapper>
        {onCancel && <Button {...onCancel} />}
        <Button type="submit" theme="secondary" {...onSubmit} />
      </StyledButtonWrapper>
    </StyledForm>
  );
};

const StyledInput = styled(Input)`
  margin-bottom: 35px;
`;

const StyledForm = styled.form`
  width: 320px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButtonWrapper = styled.div`
  width: fit-content;
  max-width: 100%;
  display: flex;

  button:nth-child(2) {
    margin-left: 30px;
  }
`;

export default Form;
