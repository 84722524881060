import React from "react";
import styled from "styled-components";

const TableRow = ({ children, ...rest }) => {
  return <StyledTableRow {...rest}>{children}</StyledTableRow>;
};

const StyledTableRow = styled.tr`
  display: table;
  width: 100%;
  table-layout: fixed;
`

export default React.memo(TableRow);
