import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CheckboxList from "../molecules/CheckboxList";
import { ASSIGN_TYPE } from "./constants";
import { getFileName } from "../../utils/common";
const AssignBody = ({ videoData, userData, taskTypeData, currentPage }) => {
  const [indexCodeList, setIndexCodeList] = useState([]); // 체크리스트에 보여지는 인덱스코드 리스트 관리용 상태입니다.
  const [videoList, setVideoList] = useState([]); // 체크리스트에 보여지는 비디오 리스트 관리용 상태입니다.
  const [taskTypeList, setTaskTypeList] = useState([]); // 체크리스트에 보여지는 타입 리스트 관리용 상태입니다.
  const [userList, setUserList] = useState([]); // 체크리스트에 보여지는 사용자 리스트 관리용 상태입니다.
  // 해당하는 비디오, 타입, 유저 데이터는 admin page 에서  관리 하고 있습니다.
  // index code 는 개념적인 리스트라 데이터로써는 관리 X
  // typelist, userlist 는 서로의 연관성이 없고 보여지는게 데이터와 일치하기 때문에, 데이터와 싱크를 맞춰갑니다.

  /**
   * list 의 인덱스에 해당하는 value 변경후 return
   *
   * @param {list} l
   * @param {int} ind
   * @param {object} updatedValue
   */
  const _changeObject = (l, ind, checked) => {
    const new_l = l.slice();
    if (ind > -1) {
      new_l[ind] = { ...new_l[ind], checked };
    }
    return new_l;
  };
  /**
   * current Page 가 변경되거나 업데이트 될때 상태를 변환해준다.
   */
  useEffect(() => {
    if (currentPage === ASSIGN_TYPE.VIDEO && videoData) {
      const uniqueIndexCode = {};
      const tempIndexCodes = [];
      for (let i = 0; i < videoData.length; i++) {
        if (!uniqueIndexCode[videoData[i].indexCode]) {
          uniqueIndexCode[videoData[i].indexCode] = true;
          tempIndexCodes.push({
            label: videoData[i].indexCode,
            checked: false,
            value: videoData[i].indexCode,
          });
        }
      }
      setIndexCodeList(tempIndexCodes);
    } else if (currentPage === ASSIGN_TYPE.TASK) {
      setUserList(userData);
      setTaskTypeList(taskTypeData);
    }
  }, [currentPage]);

  /**
   * 비디오 어싸인 페이지에서, 하나의 index 만 눌렀을때 하나의 인덱스가 체크 혹은 해제되고 그에 따라 해당하는 비디오가
   * 실제 데이터에서 변경 되고 videos Table에 해당하는 영상들이 보여지게 상태값을 바꿔준다.
   *
   * @param {event} e
   */
  const handleIndexChecked = (e) => {
    const videos = [];
    for (let i = 0; i < videoData.length; i++) {
      const video = videoData[i];

      if (video.indexCode === indexCodeList[e.target.value].value) {
        video.checked = e.target.checked;
        videos.push({
          value: video.id,
          label: getFileName(video.path),
          checked: video.checked,
          index: i,
        });
      }
    }
    setVideoList(videos);
    setIndexCodeList(
      _changeObject(indexCodeList, e.target.value, e.target.checked)
    );
  };
  /**
   * 비디오 어싸인 페이지에서, 전체의 index 가 체크 혹은 해지되고 모든 비디오가
   * 실제 데이터에서 변경 되고 videos Table에 해당하는 영상들의 상태값을 바꿔준다.
   *
   * @param {event} e
   */
  const handleAllIndexChecked = (e) => {
    for (let i = 0; i < videoData.length; i++) {
      videoData[i].checked = e.target.checked;
    }
    const newIndexList = indexCodeList.slice();
    for (let i = 0; i < newIndexList.length; i++) {
      newIndexList[i].checked = e.target.checked;
    }
    const newVideoList = videoList.slice();
    for (let i = 0; i < newVideoList.length; i++) {
      newVideoList[i].checked = e.target.checked;
    }
    setVideoList(newVideoList);
    setIndexCodeList(newIndexList);
  };

  /**
   * 비디오 한개만 선택 혹은 해제 할때, 비디오 데이터 반영
   * 비디오 한개 선택이 모든 인덱스를 선택되었을때, 그 해당하는 인덱스 true로 변환
   * 비디오 한개 해제시, 해당하는 인덱스 true 일경우 False 로 변환.
   * @param {Event} e
   */
  const handleVideoChecked = (e) => {
    const i = e.target.value;
    const checked = e.target.checked;
    const indexCode = videoData[videoList[i].index].indexCode;

    videoData[videoList[i].index].checked = checked;
    setVideoList(_changeObject(videoList, i, checked));
    if (!checked) {
      // 해제하는 액션 이였을때, 해당하는 인덱스 가 선택 이였을경우 해지해준다.
      for (let i = 0; i < indexCodeList.length; i++) {
        if (indexCodeList[i].checked && indexCodeList[i].value === indexCode) {
          setIndexCodeList(_changeObject(indexCodeList, i, checked));
        }
      }
    } else {
      // 선택하는 액션 이였을때, 해당하는 인덱스에 모든 비디오가 선택되있을경우, 인덱스도 선택해준다.
      let allChecked = true; //ALL checked true flag
      for (let video of videoData) {
        if (video.indexCode === indexCode && !video.checked) {
          allChecked = false;
        }
      }
      if (allChecked) {
        for (let i = 0; i < indexCodeList.length; i++) {
          if (
            !indexCodeList[i].checked &&
            indexCodeList[i].value === indexCode
          ) {
            setIndexCodeList(_changeObject(indexCodeList, i, checked));
          }
        }
      }
    }
  };
  /**
   * 모든 비디오 선택 혹은 해제,
   * 그에 해당하는 비디오 리스트 및 비디오 데이터 변경
   * 그에 해당하는 인덱스코드 리스트 변경
   * @param {event} e
   */
  const handleAllVideoChecked = (e) => {
    if (videoList.length) {
      const newVideoList = videoList.slice();
      const indexCode = videoData[videoList[0].index].indexCode;

      for (let i = 0; i < videoList.length; i++) {
        videoData[videoList[i].index].checked = e.target.checked;
        newVideoList[i].checked = e.target.checked;
      }
      setVideoList(newVideoList);
      for (let i = 0; i < indexCodeList.length; i++) {
        if (indexCodeList[i].value === indexCode) {
          setIndexCodeList(_changeObject(indexCodeList, i, e.target.checked));
        }
      }
    }
  };
  /**
   * 한명의 유저만 체크 혹은 해제 시 데이터 변경후 View update 를 위해 userlist 변경
   * @param {event} e
   */
  const handleUserChecked = (e) => {
    userData[e.target.value].checked = e.target.checked;
    setUserList(_changeObject(userData));
  };

  /**
   * 한개의 tasktype 체크 혹은 해제 시 데이터 변경후 View update 를 위해 tasktype 변경
   * @param {event} e
   */

  const handleTaskTypeChecked = (e) => {
    taskTypeData[e.target.value].checked = e.target.checked;
    setTaskTypeList(_changeObject(taskTypeData));
  };
  /**
   * 전체의 유저 체크 혹은 해제 시 데이터 변경후 View update 를 위해 userlist 변경
   * @param {event} e
   */
  const handleAllUserChecked = (e) => {
    for (let user of userData) {
      user.checked = e.target.checked;
    }
    setUserList(_changeObject(userData));
  };
  /**
   * 전체의 tasktype 체크 혹은 해제 시 데이터 변경후 View update 를 위해 tasktypeList 변경
   * @param {event} e
   */
  const handleAllTaskTypeChecked = (e) => {
    for (let taskType of taskTypeData) {
      taskType.checked = e.target.checked;
    }
    setTaskTypeList(_changeObject(taskTypeData));
  };
  /**
   * 비디오 어싸인 페이지에서 index가 클릭되면 해당하는 영상들을 보여주게 비디오리스트 상태값을 변경해주고
   * 선택된 인덱스에 selected =true 값으로 변경해준다.
   *
   * @param {event} event Event object
   */
  const handleIndexClick = (e) => {
    let target = e.target;
    while (!target.getAttribute("value") && target.parentNode) {
      //Child node Click Event 가 생성됨으로 parent 에서 value 를 찾아야 함
      target = target.parentNode;
    }
    const index = target.getAttribute("value");
    if (index) {
      const videos = [];
      for (let i = 0; i < videoData.length; i++) {
        const video = videoData[i];
        if (indexCodeList[index].value === video.indexCode) {
          videos.push({
            value: video.id,
            label: getFileName(video.path),
            checked: video.checked,
            index: i,
          });
        }
      }
      const newIndexList = indexCodeList.slice();
      newIndexList.forEach((obj) => {
        obj.selected = false;
      });
      newIndexList[index].selected = true;
      setIndexCodeList(newIndexList);
      setVideoList(videos);
    }
  };
  return (
    <StyledPageBody>
      <StyledArticle>
        <StyledB>
          {currentPage === ASSIGN_TYPE.VIDEO ? "Index" : "Task Type"}
        </StyledB>
        <StyledAssignDiv>
          {currentPage === ASSIGN_TYPE.VIDEO && (
            <CheckboxList
              title="Index Codes"
              datas={indexCodeList}
              onChange={handleIndexChecked}
              onChangeAll={handleAllIndexChecked}
              onClick={handleIndexClick}
            />
          )}
          {currentPage === ASSIGN_TYPE.TASK && (
            <CheckboxList
              title="Task Type"
              datas={taskTypeList}
              onChange={handleTaskTypeChecked}
              onChangeAll={handleAllTaskTypeChecked}
            />
          )}
        </StyledAssignDiv>
      </StyledArticle>
      <StyledArticle>
        <StyledB>
          {currentPage === ASSIGN_TYPE.VIDEO ? "Video" : "User"}
        </StyledB>
        <StyledAssignDiv>
          {currentPage === ASSIGN_TYPE.VIDEO && (
            <CheckboxList
              title="Videos"
              datas={videoList}
              onChange={handleVideoChecked}
              onChangeAll={handleAllVideoChecked}
            />
          )}
          {currentPage === ASSIGN_TYPE.TASK && (
            <CheckboxList
              title="Annotators"
              datas={userList}
              onChange={handleUserChecked}
              onChangeAll={handleAllUserChecked}
            />
          )}
        </StyledAssignDiv>
      </StyledArticle>
    </StyledPageBody>
  );
};

const StyledPageBody = styled.section`
  flex: 1;
  display: flex;
  overflow-y: hidden;
  justify-content: space-around;
`;
  
const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  width: 42vw;
  min-width: 400px;
`;
  
const StyledAssignDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 18px;
  background-color: #ffffff;
  border-radius: 20px;
  overflow-y: hidden;
`;

const StyledB = styled.b`
  margin-left: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
`;
export default AssignBody;
