import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import Input from "../atoms/NewInput.jsx";
import Button from "../atoms/NewButton.jsx";
import Selector from "../molecules/Selector.jsx";

import { validate } from "../../utils/validate.js";

import { CODES } from "./constants.js";
import {
  toFrame,
  toHHMMSSFF,
  completeHHMMSSFF,
} from "../../utils/annotation.js";

import {
  setSelected,
  checkArmes,
  insertArmesThunk,
  editOnlyArmesThunk,
  clearWarning,
} from "../../redux/ducks/phase.js";
import ModalPortal from "./ModalPortal.jsx";
import Modal from "./Modal.jsx";
import Message from "./Message.jsx";
import { normalizeString, capitalizeFirstLetter } from "../../utils";

const PhaseInputContainer = ({
  className,
  totalFrame,
  phaseState,
  frameRate,
}) => {
  const dispatch = useDispatch();
  const { selected, warning, currPhase } = phaseState;

  const [code, setCode] = useState(null);
  const [frameInputValues, setFrameInputValues] = useState({
    start: "00:00:00:00",
    end: "00:00:00:00",
  });
  const [startFrameInput, setStartFrameInput] = useState(-1);
  const [reset, setReset] = useState(0);

  const disabledIndex = useMemo(
    () =>
      currPhase === "ONLY_ARMES" ? Object.keys(CODES[currPhase]).length : null,
    [currPhase]
  );

  const updateCode = (code) => setCode(code);

  const updateInput = useCallback(
    ({ target: { name, value } }) => {
      if (validate(value, "hhmmssff")) {
        const newValues = {
          ...frameInputValues,
          [name]: value,
        };
        setFrameInputValues(newValues);
      }
    },
    [frameInputValues]
  );

  const dispatchPhase = () => {
    if (!code) return;

    const data = {
      ...frameInputValues,
      start: toFrame(frameInputValues.start, frameRate),
      end: toFrame(frameInputValues.end, frameRate),
      code: code,
    };

    if (currPhase === "ARMES") {
      dispatch(checkArmes(data));
    } else {
      dispatch(editOnlyArmesThunk(data));
    }
    setCode(null);
  };

  const resetInput = () => {
    dispatch(setSelected(null));
    setCode(null);
  };

  const inputOnKeyDown = (e) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      updateValidatedInputs();
    }
  };

  const updateValidatedInputs = () => {
    const startHHMMSSFF = completeHHMMSSFF(frameInputValues.start);
    const endHHMMSSFF = completeHHMMSSFF(frameInputValues.end);
    const startFrame = toFrame(startHHMMSSFF, frameRate);
    const endFrame = toFrame(endHHMMSSFF, frameRate);
    dispatch(setSelected({ start: startFrame, end: endFrame }));
    setFrameInputValues({
      ...frameInputValues,
      start: toHHMMSSFF(
        startFrame < totalFrame ? startFrame : totalFrame,
        frameRate
      ),
      end: toHHMMSSFF(endFrame < totalFrame ? endFrame : totalFrame, frameRate),
    });
  };

  const insertArmes = () => dispatch(insertArmesThunk(warning.data));
  const closeWarning = () => dispatch(clearWarning());

  const inputProps = [
    {
      placeholder: "Start",
      name: "start",
      value:
        frameInputValues?.start !== undefined
          ? frameInputValues.start
          : "00:00:00:00",
      onChange: updateInput,
      onKeyDown: inputOnKeyDown,
      onBlur: updateValidatedInputs,
      onFocus: (e) => e.target.setSelectionRange(0, e.target.value.length),

      autoComplete: "off",
    },
    {
      placeholder: "End",
      name: "end",
      value:
        frameInputValues?.end !== undefined ? frameInputValues.end : "00:00:00",
      onChange: updateInput,
      onKeyDown: inputOnKeyDown,
      onBlur: updateValidatedInputs,
      onFocus: (e) => e.target.setSelectionRange(0, e.target.value.length),

      autoComplete: "off",
    },
  ];

  const renderInputs = () =>
    inputProps.map((item, i) => (
      <StyledInput
        key={"" + reset + i}
        disabled={currPhase === "ONLY_ARMES"}
        {...item}
      />
    ));

  const makeString = (obj) => {
    const { code, start, end } = obj;
    const startHHMMSSFF = toHHMMSSFF(start, frameRate);
    const endHHMMSSFF = toHHMMSSFF(end, frameRate);

    console.log("obj: ", obj);
    console.log("index: ", obj.index);

    return `(${code}) ${startHHMMSSFF} - ${endHHMMSSFF}`;
  };

  const getMessage = () => {
    const msg = warning.list.map((curr) => makeString(curr)).join(", ");

    return `The section you filled in, ${makeString(
      warning.data
    )} is overlapped with ${msg}. Do you want to change it?`;
  };

  const warningModal = useMemo(() => {
    if (!warning.data) return;

    return {
      type: "warning",
      message: getMessage(),
      actions: [
        {
          label: "Yes",
          theme: "secondary",
          onClick: insertArmes,
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: closeWarning,
          cancelOnEsc: true,
        },
      ],
    };
  }, [warning]);

  useEffect(() => {
    if (selected) {
      selected.start = !selected.start
        ? 0
        : selected.start < totalFrame
        ? selected.start
        : totalFrame;
      selected.end = !selected.end
        ? 0
        : selected.end < totalFrame
        ? selected.end
        : totalFrame;
      selected.start >= selected.end
        ? setStartFrameInput(0)
        : setStartFrameInput(selected.end);
      setFrameInputValues({
        ...selected,
        start: toHHMMSSFF(selected.start, frameRate),
        end: toHHMMSSFF(selected.end, frameRate),
      });
    } else {
      setFrameInputValues({
        start: toHHMMSSFF(startFrameInput + 1, frameRate),
        end: "00:00:00:00",
      });
    }
    setCode(selected?.code);
    setReset(reset + 1);
  }, [selected]);

  useEffect(() => {
    const { data, list } = warning;
    if (data && !list?.length) dispatch(insertArmesThunk(warning.data));

    setFrameInputValues({
      start: toHHMMSSFF(startFrameInput + 1, frameRate),
      end: "00:00:00:00",
    });
    setReset(reset + 1);
  }, [warning]);

  useEffect(() => {
    setCode(null);
  }, [currPhase]);

  return (
    <>
      <Wrapper className={className}>
        <StyledContent>
          <StyledInputWrapper>
            {renderInputs()}
            <StyledTitle>
              {capitalizeFirstLetter(normalizeString(currPhase))}
            </StyledTitle>
            <StyledButtonWrapper>
              {(currPhase === "ONLY_ARMES" && selected?.code === 0) ||
              (currPhase === "ARMES" && selected?.code) ? (
                <>
                  <Button label="Cancel" size="small" onClick={resetInput} />
                  <Button
                    label="Save"
                    theme="secondary"
                    size="small"
                    onClick={dispatchPhase}
                  />
                </>
              ) : (
                currPhase === "ARMES" && (
                  <Button
                    label="Add"
                    theme="secondary"
                    size="regular"
                    onClick={dispatchPhase}
                  />
                )
              )}
            </StyledButtonWrapper>
          </StyledInputWrapper>
          <Selector
            contents={CODES["ARMES"]}
            disabledIndex={disabledIndex}
            onClick={updateCode}
            active={code}
            clickable={
              currPhase === "ARMES" ||
              (currPhase === "ONLY_ARMES" && selected?.code === 0)
            }
          />
        </StyledContent>
      </Wrapper>
      {warning?.list?.length > 0 && (
        <ModalPortal>
          <Modal>
            <Message {...warningModal} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  height: 120px;
  max-height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  line-height: 30px;
  font-size: 14px;
  padding: 0 40px;
  box-sizing: border-box;
  font-weight: 500;
`;

const StyledContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 40px;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  height: 50%;
  align-items: center;
`;

const StyledInput = styled(Input)`
  font-size: 10px;

  :not(:first-of-type) {
    margin-left: 32px;
  }
`;

const StyledButtonWrapper = styled.div`
  margin: auto 0 auto auto;
  button:not(:first-child) {
    margin-left: 15px;
  }
`;

export default PhaseInputContainer;
