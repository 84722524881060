import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../components/atoms/NewButton.jsx";
import CheckboxWithLabel from "../components/molecules/CheckboxWithLabel.jsx";
import { updateUserThunk } from "../redux/actions.js";
import { dummyTerms } from "./constants.js";
import styled from "styled-components";

const Agreement = ({ isAgreed, location }) => {
  const history = useHistory();

  const { from } = location.state || { from: "/" };
  if (isAgreed) history.replace(from);

  const dispatch = useDispatch();
  const [accepted, setAccepted] = useState(false);

  const toggleCb = () => setAccepted(!accepted);

  const onClickNext = () => {
    history.push({ pathname: "/password" });
    dispatch(updateUserThunk("isAgreed", true));
  };

  const action = { label: "Next", onClick: onClickNext, disabled: !accepted };

  const labelStyle = { fontSize: "10px" };

  return (
    <Wrapper>
      <StyledContent>
        <StyledTitle>Terms and Conditions</StyledTitle>
        <div>
          <StyledParagraph>{dummyTerms}</StyledParagraph>
          <CheckboxWithLabel
            name="accepted"
            checked={accepted}
            onChange={toggleCb}
            labelText="Accept terms and conditions."
            shape="round"
            labelStyle={labelStyle}
          />
        </div>
        <Button theme="secondary" {...action} />
      </StyledContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 1280px;
  height: calc(100% - 80px);
  background-color: #f1f1f1;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 672px;
  height: 500px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 63px 176px 82px;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const StyledParagraph = styled.p`
  height: 200px;
  font-size: 10px;
  text-align: justify;
  overflow-x: scroll;
  margin: 0 0 25px 0;
`;

export default Agreement;
