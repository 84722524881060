import { checkABConflict, insertActiveBleeding, remove } from "../../utils";
import { saveAnnotation } from "../../api";

// Actions
const SET_ID = "activeBleeding/SET_ID";
const SET_SELECTED = "activeBleeding/SET_SELECTED";
const SET_ANNOTATOR_BLEEDING = "activeBleeding/SET_ANNOTATOR_BLEEDING";
const SET_INSPECTOR_BLEEDING = "activeBleeding/SET_INSPECTOR_BLEEDING";
const CHECK_BLEEDING = "activeBleeding/CHECK_BLEEDING";
const RESET_BLEEDING = "activeBleeding/RESET_BLEEDING";
const CLEAR_WARNING = "activeBleeding/CLEAR_WARNING";

const initialState = {
  id: { annotator: null, inspector: null },
  selected: null,
  annotatorBleeding: [],
  inspectorBleeding: [],
  warning: { data: null, list: [] },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ID:
      return { ...state, id: action.payload };
    case SET_SELECTED:
      const selected = action.payload ? { ...state.selected, ...action.payload } : null;
      return { ...state, selected: selected };

    case SET_ANNOTATOR_BLEEDING:
      return {
        ...state,
        annotatorBleeding: action.payload,
        selected: null,
        warning: initialState.warning,
      };
    case SET_INSPECTOR_BLEEDING:
      return {
        ...state,
        inspectorBleeding: action.payload,
        selected: null,
        warning: initialState.warning,
      };

    case CHECK_BLEEDING:
      return {
        ...state,
        warning: {
          data: action.payload,
          list: checkABConflict(state.inspectorBleeding, action.payload),
        },
      };
    case CLEAR_WARNING:
      return { ...state, warning: initialState.warning };
    case RESET_BLEEDING:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setSelected(payload) {
  return { type: SET_SELECTED, payload };
}

export const importAnnotatorBleeding = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().activebleeding;
    const { status } = await saveAnnotation(state.id.annotator, payload);
    if (status === 200) {
      dispatch(setAnnotatorBleeding(payload));
    }
  } catch (err) {
    console.log(err);
  }
};

export function setAnnotatorBleeding(payload) {
  return { type: SET_ANNOTATOR_BLEEDING, payload };
}

export function setInspectorBleeding(payload) {
  return { type: SET_INSPECTOR_BLEEDING, payload };
}

export function check(payload) {
  return { type: CHECK_BLEEDING, payload };
}

export function resetBleeding() {
  return { type: RESET_BLEEDING };
}

export function setId(payload) {
  return { type: SET_ID, payload };
}

export function clearWarning() {
  return { type: CLEAR_WARNING };
}

export const insertThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().activebleeding;
    const bleedingData = {
      x: payload.x,
      y: payload.y,
      start: payload.start,
      end: payload.end,
      code: payload.code,
    };
    const bleeding = insertActiveBleeding(state.inspectorBleeding, bleedingData);

    const { status } = await saveAnnotation(state.id.inspector, bleeding);
    if (status === 200) {
      dispatch(setInspectorBleeding(bleeding));
    }
  } catch (err) {
    console.error(err);
  }
};

export const removeAnnotatorBleeding = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().activebleeding;
    const bleeding = remove(state.annotatorBleeding, payload);
    const { status } = await saveAnnotation(state.id.annotator, bleeding);
    if (status === 200) {
      dispatch(setAnnotatorBleeding(bleeding));
    }
  } catch (err) {
    console.log(err);
  }
};

export const removeInspectorBleeding = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().activebleeding;
    const bleeding = remove(state.inspectorBleeding, payload);

    const { status } = await saveAnnotation(state.id.inspector, bleeding);
    if (status === 200) {
      dispatch(setInspectorBleeding(bleeding));
    }
  } catch (err) {
    console.log(err);
  }
};
