const setCookie = (key, value) => (document.cookie = key + "=" + value);

const getCookie = (key) => {
  const name = key + "=";
  const cookies = document.cookie.split(";");
  const cookie = cookies.find((row) => row.trim().startsWith(name));
  return cookie ? cookie.slice(name.length) : "";
};

const deleteCookie = (key) =>
  (document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`);

export { setCookie, getCookie, deleteCookie };
