import React from "react";
import styled from "styled-components";

const TableCell = ({ children, align, width, compact, fontSize }) => {
  return (
    <StyledTd align={align} width={width} compact={compact} fontSize={fontSize}>
      {children}
    </StyledTd>
  );
};

TableCell.defaultProps = {
  align: "left",
  width: "initial",
  compact: false,
  fontSize: "12px"
};

export const StyledTd = styled.td`
  border-bottom: 1px solid #dddddd;

  white-space: nowrap;
  font-size: ${({ fontSize }) => fontSize};
  text-align: ${({ align }) => align};

  width: ${({ compact, width }) => (compact ? "fit-content" : width)};

  padding: 15px 45px;
  padding-left: ${({ compact }) => (compact ? "0" : "45px")};
`;

export default TableCell;
