import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import DualTab from "../../molecules/AnnotationListTab/DualTab";
import AnnotationListBody from "../../molecules/AnnotationListBody";
import {
  setSelected,
  removeAnnotatorBleeding,
  removeInspectorBleeding,
} from "../../../redux/ducks/activebleeding.js";
import ModalPortal from "../ModalPortal.jsx";
import Modal from "../Modal.jsx";
import Message from "../Message.jsx";
import { getSeperatedFrames } from "./utils";

const ActiveBleedingListContainer = ({
  onClickListItem,
  bleedingState,
  annotationType,
  frameRate,
}) => {
  const dispatch = useDispatch();
  const { selected, annotatorBleeding, inspectorBleeding } = bleedingState;

  const [msg, setMsg] = useState();
  const [reset, setReset] = useState({ anno: 0, ins: 0 });

  const onClickAnnotatorRow = (row) => {
    dispatch(setSelected({ ...row, currentTab: 1 }));
    onClickListItem(row);
  };

  const onClickInspectorRow = (row) => {
    dispatch(setSelected({ ...row, currentTab: 0 }));
    onClickListItem(row);
  };

  const onClickDeleteAnnotator = (row) => {
    const deleteWarningMsg = {
      type: "warning",
      message: "Are you sure you want to delete this annotation?",
      actions: [
        {
          label: "Yes",
          onClick: () => {
            const bleedingData = {
              x: row.x,
              y: row.y,
              code: row.code,
              start: row.start,
              end: row.end,
            };
            dispatch(removeAnnotatorBleeding(bleedingData));
            setMsg(null);
          },
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: () => setMsg(null),
          type: "gray",
          cancelOnEsc: true,
        },
      ],
    };
    setMsg(deleteWarningMsg);
  };

  const onClickDeleteInspector = (row) => {
    const deleteWarningMsg = {
      type: "warning",
      message: "Are you sure you want to delete this annotation?",
      actions: [
        {
          label: "Yes",
          theme: "secondary",
          onClick: () => {
            const bleedingData = {
              x: row.x,
              y: row.y,
              code: row.code,
              start: row.start,
              end: row.end,
            };
            dispatch(removeInspectorBleeding(bleedingData));
            setMsg(null);
          },
          submitOnEnter: true,
        },
        {
          label: "No",
          onClick: () => setMsg(null),
          type: "gray",
          cancelOnEsc: true,
        },
      ],
    };
    setMsg(deleteWarningMsg);
  };

  useEffect(() => {
    if (!selected) setReset({ anno: reset.anno + 1, ins: reset.ins + 1 });
    if (selected?.currentTab === 0) {
      setReset({ ...reset, anno: reset.anno + 1 });
    } else if (selected?.currentTab === 1) {
      setReset({ ...reset, ins: reset.ins + 1 });
    }
  }, [selected]);

  return (
    <>
      <div className="active-bleeding-list-container">
        <div className="container-header">
          <DualTab
            tabs={["Inspector", "Annotator"]}
            nRows={[inspectorBleeding.length, annotatorBleeding.length]}
          />
        </div>
        <div className="container-body">
          <AnnotationListBody
            taskType={annotationType}
            rows={inspectorBleeding}
            onClickRow={onClickInspectorRow}
            onClickDelete={onClickDeleteInspector}
            resetSelection={() => dispatch(setSelected(null))}
            reset={reset.ins}
            frameRate={frameRate}
            isActiveBleeding={true}
          />
          <AnnotationListBody
            taskType={annotationType}
            rows={getSeperatedFrames(frameRate, annotatorBleeding).map(
              (data) => {
                if (data === null) {
                  return null;
                } else {
                  return {
                    ...data,
                    background: data.code === 1 ? "#D8BFD8" : "#A8C8F9",
                  };
                }
              }
            )}
            onClickRow={onClickAnnotatorRow}
            onClickDelete={onClickDeleteAnnotator}
            resetSelection={() => dispatch(setSelected(null))}
            reset={reset.anno}
            frameRate={frameRate}
            isActiveBleeding={true}
            isAnnotator={true}
          />
        </div>
      </div>
      {msg && (
        <ModalPortal>
          <Modal>
            <Message {...msg} />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

export default ActiveBleedingListContainer;
