import React, { useEffect, useMemo } from "react";
import { Switch, Route, Redirect, useParams, useHistory } from "react-router-dom";

import AnnotatorMain from "../pages/AnnotatorMain.jsx";
import EditPassword from "../pages/EditPassword.jsx";
import Agreement from "../pages/Agreement.jsx";
import PhaseAnnotation from "../pages/PhaseAnnotation.jsx";
import BleedingAnnotation from "../pages/BleedingAnnotation.jsx";
import ActiveBleeding from "../pages/ActiveBleedingAnnotation";
import CholeSkillAssessment from "../pages/CholeSkillAssessment";
import TemporalAnnotation from "../pages/TemporalAnnotation";
import ActiveBleedingIntervention from "../pages/ActiveBleedingIntervention";
import Grading from "../pages/Grading";
import ABFrame from "../pages/ActiceBleedingFrame";
import FrameSelection from "../pages/FrameSelection";
import PresenceAnnotation from "../pages/PresenceAnnotation";
import GastrectomyAssessment from "../pages/GastrectomyAssessment";

import { useRequest } from "../hooks";

const AnnotationRoute = () => {
  const history = useHistory();
  const { taskId } = useParams();
  const [task, getTask] = useRequest();

  const annotationType = useMemo(() => task?.data?.annotationType, [task]);

  const getComponent = (annotationType) => {
    switch (annotationType) {
      case "PHASE":
        return <PhaseAnnotation task={task} />;
      case "BLOOD_STAINED_TISSUE":
      case "BLOOD_COLLECTED":
      case "HSDB_SELECTION":
        return <BleedingAnnotation task={task} />;
      case "ORGAN":
      case "AI_EMPOWERED":
        return <TemporalAnnotation task={task} />;
      case "ACTIVE_BLEEDING":
        return <ActiveBleeding task={task} />;
      case "INTERVENTION":
        return <ActiveBleedingIntervention task={task} />;
      case "GRADING":
        return <Grading task={task} />;
      case "AB_FRAME":
        return <ABFrame task={task} />;
      case "CHOLEC_SKILL_ASSESSMENT":
        return <CholeSkillAssessment task={task} />;
      case "FRAME_SELECTION":
        return <FrameSelection task={task} />;
      case "PRESENCE":
      case "INSTRUMENTS_PRESENCE":
      case "ORGAN_PRESENCE":
        return <PresenceAnnotation task={task} />;
      case "GASTRECTOMY_GEARS":
      case "GASTRECTOMY_GOALS":
        return <GastrectomyAssessment task={task} />;

      default:
        return <Redirect to="/" />;
    }
  };

  useEffect(() => {
    const request = {
      method: "GET",
      url: `/video-tasks/${taskId}`,
    };
    getTask(request);
  }, []);

  // TODO api로 변경?
  useEffect(() => {
    if (task.type === "failure" || task?.data?.status === "DONE") return history.replace("/");
  }, []);

  return annotationType ? getComponent(annotationType) : <></>;
};

const AnnotatorRoute = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/home" />} />
      <Route path="/home" component={AnnotatorMain} />
      <Route exact path="/password" component={EditPassword} />
      <Route exact path="/agreement" component={Agreement} />
      <Route path="/video-tasks/:taskId" component={AnnotationRoute} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default AnnotatorRoute;
