import React from "react";
import styled from "styled-components";

const TableHead = ({ children, ...rest }) => {
  return <StyledThead {...rest}>{children}</StyledThead>;
};

const StyledThead = styled.thead`
  display: block;
  width: 100%;
  table-layout: fixed;

  td {
    border-bottom: unset;
    font-size: 14px;
    font-weight: 700;
  }
`;

export default React.memo(TableHead);
