import React from "react";
import styled from "styled-components";

import UserIcon from "../../assets/profile-annotator-wt.svg";

const Account = ({ image, username, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <StyledProfileImg src={image ? image : UserIcon} alt="profile" />
      <StyledUsername>{username}</StyledUsername>
    </Wrapper>
  );
};

Account.defaultProps = {
  image: UserIcon,
};

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledProfileImg = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto;
  margin-right: 10px;
`;

const StyledUsername = styled.div`
  color: white;
  height: fit-content;
  margin: auto;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
`;

export default Account;
