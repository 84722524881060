import React, { useState, useEffect } from "react";
import styled from "styled-components";

import AnnotationListTab from "../molecules/AnnotationListTab";
import GradingListBody from "../molecules/AnnotationListBody/GradingListBody";

const GradingListContainer = ({ onClickListItem, annotationType, frameRate, grading, resetSelection }) => {
  const [reset, setReset] = useState(0);

  const onClickRow = (row, i) => {
    //TODO: selected 추가
    onClickListItem(row, i);
  };

  useEffect(() => {
    if (grading.currentIndex === -1) {
      setReset(reset + 1);
    }
  }, [grading]);
  const listHeader = ["No", "Time", "Vessel", "Cause", "Char.", "Remedy"];

  const renderListHeader = () => {
    return (
      <StyledUl>
        {listHeader.map((list) => (
          <StyledLi>{list}</StyledLi>
        ))}
      </StyledUl>
    );
  };

  return (
    <>
      <Container>
        <StyledContainerHeader>
          <AnnotationListTab tabs={[annotationType]} active={`${annotationType}_INDIVIDUAL`} />
          <StyledContainerBody>
            {renderListHeader()}
            <GradingListBody
              taskType="Grading"
              onClickRow={onClickRow}
              reset={reset}
              frameRate={frameRate}
              rows={grading.grading}
              resetSelection={resetSelection}
            />
          </StyledContainerBody>
        </StyledContainerHeader>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 95%;
  min-height: 820px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 12px rgba(71, 77, 81, 0.2);
  overflow: hidden;
`;

const StyledContainerHeader = styled.div`
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #dddddd;
`;

const StyledContainerBody = styled.div`
  height: 820px;
  overflow: scroll;
`;

const StyledUl = styled.ul`
  display: flex;
  list-style: none;
  font-size: 10px;
  width: 100%;
  padding-left: 15px;
`;

const StyledLi = styled.li`
  float: left;
  border: 1px solid;
  font-size: 14px;
  white-space: normal;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:first-child {
    width: 20px;
  }

  &:nth-child(2) {
    width: 130px;
  }
  &:nth-child(3) {
    width: 40px;
  }
  &:nth-child(4) {
    width: 45px;
  }
  &:nth-child(5) {
    width: 50px;
  }
`;

export default GradingListContainer;
