import { checkPresenceConflict, remove, insertPresence, editPresence } from "../../utils";
import { saveAnnotation } from "../../api";

// Actions
const SET_ID = "presence/SET_ID";
const SET_SELECTED = "presence/SET_SELECTED";
const SET_PRESENCE = "presence/SET_PRESENCE";
const CHECK_PRESENCE = "presence/CHECK_PRESENCE";
const RESET_PRESENCE = "presence/RESET_PRESENCE";
const CLEAR_WARNING = "presence/CLEAR_WARNING";

const initialState = {
  id: null,
  selected: null,
  presence: [],
  warning: { data: null, list: [] },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ID:
      return { ...state, id: action.payload };
    case SET_SELECTED:
      const selected = action.payload
        ? { ...state.selected, ...action.payload }
        : null;
      return { ...state, selected: selected };
    case SET_PRESENCE:
      return {
        ...state,
        presence: action.payload,
        selected: null,
        warning: initialState.warning,
      };
    case CHECK_PRESENCE:

    console.log(state.presence);
    console.log(action.payload);
    console.log("list: ", checkPresenceConflict(state.presence, action.payload));

      return {
        ...state,
        warning: {
          data: action.payload,
          list: checkPresenceConflict(state.presence, action.payload),
        }, 
      };
    case CLEAR_WARNING:
      return { ...state, warning: initialState.warning };
    case RESET_PRESENCE:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setSelected(payload) {
  return { type: SET_SELECTED, payload };
}

export const importPresence = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().presence;
    const { status } = await saveAnnotation(state.id, payload);
    if (status === 200) {
      dispatch(setPresence(payload));
    }
  } catch (err) {
    console.error(err);
  }
};
export function setPresence(payload) {
  return { type: SET_PRESENCE, payload };
}

export function check(payload) {
  return { type: CHECK_PRESENCE, payload };
}

export function resetPresence() {
  return { type: RESET_PRESENCE };
}

export function setId(payload) {
  return { type: SET_ID, payload };
}

export function clearWarning() {
  return { type: CLEAR_WARNING };
}
export const editThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().presence;
    const presence = editPresence(state.presence, payload);

    const { status } = await saveAnnotation(state.id, presence);
    if (status === 200) {
      dispatch(setPresence(presence));
    }
  } catch (err) {
    console.log(err);
  }
};

export const insertThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().presence;
    const presence = insertPresence(state.presence, payload);

    const { status } = await saveAnnotation(state.id, presence);
    if (status === 200) {
      dispatch(setPresence(presence));
    }
  } catch (err) {
    console.log(err);
  }
};

export const removeThunk = (payload) => async (dispatch, getState) => {
  try {
    const state = getState().presence;
    const presence = remove(state.presence, payload);

    const { status } = await saveAnnotation(state.id, presence);
    if (status === 200) {
      dispatch(setPresence(presence));
    }
  } catch (err) {
    console.log(err);
  }
};