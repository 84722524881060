const Links = {
  annotator: [{ name: "Home", url: "/home" }],
  admin: [
    { name: "Members", url: "/members" },
    { name: "Assign", url: "/assign" },
    { name: "Status", url: "/status" },
    { name: "Video Update", url: "/video-update" },
  ],
};

const ASSIGN_TYPE = {
  VIDEO: 0,
  TASK: 1,
};

const CODES = {
  // Omentum
  // Liver
  // Spleen
  // GallBladder
  // Pancreas
  // Stomach
  // Vessel
  ORGAN: {
    1: "Omentum",
    2: "Liver",
    3: "Spleen",
    4: "Gallbladder",
    5: "Pancreas",
    6: "Stomach",
    7: "Vessel",
  },
  NIR_OOB: {
    1: "CAMERA OUT",
    2: "NRS CAMERA",
  },
  ONLY_ARMES: {
    1: "1 Trocar insertion",
    2: "2 Docking",
    3: "3 Division of less omentum up to the right side of the esophagus",
    4: "4 Liver retraction",
    5: "5 Partial (or total) omentectomy",
    6: "6 Ligation of left gastroepiploic vessels",
    7: "7 Clearance of soft tissues along the greater curvature",
    8: "8 Ligation of Right gastroepiploic vein",
    9: "9 Ligation of right gastroepiploic artery",
    10: "10 Creation of window for duodenal transection",
    11: "11 Duodenal transection",
    12: "12 Ligation of right gastric artery",
    13: "13 Dissection of LN stations 12a",
    14: "14 Dissection of LN station 8 and 9",
    15: "15 Dissection of LN station 7 and ligation of left gastric artery",
    16: "16 Dissection of LN station 11p",
    17: "17 Clearance of soft tissue along the lesser curvature",
    18: "18 Gastric transection",
    19: "19 Harvesting resected specimen into Endo bag",
    20: "20 Anastomosis",
    21: "21 Retrieval of specimen",
  },
  ARMES: {
    1: "1 Trocar insertion",
    2: "2 Docking",
    3: "3 Division of less omentum up to the right side of the esophagus",
    4: "4 Liver retraction",
    5: "5 Partial (or total) omentectomy",
    6: "6 Ligation of left gastroepiploic vessels",
    7: "7 Clearance of soft tissues along the greater curvature",
    8: "8 Ligation of Right gastroepiploic vein",
    9: "9 Ligation of right gastroepiploic artery",
    10: "10 Creation of window for duodenal transection",
    11: "11 Duodenal transection",
    12: "12 Ligation of right gastric artery",
    13: "13 Dissection of LN stations 12a",
    14: "14 Dissection of LN station 8 and 9",
    15: "15 Dissection of LN station 7 and ligation of left gastric artery",
    16: "16 Dissection of LN station 11p",
    17: "17 Clearance of soft tissue along the lesser curvature",
    18: "18 Gastric transection",
    19: "19 Harvesting resected specimen into Endo bag",
    20: "20 Anastomosis",
    21: "21 Retrieval of specimen",
    30: "30 Adhesiolysis",
    31: "31 Housekeeping",
    32: "32 Clean camera",
    33: "33 Junk",
    34: "34 Other procedure",
    35: "35 Event",
  },
  INTERVENTION: {
    1: "Harmonic",
    2: "MarylandBipolar",
    3: "Cadiere",
    4: "ClipApplier",
    5: "Gauze",
    6: "VesselSealer",
    7: "R_Scissors",
    8: "Forceps",
    9: "Bipolar",
    10: "Sonicsion",
    11: "Hook",
    12: "L_Scissors",
    13: "Surgicel",
    14: "Suture",
    15: "Force Bipolar",
    16: "Suction-Irrigation",
  },
  CHOLEC_SKILL_ASSESSMENT: {
    1: "Preparation",
    2: "Calot Triangle Dissection",
    3: "Clipping and Cutting",
    4: "Gallbladder Dissection",
    5: "Gallbladder Packaging",
    6: "Cleaning and Coagulation",
    7: "Gallbladder Retraction",
    30: "30-A Adhesiolysis : release of adhesions",
    31:"31-H Housekeeping: Wiping of fluid/blood , floseal application and drain insertion",
    32:"32-C Clean camera: Time need to remove the camera cleaning and insert it again",
    33:"33-J Junk : No movement of all instruments over 10 seconds",
    34:"34-O Other procedure (Ex: PTGBD removal, liver biopsy)",
    35:"35-E Event : Unexpected complication during the procedure which required intervention (e.g: bleeding-coagulation, bowel injury-suture)"

  },
};

const Intervention = ["None", "Grasping", "Coagulation", "Compression", "Packing", "Clipping", "Tying"];

const GradingData = {
  site: {
    description: "",
    vessel_bleeding: null,
  },
  cause: {
    cause: "",
    other: "",
  },
  characteristics: {},
  identical: "",
  comment: "",
  remedy: [
    { id: 0, method: "", other: "", effectiveness: null },
    { id: 1, method: "", other: "", effectiveness: null },
    { id: 2, method: "", other: "", effectiveness: null },
    { id: 3, method: "", other: "", effectiveness: null },
    { id: 4, method: "", other: "", effectiveness: null },
  ],
};

export { Links, ASSIGN_TYPE, CODES, Intervention, GradingData };
