import React from "react";
import { useHistory } from "react-router-dom";
import NavBar from "../components/organisms/NavBar.jsx";
import { useValidation } from "../hooks";
import Form from "../components/molecules/Form.jsx";
import { ReactComponent as ProfileIcon } from "../assets/profile-annotator-bk.svg";
import styled from "styled-components";

const Login = ({ authenticatedAs, login, location }) => {
  const history = useHistory();
  const { from } = location.state || { from: "/" };
  if (authenticatedAs) history.replace(from);

  const [onChangeEmail, email, isValidEmail] = useValidation("email");
  const [onChangePassword, password, isValidPassword] = useValidation(
    "password"
  );

  const onSubmit = (e) => {
    e.preventDefault();
    login({ email, password });
    // if (isValidEmail && isValidPassword) {
    //   login({ email, password });
    // }
    // onChangeEmail();
    // onChangePassword();
  };

  const formActions = {
    onSubmit: { label: "Sign In", action: onSubmit },
  };

  const items = [
    {
      label: "Email",
      value: email || "",
      onChange: onChangeEmail,
      // isValid: isValidEmail,
      placeholder: "hutom@gmail.com",
    },
    {
      label: "Password",
      type: "password",
      value: password || "",
      onChange: onChangePassword,
      // isValid: isValidPassword,
      placeholder: "********",
    },
  ];

  return (
    <>
      <NavBar />
      <Wrapper>
        <Container>
          <ProfileIcon />
          <StyledTitle>Sign In</StyledTitle>
          <StyledDescription>Enter your credential below</StyledDescription>
          <Form formActions={formActions} items={items} />
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 45px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  width: 670px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const StyledDescription = styled.div`
  font-size: 12px;
  margin-bottom: 30px;
`;

export default Login;
