import React, { useState } from "react";
import styled from "styled-components";

const GradingCharacteristics = ({ data, onClick }) => {
  console.log(data);
  return (
    <Wrapper>
      <Container>
        <StyledRadioBtn
          id="brisk"
          data-id="isBrisk"
          data-nested="isBrisk"
          value=""
          name="isBrisk"
          type="radio"
          checked={data?.isBrisk === 1}
        />
        <StyledLabel htmlFor="brisk">Brisk</StyledLabel>
        <div style={{ display: "flex" }}>
          <StyledRadioBtn
            id="non-pulsatile"
            name="isPulsatile"
            type="radio"
            data-id="isPulsatile"
            value="0"
            onChange={onClick}
            checked={data?.isPulsatile === 0}
          />
          <StyledItemLabel htmlFor="non-pulsatile" data-text="non-Pulsatile">
            Non-Pulsatile
          </StyledItemLabel>
          <StyledRadioBtn
            id="pulsatile"
            name="isPulsatile"
            type="radio"
            data-id="isPulsatile"
            value="1"
            onChange={onClick}
            checked={data?.isPulsatile === 1}
          />
          <StyledItemLabel htmlFor="pulsatile" data-text="Pulsatile">
            Pulsatile
          </StyledItemLabel>
        </div>
      </Container>
      <Container style={{ marginLeft: "40px" }}>
        <StyledRadioBtn
          id="oozing"
          data-id="oozing"
          data-nested="oozing"
          value="isOozing"
          name="isOozing"
          type="radio"
          onChange={onClick}
          checked={data?.isOozing === 1}
        />
        <StyledLabel htmlFor="oozing">Oozing</StyledLabel>
        <div style={{ display: "flex" }}>
          <StyledCheckBox
            id="micro-bleeding"
            name="isMicroBleeding"
            type="checkbox"
            data-id="isMicroBleeding"
            value={data?.isMicroBleeding}
            checked={data?.isMicroBleeding === 1}
            onChange={onClick}
          />
          <StyledItemLabel htmlFor="micro-bleeding" data-text="Micro-bleeding">
            M
          </StyledItemLabel>
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLabel = styled.label`
  width: fit-content;
  text-align: center;
  font-size: 16px;
  display: block;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  border: 2px solid black;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const StyledItemLabel = styled.label`
  min-width: 25px;
  min-height: 25px;
  text-align: center;
  font-size: 16px;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-color: #30363b;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  margin-left: 15px;
  position: relative;

  &:before {
    content: "";
    display: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #1a1a1a;
    position: absolute;
    bottom: 30px;
    left: 13px;
    z-index: 8;
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
  }

  &:after {
    display: none;
    content: attr(data-text);
    position: absolute;
    bottom: 35px;
    left: 0px;
    padding: 5px 8px;
    background: #1a1a1a;
    color: #fff;
    z-index: 9;
    font-size: 0.75em;
    height: 18px;
    line-height: 18px;
    border-radius: 3px;
    white-space: nowrap;
    word-wrap: normal;
  }

  &:hover:before,
  &:hover:after {
    display: block;
  }
`;

const StyledRadioBtn = styled.input`
  display: none;

  &:checked + label {
    background: #00a999;
    color: #ffffff;
  }
`;

const StyledCheckBox = styled.input`
  display: none;

  &:checked + label {
    background: #00a999;
    color: #ffffff;
  }
`;

export default GradingCharacteristics;
