import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Login from "../pages/Login.jsx";

import AuthRoute from "./AuthRoute.jsx";
import HomeRoute from "./HomeRoute.jsx";
import { useRequest } from "../hooks";
import { getUserState } from "../redux/selectors";
import { verifyUserAsyncThunk } from "../redux/actions";

const Routes = () => {
  const user = useSelector(getUserState);
  const [result, send] = useRequest();
  const dispatch = useDispatch();

  useEffect(() => {
    if (result.type === "failure") return alert(result.error);
    if (!result.data) return;

    dispatch(verifyUserAsyncThunk());
  }, [result, dispatch]);

  const login = (data) => {
    const request = {
      method: "POST",
      url: `/login`,
      data,
    };
    send(request);
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => (
            <Login authenticatedAs={user} login={login} {...props} />
          )}
        />
        <AuthRoute authenticatedAs={user} path="/" component={HomeRoute} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
