import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Selector from "./Selector";
import Input from "../atoms/NewInput";
import { GRADING } from "./constants";

const GradingCause = ({ onChange, onClick, data, annotationType, onKeyDown }) => {
  const cause = data ? data : "";

  const [activeOther, setActiveOther] = useState(false);
  useEffect(() => {
    console.log("DATA:", data);

    let isOther = false;
    if (data) {
      isOther = true;

      for (const [key, value] of Object.entries(GRADING.Cause)) {
        console.log(value);
        if (value === data) {
          isOther = false;
        }
      }
    }
    if (activeOther !== isOther) {
      setActiveOther(isOther);
    }
  }, [data]);
  const handleShowOtherInput = (e) => {
    setActiveOther(!activeOther);
    onChange(e);
  };

  const handleClickCause = (e) => {
    onClick(e);
    setActiveOther("");
  };

  return (
    <>
      <StyledInputWrapper>
        <StyledTitle>Cause</StyledTitle>
        <div style={{ width: "fit-content", display: "flex" }}>
          <Selector contents={GRADING["Cause"]} onClick={handleClickCause} clickable annotationType={annotationType} active={cause} />
          <StyledOtherBtn onClick={handleShowOtherInput} active={activeOther}>
            O
          </StyledOtherBtn>
        </div>
      </StyledInputWrapper>
      {activeOther && (
        <StyledInputWrapper>
          <StyledTitle>Other</StyledTitle>
          <StyledInput
            id="grading-input"
            data-id="cause"
            data-nested="cause"
            name="other"
            value={cause}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        </StyledInputWrapper>
      )}
    </>
  );
};

const StyledInputWrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  display: flex;
`;

const StyledTitle = styled.div`
  width: fit-content;
  border: 2px solid black;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
`;

const StyledInput = styled(Input)`
  width: 500px;
  border-radius: 5px;
`;

const StyledOtherBtn = styled.button`
  min-width: 25px;
  min-height: 25px;
  margin-left: 15px;
  background-color: ${({ active }) => (active ? "#00A999" : "#30363b")};
  color: #ffffff;
  font-size: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  &:hover {
    opacity: 0.5;
  }
  &:active {
    background-color: #889198;
  }
`;

export default GradingCause;
