import React from "react";
import PropTypes from "prop-types";
import { DisappearedLoading } from "react-loadingg";
import Modal from "./modal/index";

import styled from "styled-components";

const LoadingModal = (props) => {
  const { show, message, ...rest } = props;
  return (
    <Modal show={show} transition={true} style={{ zIndex: 99999 }}>
      <Container {...rest}>
        <SpinnerWrapper>
          {message && <Message>{message}</Message>}
          <DisappearedLoading style={{ position: "relative", width: 94 }} color="#30d9c3" />
        </SpinnerWrapper>
      </Container>
    </Modal>
  );
};

export default LoadingModal;

LoadingModal.propTypes = {
  show: PropTypes.bool.isRequired,
};

const Message = styled.p`
  font-size: large;
  font-weight: bold;
`

export const SpinnerWrapper = styled.div`
  div div {
    width: 18px;
    height: 18px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
